import { CBottomSheetType } from '@typedef/components/common/cbottom.sheet.types';
import React, { useCallback, useState } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import CBottomSheet from '../CBottomSheet';

/**
 * 사용법 예시
 * 
 * const [open, setOpen] = useState(false);

  const onClosedBtnClick = useCallback(() => {
    setOpen(false);
  }, []);

  const onApplyBtnClick = useCallback(()=>{
  setOpen(false);
  },[]);

  return (
    <div>
      <button onClick={() => setOpen(true)}>Open</button>
      <CBottomSheetContainer
        open={open}
        title={'타이틀'}
        onClosedBtnClick={onClosedBtnClick}
        onApplyBtnClick={onApplyBtnClick}>
        <div>{'content 컴포넌트'}</div>
      </CBottomSheetContainer>
    </div>
  );
 * 
 * 
 */

type Props = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  type?: CBottomSheetType;
  text?: string;
  onClosedBtnClick: () => void;
  onApplyBtnClick: () => void;
};

const CBottomSheetContainer = (props: Props) => {
  return props.open ? <CBottomSheet {...props} /> : <></>;
};

export default CBottomSheetContainer;
