import { GREY2 } from '@constants/colors';
import styled from '@emotion/styled';

export const MultiFileDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  label {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${GREY2};

    img {
      width: 24px;
      height: 24px;
    }
  }

  .item-container {
    flex: 1;
    display: flex;
    overflow-x: auto;
    gap: 10px;
  }
`;

export const FileItemDiv = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;
