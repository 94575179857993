import { GREY2, WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const SearchPopupDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${WHITE};
  z-index: 9999;
`;

export const PopoupHeaderDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;

  button {
    position: absolute;
    left: 20px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const PopupSearchbarDiv = styled.div`
  height: 70px;
  padding: 11px 20px;
`;

export const ListItemDiv = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${GREY2};
`;
