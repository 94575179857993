import React, { useCallback, useEffect, useState } from 'react';
import CommentBottomSheet from '../components/CommentBottomSheet';
import { API_ROUTE, request } from '@libs/api';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { SESSION_STORAGE_TYPE, getTokenFromStorage } from '@libs/storage';
import { useRecoilState } from 'recoil';
import { authState } from '@store/atom/AuthAtom';

type Props = {
  isOpen: boolean;
  place: PlaceDetailType;
  onClosedBtnClick: () => void;
};

const CommentBottomSheetContainer = (props: Props) => {
  const [rate, setRate] = useState(100);
  const [postRate, setPostRate] = useState(20);
  const [files, setFiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [{ isLoggedIn }] = useRecoilState(authState);

  const onChange = ({ value }: { value: string }) => {
    if (value.length >= 50) return;
    setInputValue(value);
  };

  const onHandleRate = (num: number) => {
    setRate(num);
    setPostRate(num / 20);
  };

  const onSelectedFileChanged = (files: File[]) => {
    setFiles(files);
  };

  /**
   * post 보내기
   */
  const handleCommentPost = async () => {
    if (!isLoggedIn) {
      return;
    }

    const formData = new FormData();

    formData.append('placeId', props.place.id.toString());
    formData.append('rating', postRate.toString());
    formData.append('content', inputValue);
    files.forEach((file) => formData.append('images', file));

    const {
      data,
      config: { isSuccessful },
    } = await request(
      API_ROUTE.placeReview,
      'post',
      { 'Content-Type': 'multipart/form-data' },
      formData,
    );

    if (!isSuccessful) {
      console.log('fail');
      return;
    }
    props.onClosedBtnClick();

    /** 초기화 */
    setRate(0);
    setPostRate(0);
    setFiles([]);
    setInputValue('');
  };

  useEffect(() => {
    console.log('rate', rate);
    console.log('files', files);
    console.log('inputvalue', inputValue);
    console.log('postRate', postRate);
  }, [rate, files, inputValue, postRate]);

  return (
    <CommentBottomSheet
      {...props}
      rate={rate}
      onHandleRate={onHandleRate}
      files={files}
      onSelectedFileChanged={onSelectedFileChanged}
      inputValue={inputValue}
      setInputValue={setInputValue}
      onChange={onChange}
      handleCommentPost={handleCommentPost}
    />
  );
};

export default CommentBottomSheetContainer;
