import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import TabNavigation from './components/TabNavigation';
import NearByContainer from '@components/NearBy/containers/NearByContainer';
import LoginPage from '@components/Login/Login';
import MyReview from '@components/MyPage/MyReview';
import SelfTourContainer from '@components/SelfTour/containers/SelfTourContainer';
import AddOtherPlaceContainer from '@components/SelfTour/containers/AddOtherPlaceContainer';
import PlaceDetailContainer from '@components/PlaceDetail/containers/PlaceDetailContainer';
import ProfileEditPage from '@components/MyPage/ProfileEditPage';
import WishList from '@components/MyPage/WishList';
import Tour from '@components/MyPage/Tour';
import Review from '@components/MyPage/Review';
import MyPagePage from '@components/MyPage/MyPage';
import WithdrawalPage from '@components/Withdrawal/WithdrawalPage';
import WishListTourContent from '@components/MyPage/components/WishListTourContent';
import WishListPlaceContent from '@components/MyPage/components/WishListPlaceContent';
import ComingTourContent from '@components/MyPage/components/ComingTourContent';
import PastTourContent from '@components/MyPage/components/PastTourContent';
import LoginRedirect from '@components/MyPage/components/LoginRedirect';
import HomeContainer from '@components/Home/containers/HomeContainer';
import AddPlaceListContainer from '@components/SelfTour/containers/AddPlaceListContainer';
import PlaceListContainer from '@components/PlaceList/containers/PlaceListContainer';
import PlaceListProvider from '@components/PlaceList/context/PlaceListProvider';
import PlaceMapContainer from '@components/PlaceMap/containers/PlaceMapContainer';
import TourListContainer from '@components/TourList/containers/TourListContainer';
import TourListProvider from '@components/TourList/context/TourListProvider';
import TourDetailContainer from '@components/TourDetail/containers/TourDetailContainer';
import OnBoarding from '@components/OnBoarding/OnBoarding';
import AddPlaceSettingContainer from '@components/SelfTour/containers/AddPlaceSettingContainer';

const RootNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='onboarding' element={<OnBoarding />} />
        {/* GNB가 보이는 메인 페이지 */}
        <Route path='/' element={<TabNavigation />}>
          <Route index element={<HomeContainer />} />
          <Route path='nearby' element={<NearByContainer />} />
          <Route path='mypage' element={<MyPagePage />} />
        </Route>
        <Route path='/login' element={<LoginPage />} />
        <Route path='tour' element={<Tour />}>
          <Route index element={<Navigate replace to='comingtour' />} />
          <Route path='comingtour' element={<ComingTourContent />} />
          <Route path='pasttour' element={<PastTourContent />} />
        </Route>
        <Route path='/login' element={<LoginPage />} />
        <Route path='mypage'>
          <Route path='withdrawal' element={<WithdrawalPage />} />
          <Route path='edit' element={<ProfileEditPage />} />
          <Route path='wishList' element={<WishList />}>
            <Route index element={<Navigate replace to='tour' />} />
            <Route path='tour' element={<WishListTourContent />} />
            <Route path='place' element={<WishListPlaceContent />} />
          </Route>
          <Route path='tour' element={<Tour />}>
            <Route index element={<Navigate replace to='comingtour' />} />
            <Route path='comingtour' element={<ComingTourContent />} />
            <Route path='pasttour' element={<PastTourContent />} />
            <Route path='review' element={<Review />} />
          </Route>
          <Route path='myReview' element={<MyReview />} />
        </Route>
        <Route path='/selftour'>
          <Route index element={<SelfTourContainer />} />
          <Route path='add-place' element={<AddPlaceListContainer />} />
          <Route
            path='add-place/setting'
            element={<AddPlaceSettingContainer />}
          />
          <Route path='add-other-place' element={<AddOtherPlaceContainer />} />
        </Route>
        <Route path='place'>
          <Route
            path='list'
            element={
              <PlaceListProvider>
                <PlaceListContainer />
              </PlaceListProvider>
            }
          />
          <Route path='map' element={<PlaceMapContainer />} />
          <Route path='detail' element={<PlaceDetailContainer />} />
        </Route>
        <Route path='tour'>
          <Route
            path='list'
            element={
              <TourListProvider>
                <TourListContainer />
              </TourListProvider>
            }
          />
          <Route path='detail' element={<TourDetailContainer />} />
        </Route>
        <Route path='/oauth2/redirect' element={<LoginRedirect />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RootNavigation;
