import React, { useCallback, useEffect, useState } from 'react';
import TourDetail from '../TourDetail';
import { API_ROUTE, buildQueryString, request } from '@libs/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelfTourPlaceType } from '@typedef/components/SelfTour/selftour.types';
import { getDateDiff } from '@libs/factory';
import { TourDetailResType } from '@typedef/api/tour.types';

const TourDetailContainer = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const tourId = params.get('tourId');
  const [tourDetail, setTourDetail] = useState<TourDetailResType | null>(null);
  const [schedule, setSchedule] = useState<Set<string>>();
  const [activeDay, setActiveDay] = useState(1);
  const [tourPlaces, setTourPlaces] =
    useState<Map<number, SelfTourPlaceType[]>>();

  const handleDateClick = useCallback((idx: number) => {
    setActiveDay(idx);
  }, []);

  const handleMapClick = useCallback(
    (lat: number, lng: number) => {
      navigate(`/place/map${buildQueryString({ lat, lng })}`);
    },
    [navigate],
  );

  const getTourDetail = useCallback(async () => {
    if (!tourId) return;

    const {
      data,
      config: { isSuccessful },
    } = await request<TourDetailResType>(
      API_ROUTE.tour + `/${tourId}/plan`,
      'get',
    );

    if (!isSuccessful) {
      console.log('투어 상세 정보를 불러오는데에 실패했습니다');
      alert('투어 상세 정보를 불러오는데에 실패했습니다');
      navigate(-1);
      return;
    }

    setTourDetail(data);
    setActiveDay(1);
    const tmpTourPlaceMap = new Map();
    const tourFromDate = data.from;
    data.tourPlaces.forEach((place) => {
      const diff = getDateDiff([tourFromDate, place.from]);
      const value = tmpTourPlaceMap.get(diff) ?? [];
      value?.push(place);
      tmpTourPlaceMap.set(diff, value);
    });

    setTourPlaces(tmpTourPlaceMap);
  }, [navigate, tourId]);

  useEffect(() => {
    getTourDetail();
  }, [tourId]);

  return tourDetail ? (
    <TourDetail
      tourDetail={tourDetail}
      placeList={tourPlaces?.get(activeDay)}
      activeDay={activeDay}
      handleDateClick={handleDateClick}
      handleMapClick={handleMapClick}
    />
  ) : null;
};

export default TourDetailContainer;
