import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MyPageHeader from '../MyPageHeader';

type Props = {
  title: string;
};

const MyPageHeaderContainer = ({ title }: Props) => {
  const navigate = useNavigate();

  const onClickBackBtn = useCallback(() => {
    navigate('/mypage');
  }, [navigate]);

  return <MyPageHeader title={title} onClickBackBtn={onClickBackBtn} />;
};

export default MyPageHeaderContainer;
