/** @jsxImportSource @emotion/react */
/** @jsxImportSource @emotion/react */
import React from 'react';
import BottomSheet from './components/BottomSheet';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@components/assets/colors';
import Button from '@components/common/button/components/Button';
import { useNavigate } from 'react-router-dom';

const BottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 41px;
`;

const ControllerArea = styled.div`
  display: flex;
  margin-top: 47px;
  margin-bottom: 41px;
  gap: 8px;
  padding: 0 20px;
`;

type Props = {
  title: string;
  closeModal: () => void;
};

const ReviewBottomSheet = ({ title, closeModal }: Props) => {
  const navigate = useNavigate();

  return (
    <BottomSheet title={title} closeModal={closeModal}>
      <BottomSheetContent>
        <h4
          css={css`
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
          `}
        >
          후기를 나중에 작성하시겠어요?
        </h4>
        <p
          css={css`
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: ${colors.greyScale.grey5};
            margin-top: 8px;
            width: 194px;
            text-align: center;
          `}
        >
          삭제 버튼을 누르면 작성중인 내용이 저장되지 않고 사라집니다
        </p>
      </BottomSheetContent>
      <ControllerArea>
        <Button
          isFilled={false}
          btnText="임시 저장"
          backgroundColor="primary"
          onClick={() => {}}
        ></Button>
        <Button
          isFilled={true}
          btnText="삭제"
          backgroundColor="primary"
          onClick={() => {
            navigate('/mypage/tour/pasttour', {
              state: '지난 투어',
              replace: true,
            });
          }}
        ></Button>
      </ControllerArea>
    </BottomSheet>
  );
};

export default ReviewBottomSheet;
