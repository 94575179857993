/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  onClickCloseBtn: () => void;
};

const MyPageSelectBox = ({ onClickCloseBtn }: Props) => {
  return (
    <SelectBoxStyled onClick={onClickCloseBtn}>
      탈퇴 사유 선택
      <img src={images.arrowDown} alt="select" />
    </SelectBoxStyled>
  );
};

export default MyPageSelectBox;

const SelectBoxStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 56px;
  background: ${colors.greyScale.grey1};
  border: 1px solid ${colors.greyScale.grey2};
  border-radius: 6px;
  font-weight: 400;
  font-size: 15px;
  color: ${colors.greyScale.grey5};
  padding-left: 16px;
  padding-right: 46px;

  img {
    position: absolute;
    right: 19px;
    bottom: 19px;
  }
`;
