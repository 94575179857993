import { images } from '@components/assets/images';
import TourCardListItemContainer from '@components/common/TourCardListItem/containers/TourCardListItemContainer';
import React from 'react';
import * as S from '../styles/RecommendTabStyles';
import LocationListItemContainer from '@components/common/PlaceGridItem/containers/PlaceGridItemContainer';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { TourType } from '@typedef/components/home/home.types';

type Props = {
  placeList: PlaceDetailType[];
  tourList: TourType[];
};

const RecommendTab = ({ placeList, tourList }: Props) => {
  return (
    <div>
      <S.RelatedLocationSection>
        <span className='title text-l'>{'관련 장소'}</span>
        <div className='location-container'>
          {placeList?.map((item) => (
            <LocationListItemContainer
              key={item.id}
              place={item}
              maxWidth={162}
            />
          ))}
        </div>
      </S.RelatedLocationSection>
      {tourList.length > 0 && (
        <S.RelatedLocationSection>
          <span className='title text-l'>{'주변 시즈니 투어'}</span>
          <div className='location-container'>
            {tourList.map((item) => (
              <TourCardListItemContainer tour={item} />
            ))}
          </div>
        </S.RelatedLocationSection>
      )}
    </div>
  );
};

export default RecommendTab;
