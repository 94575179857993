import { BLACK } from '@constants/colors';
import styled from '@emotion/styled';

export const BackButton = styled.button`
  position: absolute;
  top: 16px;
  left: 20px;
  z-index: 9;
`;

export const ButtonListDiv = styled.div`
  position: absolute;
  bottom: 18px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.4);
  padding: 8px 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
    height: 18px;
  }
`;
