import {
  GREY1,
  GREY2,
  GREY5,
  PRIMARY,
  PRIMARY_DARK,
  SECONDARY_DARK,
  WHITE,
} from '@constants/colors';
import styled from '@emotion/styled';

export const ScheduleSectionStyledSection = styled.section`
  background-color: ${WHITE};
`;

export const DateSectionDiv = styled.div`
  display: flex;
  padding: 0 20px;
  overflow-x: auto;
  border-bottom: 1px solid ${GREY2};

  .date {
    flex-shrink: 0;
    display: flex;
    min-width: 94px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    border-bottom: 2px solid ${WHITE};

    .main {
      color: ${GREY5};
    }

    &.selected {
      border-color: ${PRIMARY_DARK};

      .main {
        color: ${PRIMARY_DARK};
      }
    }
  }
`;

export const LocationItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .time-container {
    position: relative;
    display: flex;
    align-items: center;

    .dot {
      position: absolute;
      left: -11px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${SECONDARY_DARK};
    }

    .time {
      color: ${SECONDARY_DARK};
    }
  }

  .place-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;

    .img-wrapper {
      flex-shrink: 0;
      width: 56px;
      height: 56px;
      border-radius: 6px;
      background-color: ${GREY2};
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;

      &.error {
        width: 40px;
        height: 40px;
      }
    }

    .location-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
    }
  }
`;

export const DefaultModePageDiv = styled.div`
  padding-bottom: 40px;
`;

export const DefaultModeHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid ${GREY2};
`;

export const DefaultLocationItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;

  .button-container {
    display: flex;
    gap: 9px;
  }
`;

export const AddPlaceByMySelfDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const ButtonListDiv = styled.div`
  position: absolute;
  bottom: 18px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.4);
  padding: 8px 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
    height: 18px;
  }
`;
