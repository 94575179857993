import React from 'react';
import TabSectionContainer from '../containers/TabSectionContainer';
import * as S from '../styles/PlaceDetailStyles';
import { PlaceDetailType } from '@typedef/components/Location/location.types';

type Props = {
  place: PlaceDetailType;
};

const InfoSection = ({ place }: Props) => {
  return (
    <S.LocationInfoDiv>
      <S.LocationNameDiv>
        <div className='title text-xxl'>{place.name}</div>
        <div className='description text-m'>{place.title}</div>
      </S.LocationNameDiv>
      <S.LocationDescriptionDiv>
        <div className='row'>
          <div className='title text-m'>{'주소'}</div>
          <div className='description text-m'>{place.address}</div>
        </div>
        <div className='row'>
          <div className='title text-m'>{'설명'}</div>
          <div className='description text-m'>{place.description}</div>
        </div>
      </S.LocationDescriptionDiv>
      <div>{/* {'지도 부분'} */}</div>
    </S.LocationInfoDiv>
  );
};

export default InfoSection;
