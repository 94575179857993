import ChipsContainer from '@components/common/button/containers/ChipsContainer';
import LocationItem from './LocationItem';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import * as S from '../styles/DefaultModeStyles';
import { SelfTourPlaceType } from '@typedef/components/SelfTour/selftour.types';

type Props = {
  list?: SelfTourPlaceType[];
  handleAddLoadcationClick: () => void;
  handleAddOtherPlaceClick: () => void;
  handleEditClick: () => void;
  handleMapClick: (lat: number, lng: number) => void;
};

const DefaultMode = ({
  list,
  handleAddLoadcationClick,
  handleAddOtherPlaceClick,
  handleEditClick,
  handleMapClick,
}: Props) => {
  return (
    <S.DefaultModePageDiv>
      <S.DefaultModeHeaderDiv>
        <ChipsContainer
          isClick={false}
          btntext={'기상청'}
          onClickHandler={() => {}}
        />
        <button onClick={handleEditClick}>{'편집'}</button>
      </S.DefaultModeHeaderDiv>
      <div style={{ padding: '12px 20px' }}>
        {/* 시간순으로 정렬 */}
        {list
          ?.sort((a, b) => (a.from < b.from ? -1 : 1))
          .map((item, idx) => (
            <S.DefaultLocationItemDiv key={item.place.id}>
              <LocationItem
                tourPlaceInfo={item}
                isLineVisible={list.length - 1 !== idx}
              />
              <div className='button-container'>
                <ButtonContainer
                  isFilled={true}
                  btnText={'길찾기'}
                  isMedium={true}
                  backgroundColor={'grey1'}
                  onClick={() => {}}
                />
                <ButtonContainer
                  isFilled={true}
                  btnText={'지도보기'}
                  isMedium={true}
                  backgroundColor={'grey1'}
                  onClick={() => {
                    handleMapClick(item.place.point.y, item.place.point.x);
                  }}
                />
              </div>
            </S.DefaultLocationItemDiv>
          ))}

        <ButtonContainer
          isFilled={true}
          btnText={'투어 장소 추가'}
          backgroundColor={'secondary-lighten'}
          buttonStyle={{ margin: '12px 0' }}
          onClick={handleAddLoadcationClick}
        />
        {/* <S.AddPlaceByMySelfDiv>
          <div className='text-m grey5'>
            {'잠깐! 나만의 장소를 따로 넣고 싶다면?'}
          </div>
          <ChipsContainer
            isClick={false}
            btntext={'장소 직접 추가'}
            onClickHandler={() => {
              handleAddOtherPlaceClick();
            }}
          />
        </S.AddPlaceByMySelfDiv> */}
      </div>
    </S.DefaultModePageDiv>
  );
};

export default DefaultMode;
