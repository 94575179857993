import axios, { AxiosResponse } from 'axios';
import { getTokenFromStorage } from './storage';

export const apiOrigin = `https://api.here-city.kro.kr`;

export const getOauthURL = (platform: string) => {
  return `https://api.here-city.kro.kr/oauth2/authorization/${platform}?redirect_uri=${window.location.origin}/oauth2/redirect`;
};

const axiosInstance = axios.create({ baseURL: `${apiOrigin}/api/v1` });

axiosInstance.interceptors.request.use((config) => {
  if (process.env.NODE_ENV === 'development') {
    // console.log('req', config);
  }

  const headers: any = { ...config.headers };

  const { isTokenStored, token } = getTokenFromStorage('SESSION_STORAGE_TYPE');
  if (config.url !== API_ROUTE.auth.refresh && isTokenStored)
    headers.Authorization = `Bearer ${token.hc_eat}`;

  return {
    ...config,
    headers: { ...headers },
  };
});

axiosInstance.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(err?.response);
    }

    const config = err?.response?.config;
    const status = err?.response?.status;

    // 액세스 토큰 만료 시에 리프레쉬 요청하고, 성공 시 재시도
    // if (
    //   config?.headers['Authorization'] &&
    //   config?.url !== API_ROUTE.auth.refresh &&
    //   status === 401
    // ) {
    //   const {
    //     isTokenStored,
    //     token: { hc_ert },
    //   } = getTokenFromStorage(SESSION_STORAGE_TYPE);

    //   if (!isTokenStored) {
    //     removeItemToStorage(SESSION_STORAGE_TYPE, AUTH_KEY);
    //     window.location.replace('/login');
    //     alert('로그인이 만료 되었습니다');
    //     return Promise.reject(err);
    //   }

    //   const {
    //     data,
    //     config: { isSuccessful },
    //   } = await request<LoginResType>(API_ROUTE.auth.refresh, 'post', {
    //     Authorization: `Bearer ${hc_ert}`,
    //   });

    //   if (isSuccessful) {
    //     setItemToStorage(
    //       SESSION_STORAGE_TYPE,
    //       authConfig({
    //         hc_eat: data.token.accessToken,
    //         hc_ert: data.token.refreshToken,
    //       }),
    //     );
    //     config.headers['Authorization'] = `Bearer ${data.token.accessToken}`;

    //     return axiosInstance(config);
    //   } else {
    //     removeItemToStorage(SESSION_STORAGE_TYPE, AUTH_KEY);
    //     removeItemToStorage(LOCAL_STORAGE_TYPE, AUTH_KEY);
    //     alert('로그인이 만료 되었습니다');
    //     window.location.replace('/login');
    //   }
    // }

    return Promise.reject(err);
  },
  {
    synchronous: true,
  },
);

export type TmpListResponseType<T> = {
  contents: T[];
  meta: {
    total: number;
    page: number;
    limit: number;
    maxPage: number;
  };
};

export type ListResponseType<T> = {
  content: T[];
  meta: {
    total: number;
    page: number;
    limit: number;
    maxPage: number;
  };
};

export type BasicResponseType<T> = {
  data: T;
  config: {
    status: number;
    message?: string;
    isSuccessful: boolean;
    errorCode?: string;
  };
};

// export type ListResponseType<T> = BasicResponseType<{ content: T[] }>;

export function buildQueryString(params: {
  [key: string]: string | number | undefined;
}): string {
  let queryString = '';

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (value === undefined) {
        continue;
      }

      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value.toString());

      if (queryString !== '') {
        queryString += '&';
      }
      queryString += `${encodedKey}=${encodedValue}`;
    }
  }

  return '?' + queryString;
}

function parseAxiosResponse<T>(
  res: AxiosResponse<T, any>,
): BasicResponseType<T> {
  return {
    data: res.data ?? ({} as T),
    config: {
      isSuccessful: true,
      status: res.status ?? -1,
      message: res.statusText,
    },
  };
}

function responseErrorHandler<T>(err: any) {
  console.error('[Axios Error]', err.response);
  const status = err?.response?.status || -1;
  const resMessage = err?.response?.data?.message;
  const errorCode = err?.response?.data?.code || '-1';

  return {
    data: {} as T,
    config: {
      isSuccessful: false,
      status: status,
      message: resMessage,
      errorCode,
    },
  } as BasicResponseType<T>;
}

export function getHeaderMultipartFormat() {
  return { 'Content-Type': 'multipart/form-data' };
}

export function request<T>(
  url: string,
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  header: object = {},
  body: object | undefined = undefined,
): Promise<BasicResponseType<T>> {
  return axiosInstance[method](url, body, {
    headers: {
      'Content-Type': 'application/json',
      ...header,
    },
  })
    .then(parseAxiosResponse)
    .catch((err) => responseErrorHandler(err));
}

export function requestSecure<T>(
  url: string,
  method: 'get' | 'post' | 'patch' | 'delete',
  header: object = {},
  body: object | undefined = undefined,
): Promise<BasicResponseType<T>> {
  const { token } = getTokenFromStorage('SESSION_STORAGE_TYPE');

  return axiosInstance[method](
    url,
    { data: body },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.hc_eat}`,
        ...header,
      },
    },
  )
    .then(parseAxiosResponse)
    .catch((err) => responseErrorHandler(err));
}

export const API_ROUTE = {
  auth: {
    refresh: '',
  },
  place: '/places',
  placeType: '/places/types',
  unit: '/units',
  tour: '/tours',
  tourLike: '/tours/',
  region: '/regions',
  placeLike: '/places/',
  placeReview: '/places/reviews',
  member: '/members',
  activity: '/activities',
  user: '/users',
};
