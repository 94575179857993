import { AUTH_KEY, authConfig, setItemToStorage } from '@libs/storage';
import { authState } from '@store/atom/AuthAtom';
import React, { useEffect } from 'react';
import {
  Link,
  Navigate,
  redirect,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const LoginRedirect = () => {
  const [searchParams] = useSearchParams();
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setItemToStorage(
        'SESSION_STORAGE_TYPE',
        authConfig({ hc_eat: token, hc_ert: '' }),
      );
      setAuth({ isLoggedIn: true, token: { hc_eat: token, hc_ert: '' } });
      navigate('/', { replace: true });
    }
  }, []);

  return <div />;
};

export default LoginRedirect;
