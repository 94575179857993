import { ColorTypes } from '@typedef/styles/styles.types';
import React from 'react';
import Button from '../components/Button';

type Props = {
  isDisabled?: boolean;
  isFilled: boolean;
  btnText: string;
  isMedium?: boolean;
  backgroundColor: ColorTypes;
  buttonStyle?: React.CSSProperties;
  onClick: () => void;
};
const ButtonContainer = (props: Props) => {
  return <Button {...props} />;
};

export default ButtonContainer;
