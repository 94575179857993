import { PRIMARYDARK } from '@components/common/button/constants/color';
import { BLACK, GREY1, GREY5, PRIMARY, WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const AddTourButtonDiv = styled.div`
  bottom: 0;
  padding: 7px 20px;
  width: 100%;
  background-color: ${WHITE};
`;

export const LocationInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
`;

export const LocationNameDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .title {
    color: ${BLACK};
  }

  .description {
    color: ${GREY5};
  }
`;

export const LocationDescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px;
  background-color: ${GREY1};
  margin-top: 17px;

  .row {
    display: flex;
    gap: 16px;

    .title {
      color: ${BLACK};
      flex-shrink: 0;
    }

    .description {
      color: ${GREY5};
    }
  }
`;

export const TabDiv = styled.div<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? PRIMARYDARK : GREY5)};
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  padding: 18px 12px;
  border-bottom: 2px solid transparent;
  border-color: ${(props) => (props.isSelected ? PRIMARY : 'transparent')};
`;
