import TextButtonContainer from '@components/common/button/containers/TextButtonContainer';
import React, { useContext } from 'react';
import { CalendarContext } from '../context/CalendarContext';
import * as S from '../styles/calendarStyles';

const CalendarHeader = () => {
  const { handleResetClick } = useContext(CalendarContext);
  return (
    <S.HeaderContainer>
      <span className='text-xxl black'>{'여행 일정을 선택해주세요'}</span>
      <TextButtonContainer btnName='초기화' onClick={handleResetClick} />
    </S.HeaderContainer>
  );
};

export default CalendarHeader;
