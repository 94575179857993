import { GREY2, GREY5, PRIMARY_DARK, WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const ItemContainer = styled.div`
  display: flex;
  overflow: scroll;
  border-bottom: 1px solid ${GREY2};
`;
export const Item = styled.div<{ isSelected: boolean }>`
  &:first-of-type {
    margin-left: 20px;
  }
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  padding: 18px 12px;
  color: ${(props) => (props.isSelected ? PRIMARY_DARK : GREY5)};
  border-bottom: ${(props) =>
    props.isSelected ? `2px solid ${PRIMARY_DARK}` : `2px solid ${WHITE}`};
`;

export const OptionItemContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  min-width: 100vw;
  gap: 8px;

  & > button:first-of-type {
    margin-left: 20px;
  }
`;
