import React from 'react';
import * as S from '../styles/EditModalStyles';
import { timeFormat } from '@libs/factory';

type Props = {
  time: { from: string; to: string };
  handleTimeChange: (key: 'from' | 'to', value: string) => void;
};

const ModalEditTime = ({ time, handleTimeChange }: Props) => {
  return (
    <S.ModalContentRoot>
      <div className='subtitle1 title description'>
        {'선택한 장소의 시간을 재설정해주세요'}
      </div>
      <div className='subtitle1 title'>{'시간 설정'}</div>
      <div>
        <S.TimeItem>
          <div className={'time-list'}>
            <p className={`text-m time-text`}>{'시작 시각'}</p>
            <label htmlFor='input-time-start' className={`text-m input-time`}>
              {timeFormat(time.from)}
              <input
                type='time'
                id='input-time-start'
                name='input-time-start'
                className={'time'}
                value={time.from}
                onChange={(e) => {
                  handleTimeChange('from', e.target.value);
                }}
              />
            </label>
          </div>
        </S.TimeItem>
        <S.TimeItem>
          <div className={'time-list'}>
            <p className={`text-m time-text`}>{'종료 시각'}</p>
            <label htmlFor='input-time-end' className={`text-m input-time`}>
              {timeFormat(time.to)}
              <input
                type='time'
                id='input-time-end'
                name='input-time-end'
                className={'time'}
                value={time.to}
                onChange={(e) => {
                  handleTimeChange('to', e.target.value);
                }}
              />
            </label>
          </div>
        </S.TimeItem>
      </div>
    </S.ModalContentRoot>
  );
};

export default ModalEditTime;
