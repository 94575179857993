/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { PRIMARY, GREY3, GREY1 } from '../constants/color';
import { images, RegionKeyType } from '../../../assets/images';

type Props = {
  isClick: boolean;
  btntext: string;
  imgSrc: string;
  onClickHandler?: () => void;
};
const ChipswithImage = ({
  isClick,
  btntext,
  imgSrc,
  onClickHandler,
}: Props) => {
  return (
    <div>
      <button
        css={css`
          padding: 0px 20px 0px 5px;
          height: 45px;
          border-radius: 30px;
          border: 1px solid ${isClick ? PRIMARY : GREY3};
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
        onClick={onClickHandler}>
        <img
          //src={images.region[region]} //옵션 설정 시, 부산이라고 하면 사진 파일 중 부산 사진을 자동으로 바꾸고 싶음.
          src={imgSrc}
          alt=''
          css={css`
            width: 38px;
            height: 38px;
            border-radius: 50px;
            margin-right: 6px;
          `}
        />
        <p
          css={css`
            flex: 1;
            font-weight: 400;
            font-size: 14px;
          `}>
          {btntext}
        </p>
      </button>
    </div>
  );
};

export default ChipswithImage;
