import ModalPortal from '@components/common/ModalPortal/ModalPortal';
import * as S from './styles/NearByStyles';
import MapContainer from '@components/NearBy/containers/MapContainer';
import SearchBoxContainer from '@components/common/SearchBox/containers/SearchBoxContainer';
import { useContext } from 'react';
import SearchPopupContainer from './containers/SearchPopupContainer';

import { useRecoilState } from 'recoil';
import { nearByState } from '@store/atom/NearByAtom';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import NearByBottomSheetContainer from './containers/NearByBottomSheetContainer';
import { markerState } from '@store/atom/MapAtom';
import MarkerBottomSheetContainer from './containers/MarkerBottomSheetContainer';

type Props = {
  isLoading: boolean;
  placeList?: PlaceDetailType[];
  isOpen: boolean;
  searchInput: string;
  handleSearchInputChange: (value: string) => void;
  handleSearchPopup: (isOpen: boolean) => void;
  fetchNextPage: () => void;
};

const NearByPage = ({
  isLoading,
  isOpen,
  placeList,
  fetchNextPage,
  handleSearchInputChange,
  handleSearchPopup,
}: Props) => {
  const [value] = useRecoilState(nearByState);
  const [marker] = useRecoilState(markerState);
  return (
    <div className='near-by-root'>
      <MapContainer placeList={placeList} />
      <S.SearchBarDiv onClick={() => handleSearchPopup(true)}>
        <SearchBoxContainer
          placeholder='내 주변 장소 검색'
          variant='shadow'
          value={value.name}
          className={''}
          onChange={() => {}}
          onReset={() => handleSearchInputChange('')}
        />
      </S.SearchBarDiv>
      {marker ? (
        <MarkerBottomSheetContainer />
      ) : (
        <NearByBottomSheetContainer
          placeList={placeList}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
        />
      )}
      {isOpen && (
        <ModalPortal>
          <SearchPopupContainer
            onClosePopoup={() => handleSearchPopup(false)}
          />
        </ModalPortal>
      )}
    </div>
  );
};

export default NearByPage;
