import { useEffect, useState } from 'react';

export function useInfiniteScroll(
  fetchNext: () => void,
  threshold: number = 0.8,
) {
  const [target, setTarget] = useState<Element | null>(null);
  const [root, setRoot] = useState<Element | null>(null);

  useEffect(() => {
    if (!target) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((item) => {
          if (item.isIntersecting) {
            fetchNext();
          }
        });
      },
      {
        root: root,
        threshold,
      },
    );

    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, [target]);

  return {
    setTarget,
    setRoot,
  };
}
