/** @jsxImportSource @emotion/react */
import { GREY1 } from '@constants/colors';
import { css } from '@emotion/react';
import {
  LocationDetailTabType,
  PlaceDetailType,
} from '@typedef/components/Location/location.types';
import React from 'react';
import CommentTabContainer from '../containers/CommentTabContainer';
import RecommendTabContainer from '../containers/RecommendTabContainer';
import * as S from '../styles/PlaceDetailStyles';

type Props = {
  selectedTab: LocationDetailTabType;
  handleChangeTab: (tab: LocationDetailTabType) => void;
  place: PlaceDetailType;
};

const TabSection = ({ selectedTab, handleChangeTab, place }: Props) => {
  return (
    <div>
      <div
        css={css`
          display: flex;
          margin-top: 40px;
          border-bottom: 8px solid ${GREY1};
          padding: 0 20px;
        `}>
        <S.TabDiv
          isSelected={selectedTab === 'Comment'}
          onClick={() => handleChangeTab('Comment')}>
          {'시즈니 한줄평'}
        </S.TabDiv>
        <S.TabDiv
          isSelected={selectedTab === 'Recommend'}
          onClick={() => handleChangeTab('Recommend')}>
          {'추천 투어 장소'}
        </S.TabDiv>
      </div>
      {selectedTab === 'Comment' && <CommentTabContainer place={place} />}
      {selectedTab === 'Recommend' && <RecommendTabContainer place={place} />}
    </div>
  );
};

export default TabSection;
