import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SelfTourDetailState } from '@store/atom/SelfTourAtom';
import { useNavigate } from 'react-router-dom';
import { getTourPlaceTimeFormat } from '@libs/factory';
import AddPlaceSetting from '../components/AddPlaceSetting';

const AddPlaceSettingContainer = () => {
  const navigate = useNavigate();
  const [selfTourDetail, setSelfTourDetail] =
    useRecoilState(SelfTourDetailState);
  const { schedule, placeList, selectedPlace, selectedDay } = selfTourDetail;
  const [selectedIdx, setSelectedIdx] = useState(selectedDay);
  const [time, setTime] = useState({ from: '09:00', to: '10:00' });

  const handleDayClick = (idx: number) => {
    setSelectedIdx(idx);
  };

  const handleTimeChange = (key: 'from' | 'to', time: string) => {
    setTime((prev) => {
      const clone = { ...prev };
      clone[key] = time;
      return clone;
    });
  };

  const handleDoneClick = () => {
    if (!selectedPlace) {
      alert('장소를 다시 선택해주세요');
      navigate(-2);
      return;
    }

    if (time.from >= time.to) {
      alert('시작 시각을 종료 시각보다 빠르게 설정해주세요');
      return;
    }

    const clone = new Map(placeList);
    const activePlaceList = placeList.get(selectedIdx) ?? [];
    activePlaceList?.push({
      place: selectedPlace,
      from: getTourPlaceTimeFormat(
        Array.from(schedule).sort()[0],
        selectedIdx,
        time.from,
      ),
      to: getTourPlaceTimeFormat(
        Array.from(schedule).sort()[0],
        selectedIdx,
        time.to,
      ),
      description: '',
      isSelected: false,
    });

    clone.set(selectedIdx, activePlaceList);

    // console.log(time);

    setSelfTourDetail({
      ...selfTourDetail,
      placeList: clone,
      selectedPlace: null,
      selectedDay: selectedIdx,
    });

    navigate(-3);
  };
  return (
    <AddPlaceSetting
      schedule={schedule}
      selectedIdx={selectedIdx}
      time={time}
      handleDayClick={handleDayClick}
      handleTimeChange={handleTimeChange}
      handleDoneClick={handleDoneClick}
    />
  );
};

export default AddPlaceSettingContainer;
