/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import { LoadingDiv } from '@components/common/Loading/styles/LoadingStyles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { ISOToScheduleFormat } from '@libs/factory';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { TourType } from '@typedef/components/home/home.types';
import { useNavigate } from 'react-router-dom';

const PastTourContent = () => {
  const navigate = useNavigate();

  const getPastTourList = async (pageParam = 0) => {
    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<TourType>>(
      API_ROUTE.tour +
        '/me' +
        buildQueryString({
          page: pageParam,
          limit: 10,
          isPast: 'true',
        }),
      'get',
    );

    if (!isSuccessful) {
      throw Error('장소 리스트를 불러오는데에 실패했습니다');
    }

    return { content: data.content, pageParam, maxPage: data.meta.maxPage };
  };

  const { data, isLoading, isSuccess } = useInfiniteQuery(
    ['pastTourQuery'],
    ({ pageParam }) => getPastTourList(pageParam),
  );

  if (isLoading) {
    return (
      <PastTourContentSection>
        <LoadingDiv />
      </PastTourContentSection>
    );
  }

  return (
    <PastTourContentSection>
      {isSuccess ? (
        <>
          {data?.pages
            .map((item) => item.content)
            .flat(1)
            .map((item, idx) => {
              return (
                <>
                  <div
                    css={css`
                      display: flex;
                      flex: 1;
                      flex-direction: column;
                      margin: 0 16px;
                    `}>
                    <TourCard>
                      <img
                        height={60}
                        width={60}
                        css={css`
                          border-radius: 6px;
                          margin-right: 10px;
                          object-fit: cover;
                        `}
                        src={`${images.tour}${Math.floor(
                          Math.random() * 5 + 1,
                        )}.svg`}
                        alt='투어 썸네일'
                      />
                      <div>
                        <div className='text-m'>{item.name}</div>
                        <div
                          className='text-xs'
                          css={css`
                            margin-top: 5px;
                            color: ${colors.greyScale.grey5};
                          `}>
                          {`${ISOToScheduleFormat(
                            item.from,
                          )} ~ ${ISOToScheduleFormat(item.to)}`}
                        </div>
                      </div>
                      <div
                        className='text-xl'
                        css={css`
                          margin-left: auto;
                        `}>
                        <TourCompleteIcon src={images.tourComplete} />
                      </div>
                    </TourCard>
                    <button
                      className='text-m'
                      css={css`
                        color: ${colors.primary.primaryDark};
                        border: 1px solid ${colors.primary.primaryDark};
                        border-radius: 6px;
                        padding: 11px 0;
                        margin-top: 12px;
                        margin-bottom: 25px;
                      `}
                      onClick={() =>
                        navigate('/mypage/tour/review', { state: item })
                      }>
                      투어 후기 작성하기
                    </button>
                  </div>
                </>
              );
            })}
        </>
      ) : (
        ''
      )}
    </PastTourContentSection>
  );
};

export default PastTourContent;

const PastTourContentSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 0;
`;
const TourCard = styled.div`
  display: flex;
  height: 92px;
  min-width: 321px;
  padding: 16px;
  align-items: center;
  margin-right: 10px;
`;

const TourCompleteIcon = styled.img`
  width: 56px;
  height: 56px;
`;
