import { GREY2, PRIMARY, WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const SettingBarDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 22px 20px;
  background-color: ${GREY2};
`;

export const DraggableAreaDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EditLocationInfoSection = styled.section`
  display: flex;
  gap: 7px;
  padding: 0 20px;
  border-top: 1px solid ${GREY2};
`;

export const DraggableItemDiv = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 20px;
  background-color: ${WHITE};
  transition: all 200ms ease-in;

  label {
    img {
      width: 24px;
      height: 24px;
    }
  }

  .location-item {
    flex-shrink: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .drag-btn {
    display: flex;
    align-items: center;
  }
`;
