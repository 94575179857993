import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import SelfTourPage from '../SelfTour';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  SelfTourDetailState,
  SelfTourModalState,
} from '@store/atom/SelfTourAtom';
import {
  SelfTourDetailType,
  SelfTourModalType,
} from '@typedef/components/SelfTour/selftour.types';
import { API_ROUTE, buildQueryString, request } from '@libs/api';
import { UserType } from '@typedef/auth.types';
import { SelfTourReqType } from '@typedef/api/tour.types';

const SelfTourContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useRecoilState(SelfTourModalState);
  const resetModalState = useResetRecoilState(SelfTourModalState);
  const [selfTourDetail, setSelftourDetail] =
    useRecoilState(SelfTourDetailState);
  const resetSelfTourDetail = useResetRecoilState(SelfTourDetailState);

  const [profile, setProfile] = useState<UserType>();

  const getProfile = async () => {
    const {
      config: { isSuccessful },
      data,
    } = await request<UserType>(API_ROUTE.user + '/profile', 'get');

    if (!isSuccessful) return;

    setProfile(data);
  };

  const handleResetPlaceList = () => {
    setSelftourDetail({
      ...selfTourDetail,
      placeList: new Map(),
      selectedDay: 0,
      selectedPlace: null,
      editableItemIdx: -1,
    });
  };

  const handleSelfTourDetail = (value: Partial<SelfTourDetailType>) => {
    setSelftourDetail({ ...selfTourDetail, ...value });
  };

  const handleModalOpen = (key: keyof SelfTourModalType, value: boolean) => {
    setIsModalOpen((prev) => {
      const clone = { ...prev };
      clone[key] = value;
      return clone;
    });
    navigate(
      `${location.pathname}${buildQueryString({
        [`isModal${key}Open`]: String(value),
      })}`,
    );
  };

  const onResetTourName = () => {
    handleSelfTourDetail({ name: '' });
  };

  /**
   * 달력
   */
  const handleScheduleChange = (start: string, end: string) => {
    handleSelfTourDetail({
      schedule: new Set([start, end]),
      placeList: new Map(),
      selectedDay: 0,
      selectedPlace: null,
    });
    handleModalOpen('calendar', false);
  };

  const onChangeTourName = (value: string) => {
    handleSelfTourDetail({ name: value });
  };

  // 나가기 취소
  const onClosedBtnClicked = () => {
    handleModalOpen('exit', false);
  };

  // 투어 나가기
  const onApplyBtnClicked = () => {
    window.location.replace('/');
    resetSelfTourDetail();
    resetModalState();
  };

  const handleSubmitSelfTour = async () => {
    if (!profile) return;

    if (selfTourDetail.name === '') {
      alert('투어 이름을 입력해주세요');
      return;
    }

    if (selfTourDetail.region.name === '') {
      alert('지역을 선택해주세요');
      return;
    }

    if (selfTourDetail.schedule.size === 0) {
      alert('날짜를 선택해주세요');
      return;
    }

    if (!selfTourDetail.scope) {
      alert('투어 공개 여부를 선택해주세요');
      return;
    }

    const tourFrom =
      new Date(Array.from(selfTourDetail.schedule).sort()[0]).getTime() +
      new Date().getTimezoneOffset() * 60 * 1000;

    const tourTo =
      selfTourDetail.schedule.size === 1
        ? tourFrom
        : new Date(Array.from(selfTourDetail.schedule).sort()[1]).getTime() +
          new Date().getTimezoneOffset() * 60 * 1000;

    const tourPlaces = Array.from(selfTourDetail.placeList.values())
      .flat(1)
      .map((place) => {
        return {
          placeId: place.place.id,
          from: place.from,
          to: place.to,
          description: '',
        };
      });

    const tourReqBody: SelfTourReqType = {
      name: selfTourDetail.name,
      regionId: selfTourDetail.region.id,
      scope: selfTourDetail.scope,
      from: new Date(tourFrom).toISOString(),
      to: new Date(tourTo).toISOString(),
      tourPlaces: tourPlaces,
      tourists: [profile?.id],
    };

    const {
      config: { isSuccessful },
    } = await request(API_ROUTE.tour, 'post', {}, tourReqBody);

    if (!isSuccessful) {
      alert('투어 생성에 실패했습니다\n 다시 시도해주세요');
      return;
    }

    window.location.replace('/');
    resetSelfTourDetail();
    resetModalState();
  };

  useEffect(() => {
    getProfile();
  }, []);

  // 모달창이 켜져있는 상태에서 브라우저에서 뒤로가기 버튼 눌렀을 때, 페이지 이동 방지
  useEffect(() => {
    const listenBackEvent = () => {
      if (isModalOpen.calendar) {
        handleModalOpen('calendar', false);
        return;
      }

      if (isModalOpen.region) {
        handleModalOpen('region', false);
        return;
      }

      if (isModalOpen.scope) {
        handleModalOpen('scope', false);
        return;
      }

      if (isModalOpen.time) {
        handleModalOpen('time', false);
        return;
      }

      if (isModalOpen.day) {
        handleModalOpen('day', false);
        return;
      }

      isModalOpen.exit
        ? handleModalOpen('exit', false)
        : handleModalOpen('exit', true);
    };

    window.addEventListener('popstate', listenBackEvent);
    return () => {
      window.removeEventListener('popstate', listenBackEvent);
    };
  }, [isModalOpen]);

  return (
    <SelfTourPage
      selfTourDetail={selfTourDetail}
      isModalOpen={isModalOpen}
      onResetTourName={onResetTourName}
      onChangeTourName={onChangeTourName}
      onClosedBtnClicked={onClosedBtnClicked}
      handleScheduleChange={handleScheduleChange}
      onApplyBtnClicked={onApplyBtnClicked}
      handleModalOpen={handleModalOpen}
      handleResetPlaceList={handleResetPlaceList}
      handleSubmitSelfTour={handleSubmitSelfTour}
    />
  );
};

export default SelfTourContainer;
