export const colors = {
  primary: {
    primaryLighten: '#f9fbf2',
    primary: '#bbe309',
    primaryDark: '#94b20a',
    primaryBg: `linear-gradient(
    180.54deg,
    rgba(187, 227, 9, 0.24) 0.71%,
    rgba(187, 227, 9, 0) 99.79%
  )`,
  },
  secondary: {
    secondaryLighten: '#f1e1f4',
    secondary: '#b867c6',
    secondaryDark: '#863895',
  },
  greyScale: {
    white: '#ffffff',
    grey1: '#fafafa',
    grey2: '#eeeeee',
    grey3: '#d9d9d9',
    grey4: '#999999',
    grey5: '#767676',
    black: '#111111',
  },

  system: {
    error: '#dc0000',
    success: '#42c803',
  },
};
