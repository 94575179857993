import React, { useCallback, useEffect, useState } from 'react';
import Map from '../components/Map';
import { images } from '@components/assets/images';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { useSetRecoilState } from 'recoil';
import { nearByState } from '@store/atom/NearByAtom';
import { markerState } from '@store/atom/MapAtom';

declare global {
  interface Window {
    initMap: () => void;
  }
}

type Props = {
  placeList?: PlaceDetailType[];
};

const MapContainer = ({ placeList }: Props) => {
  const setValue = useSetRecoilState(nearByState);
  const setClickedMarker = useSetRecoilState(markerState);
  const [map, setMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<
    { marker: google.maps.Marker; place: PlaceDetailType }[]
  >([]);

  const loadCurrentPos = useCallback(() => {
    navigator.geolocation.getCurrentPosition((e) => {
      const { latitude, longitude } = e.coords;

      setValue((prev) => {
        const clone = { ...prev };
        clone.x = longitude;
        clone.y = latitude;
        return clone;
      });

      loadMap(latitude, longitude);
    });
  }, []);

  const loadMap = useCallback((lat: number, lng: number) => {
    const map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: { lat, lng },
        zoom: 16,
        disableDefaultUI: true,
      },
    );

    setMap(map);
    new google.maps.Marker({
      position: { lat: lat, lng: lng },
      map: map,
      icon: images.myPosition,
    });
  }, []);

  const clearMarkers = () => {
    markers.forEach(({ marker }) => {
      marker.setMap(null);
    });

    setMarkers([]);
  };

  const loadMarkers = (placeList?: PlaceDetailType[]) => {
    clearMarkers();
    const newMarkerList = placeList?.slice(0, 40).map((place) => {
      return {
        marker: new google.maps.Marker({
          position: { lat: place.point.y, lng: place.point.x },
          map: map,
          icon: images.marker,
        }),
        place,
      };
    });

    newMarkerList && setMarkers(newMarkerList);
  };

  useEffect(() => {
    loadCurrentPos();
  }, []);

  useEffect(() => {
    placeList && loadMarkers(placeList);
  }, [placeList]);

  useEffect(() => {
    markers.forEach(({ marker, place }) => {
      marker.addListener('click', () => {
        setClickedMarker(place);
      });
    });
  }, [markers]);

  return <Map />;
};

export default MapContainer;
