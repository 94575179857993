import { colors } from '@components/assets/colors';
import {
  GREY1,
  GREY2,
  GREY5,
  PRIMARY,
  PRIMARY_DARK,
  SECONDARY_DARK,
  WHITE,
} from '@constants/colors';
import styled from '@emotion/styled';

export const ScheduleSectionStyledSection = styled.section`
  background-color: ${WHITE};
  border-top: 8px solid ${GREY1};
`;

export const DateSectionDiv = styled.div`
  display: flex;
  padding: 0 20px;
  overflow-x: auto;
  border-bottom: 1px solid ${GREY2};

  .date {
    flex-shrink: 0;
    display: flex;
    min-width: 94px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    border-bottom: 2px solid ${WHITE};

    .main {
      color: ${GREY5};
    }

    &.selected {
      border-color: ${PRIMARY_DARK};

      .main {
        color: ${PRIMARY_DARK};
      }
    }
  }
`;

export const LocationItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .time-container {
    position: relative;
    display: flex;
    align-items: center;

    .dot {
      position: absolute;
      left: -14px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${SECONDARY_DARK};
    }

    .line {
      position: absolute;
      left: -10px;
      top: 6px;
      height: 158px;
      width: 2px;
      background-color: ${colors.secondary.secondaryLighten};
    }

    .time {
      color: ${SECONDARY_DARK};
    }
  }

  .place-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 13px;

    .img-wrapper {
      flex-shrink: 0;
      width: 56px;
      height: 56px;
      border-radius: 6px;
      background-color: ${GREY2};
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;

      &.error {
        width: 40px;
        height: 40px;
      }
    }

    .location-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
    }
  }
`;
