/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';
import { markerState } from '@store/atom/MapAtom';
import PlaceListItemContainer from '../../common/PlaceListItem/containers/PlaceListItemContainer';
import { Modal } from '@components/common/Modal/Modal';

const MarkerBottomSheetContainer = () => {
  const targetElementRef = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useRecoilState(markerState);

  return (
    <div
      css={css`
        width: 100%;
        max-width: 500px;
      `}
      ref={targetElementRef}>
      <div className='body-scroll-lock-ignore'>
        <Modal
          isOpen={!!marker}
          onCloseClick={() => {
            setMarker(null);
          }}>
          <Modal.Header
            title={'선택 장소'}
            hasCloseBtn={true}
            onCloseClick={() => {
              setMarker(null);
            }}
          />
          <Modal.Content>
            {marker && <PlaceListItemContainer place={marker} />}
          </Modal.Content>
        </Modal>
      </div>
    </div>
  );
};

export default MarkerBottomSheetContainer;
