/** @jsxImportSource @emotion/react */
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { css } from '@emotion/react';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import MapListItemContainer from '@components/common/PlaceListItem/containers/PlaceListItemContainer';
import MapListOptionContainer from '@components/NearBy/containers/MapListOptionContainer';
import { GREY2 } from '@constants/colors';
import SearchSectionContainer from '../containers/SearchSectionContainer';

type Props = {
  placeList?: PlaceDetailType[];
};

const AddPlaceList = ({ placeList }: Props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        max-height: 100vh;
      `}>
      <PageHeaderContainer title='장소 추가' />
      <div
        css={css`
          flex: 1;
          overflow-y: auto;
          border-top: 1px solid ${GREY2};
        `}>
        <SearchSectionContainer />
        <MapListOptionContainer />
        {placeList?.map((item) => (
          <MapListItemContainer key={item.id} place={item} />
        ))}
      </div>
    </div>
  );
};

export default AddPlaceList;
