import { images } from '@components/assets/images';
import React from 'react';

type Props = {
  title: string;
  onClosedBtnClick: () => void;
};

const CBottomSheetHeader = ({ title, onClosedBtnClick }: Props) => {
  return (
    <div>
      <div className='handle-bar-container'>
        <div className='bar' />
      </div>
      <div className='header-container'>
        <h1 className='h2'>{title}</h1>
        <button className='close-btn' onClick={onClosedBtnClick}>
          <img src={images.header.close} alt='close-button' />
        </button>
      </div>
    </div>
  );
};

export default CBottomSheetHeader;
