import {
  LocationDetailTabType,
  PlaceDetailType,
} from '@typedef/components/Location/location.types';
import React, { useCallback, useState } from 'react';
import TabSection from '../components/TabSection';

type Props = {
  place: PlaceDetailType;
};

const TabSectionContainer = ({ place }: Props) => {
  const [selectedTab, setSelectedTab] =
    useState<LocationDetailTabType>('Comment');

  const handleChangeTab = useCallback((tab: LocationDetailTabType) => {
    setSelectedTab(tab);
  }, []);

  return (
    <TabSection
      place={place}
      selectedTab={selectedTab}
      handleChangeTab={handleChangeTab}
    />
  );
};

export default TabSectionContainer;
