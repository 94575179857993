import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { API_ROUTE, request } from '@libs/api';
import PlaceGridItem from '../PlcaeGridItem';
import { useRecoilState } from 'recoil';
import { authState } from '@store/atom/AuthAtom';
import { useLoginRedirect } from '@hooks/useGotoLogin';
import { PlaceLikeRepository } from 'repositories/placeLikeRepository';

type Props = {
  place: PlaceDetailType;
  maxWidth?: number;
};

const PlaceGridItemContainer = ({ place, maxWidth = 225 }: Props) => {
  const navigate = useNavigate();
  const placeLikeRepo = new PlaceLikeRepository();
  const [auth] = useRecoilState(authState);
  const { loginRedirect } = useLoginRedirect();
  const [liked, setLiked] = useState(place.liked);

  const handleClick = useCallback(() => {
    navigate(`/place/detail?id=${place.id}&name=${encodeURI(place.name)}`, {
      state: { ...place, liked },
    });
  }, [navigate, place, liked]);

  const handleLikeClick = useCallback(async () => {
    if (!auth.isLoggedIn) {
      loginRedirect();
      return;
    }

    const {
      data,
      config: { isSuccessful },
    } = await placeLikeRepo.post(place.id, liked);

    if (!isSuccessful) {
      alert('요쳥에 실패했습니다');
      return;
    }

    setLiked(data.liked);
  }, [liked, place]);

  return (
    <PlaceGridItem
      place={place}
      maxWidth={maxWidth}
      liked={liked}
      onClick={handleClick}
      handleLikeClick={handleLikeClick}
    />
  );
};

export default PlaceGridItemContainer;
