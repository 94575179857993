/** @jsxImportSource @emotion/react */
import { GREY1 } from 'constants/colors';
import React from 'react';
import { css } from '@emotion/react';
import { images } from '@components/assets/images';

type Props = {
  value: string;
  className: string;
  inputRef: React.RefObject<HTMLInputElement>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearBtnClicked: () => void;
  variant?: 'shadow' | 'transparent';
  isFocus: Boolean;
  onBlur: () => void;
  onFocus: () => void;
  placeholder?: string;
  onSubmit?: () => void;
};

const borderTypes = {
  default: `background: ${GREY1};`,
  shadow: `background: #ffffff`,
  transparent: `background: rgba(255, 255, 255, 0.2);
                border: 1px solid #FFFFFF;`,
};

const SearchBox = ({
  value,
  className,
  inputRef,
  onChange,
  onClearBtnClicked,
  variant,
  isFocus,
  onBlur,
  onFocus,
  placeholder,
  onSubmit,
}: Props) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}>
      <div
        className={className}
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          flex: 1;
          /* margin: 0 20px; */
          height: 48px;
          padding: 0 16px;

          gap: 8px;
          overflow: hidden;
          ${variant ? borderTypes[variant] : borderTypes['default']};
          border-radius: 6px;
          ${variant === 'shadow'
            ? 'box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)'
            : ''}
        `}>
        <img
          css={css`
            height: 18px;
            width: 18px;
          `}
          src={
            variant === 'transparent'
              ? images.searchWhite
              : isFocus
              ? images.searchBlack
              : images.search
          }
          alt=''
        />
        <input
          css={css`
            flex: 1;
            overflow: hidden;
            font-weight: 400;
            font-size: 15px;
            ${variant ? borderTypes[variant] : borderTypes['default']};
            ${variant === 'transparent'
              ? `background: rgba(255, 255, 255, 0);
            border: none;
            color: ${GREY1};

            &::placeholder {
              color: ${GREY1};
            }`
              : ''}
          `}
          value={value}
          type='text'
          ref={inputRef}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {value ? (
          <button
            type='button'
            css={css`
              width: 18px;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
            onClick={onClearBtnClicked}>
            <img
              css={css`
                width: 15px;
                height: 15px;
              `}
              src={images.delete}
              alt='delete-button-image'
            />
          </button>
        ) : (
          ''
        )}
      </div>
    </form>
  );
};

export default SearchBox;
