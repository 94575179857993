/** @jsxImportSource @emotion/react */
import ChipsContainer from '@components/common/button/containers/ChipsContainer';
import React, { useContext } from 'react';
import * as S from '../styles/MapListOptionStyles';
import ChipsFilterContainer from '@components/common/button/containers/ChipsFilterContainer';
import { PlaceListContext } from '../context/PlaceListProvider';
import { useCommonQuery } from '@hooks/api/useCommonQuery';

const ListOption = () => {
  const {
    value: { placeTypeId, activityIds, unitIds, regionId },
    actions: { updateContext },
  } = useContext(PlaceListContext);

  const { placeTypeList, regionList, unitList, activityList } =
    useCommonQuery();

  return (
    <>
      <S.ItemContainer className='map-item'>
        <S.Item
          onClick={() => {
            updateContext({ placeTypeId: 0, page: 0 });
          }}
          isSelected={placeTypeId === 0}>
          {'전체'}
        </S.Item>
        {placeTypeList.data?.map((item, idx) => {
          return (
            <S.Item
              key={item.id}
              onClick={() => {
                updateContext({ placeTypeId: item.id, page: 0 });
              }}
              isSelected={placeTypeId === item.id}>
              {item.name}
            </S.Item>
          );
        })}
      </S.ItemContainer>
      <S.OptionItemContainer className='map-item'>
        <ChipsContainer
          isClick={false}
          btntext={'전체'}
          onClickHandler={() => {
            updateContext({
              isSortedByRate: false,
              activityIds: new Set(),
              unitIds: new Set(),
              page: 0,
              regionId: -1,
            });
          }}
        />
        <ChipsFilterContainer
          btntext={'지역별'}
          isClick={regionId !== -1}
          onClickHandler={(id) => {
            regionId === id
              ? updateContext({
                  regionId: -1,
                  page: 0,
                })
              : updateContext({
                  regionId: id,
                  page: 0,
                });
          }}
          list={regionList.data ?? []}
          selectedIds={[regionId]}
        />
        <ChipsFilterContainer
          btntext={'활동별'}
          isClick={activityIds.size !== 0}
          onClickHandler={(id) => {
            const clone = new Set(activityIds);
            clone.has(id) ? clone.delete(id) : clone.add(id);
            updateContext({ page: 0, activityIds: clone });
          }}
          list={activityList.data ?? []}
          selectedIds={Array.from(activityIds.values())}
        />
        <ChipsFilterContainer
          btntext={'유닛별'}
          isClick={unitIds.size !== 0}
          onClickHandler={(id) => {
            const clone = new Set(unitIds);
            clone.has(id) ? clone.delete(id) : clone.add(id);
            updateContext({ page: 0, unitIds: clone });
          }}
          list={unitList.data ?? []}
          selectedIds={Array.from(unitIds.values())}
        />
      </S.OptionItemContainer>
    </>
  );
};

export default ListOption;
