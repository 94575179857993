import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchBox from '../SearchBox';

type Props = {
  value: string;
  className: string;
  onChange: ({ value }: { value: string }) => void;
  onReset: () => void;
  variant?: 'shadow' | 'transparent';
  placeholder?: string;
  onSubmit?: () => void;
};

const SearchBoxContainer = ({
  value,
  className,
  onChange,
  onReset,
  variant,
  placeholder,
  onSubmit,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [text, setText] = useState(value);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setText(value);
      onChange({ value });
    },
    [onChange],
  );

  const onClearBtnClicked = useCallback(() => {
    if (inputRef.current) {
      onReset();
      setText('');
      inputRef.current.focus();
    }
  }, [onReset]);

  const onFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <SearchBox
      value={text}
      className={className}
      inputRef={inputRef}
      onChange={handleChange}
      onClearBtnClicked={onClearBtnClicked}
      variant={variant}
      isFocus={isFocus}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      onSubmit={onSubmit}
    />
  );
};

export default SearchBoxContainer;
