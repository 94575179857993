import React from 'react';
import MyPageSelectBox from '../MyPageSelectBox';

type Props = {
  onClickCloseBtn: () => void;
};

const MyPageSelectBoxContainer = ({ onClickCloseBtn }: Props) => {
  return <MyPageSelectBox onClickCloseBtn={onClickCloseBtn} />;
};

export default MyPageSelectBoxContainer;
