import SocialLoginButtonContainer from '@components/common/button/containers/SocialLoginButtonContainer';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles/login.css';
import { getOauthURL } from '@libs/api';
import { images } from '@components/assets/images';

const LoginPage = () => {
  return (
    <div className='login-root'>
      <div className='login-background-section'></div>
      <div className='title-section'>
        <h1 className='title'>환영해요 시즈니</h1>
        <h3 className='sub-title'>더 편하게 히어시티를 이용해보세요.</h3>
      </div>
      <div className='login-section'>
        <a href={getOauthURL('kakao')}>
          <SocialLoginButtonContainer
            socialName='kakao'
            socialLogin='카카오로 로그인'
          />
        </a>
        <a href={getOauthURL('naver')}>
          <SocialLoginButtonContainer
            socialName='naver'
            socialLogin='네이버로 로그인'
          />
        </a>
        <a href={getOauthURL('google')}>
          <SocialLoginButtonContainer
            socialName='google'
            socialLogin='구글로 로그인'
          />
        </a>
      </div>
    </div>
  );
};

export default LoginPage;
