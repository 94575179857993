import { PaymentType } from '@typedef/components/Payment/payment.types';

const Day: { [key: number]: string } = {
  0: '일',
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
};

export function getOSVersion() {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes('Android')) {
    return 'android';
  } else if (userAgent.includes('iPhone')) {
    return 'ios';
  }
  return 'pc';
}

export function getPreviousMonth(month: number) {
  return month === 0 ? 11 : month - 1;
}

export function getNextMonth(month: number) {
  return month === 11 ? 0 : month + 1;
}

export function getPreviousYear(year: number, month: number) {
  return month === 0 ? year - 1 : year;
}

export function getNextYear(year: number, month: number) {
  return month === 11 ? year + 1 : year;
}

export const getCalendarDateFormat = (
  year: number,
  month: number,
  date: number,
) => {
  return `${year}-${month.toString().padStart(2, '0')}-${date
    .toString()
    .padStart(2, '0')}`;
};

export const dateStringFormat = (date: string) => {
  const dateArr = date.split('-');
  return `${dateArr[0]}년 ${dateArr[1]}월 ${dateArr[2]}일`;
};

export const timeFormat = (time: string) => {
  const timeArr = time.split(':');
  if (parseInt(timeArr[0]) > 12) {
    const hour = parseInt(timeArr[0]) - 12;

    return `오후 ${hour.toString().padStart(2, '0')}:${timeArr[1]
      .toString()
      .padStart(2, '0')}`;
  } else if (parseInt(timeArr[0]) === 12) {
    return `오후 ${timeArr[0].toString().padStart(2, '0')}:${timeArr[1]
      .toString()
      .padStart(2, '0')}`;
  } else {
    return `오전 ${timeArr[0].toString().padStart(2, '0')}:${timeArr[1]
      .toString()
      .padStart(2, '0')}`;
  }
};

export const getDateDiff = (days: string[]) => {
  const date1 = new Date(days[0]);
  const date2 = new Date(days.length > 1 ? days[1] : days[0]);

  const diffDate = date1.getTime() - date2.getTime();

  return Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24))) + 1;
};

export const getDateString = (schedule: Set<string>) => {
  const date = Array.from(schedule).sort();

  return schedule.size === 1
    ? `${date[0]}/${date[0]}`
    : `${date[0]}/${date[1]}`;
};

export const locationCategoryFormat = (category: string) => {
  if (category === '숙박') {
    return 'lodging';
  } else if (category === '액티비티') {
    return 'activity';
  } else if (category === '기타') {
    return 'etc';
  } else if (category === '식사') {
    return 'meal';
  } else if (category === '관광') {
    return 'tour';
  } else if (category === '교통') {
    return 'transportation';
  } else if (category === '쇼핑') {
    return 'shopping';
  } else {
    return 'tour';
  }
};

export const totalPriceByDate = (payByDay: PaymentType) => {
  let totalPrice = 0;

  payByDay.payList.map((item, idx) => {
    return (totalPrice += item.price);
  });
  return totalPrice;
};

export const totalPrice = (paymentList: PaymentType[]) => {
  let totalPrice = 0;

  paymentList.map((payItem, idx) => {
    return payItem.payList.map((item, iidx) => {
      return (totalPrice += item.price);
    });
  });
  return totalPrice;
};

export const distanceFormat = (distance: number) => {
  if (distance > 1000000) return '-';
  const distanceInt = Math.round(distance);
  const e = Math.log10(distanceInt);
  return e > 3 ? `${Math.round(distanceInt / 1000)} km` : `${distanceInt} m`;
};

//캘린더에서 선택한 날짜를 리퀘스트 요청 포맷인 ISO 포맷으로 변경
export const getTourPlaceTimeFormat = (
  date: string,
  selectedIdx: number,
  time: string,
) => {
  const timestamp =
    new Date(date).getTime() + selectedIdx * 60 * 60 * 24 * 1000;
  const newDate = new Date(timestamp);

  return new Date(
    `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
    }-${newDate.getDate()} ${time}`,
  ).toISOString();
};

/**
 *
 * @param date 투어 시작 날짜
 * @param selectedIdx 투어 시작일로부터 인덱스
 * @returns 투어 일자 스트링
 */
export const getTourScheduleFormat = (date: string, selectedIdx: number) => {
  const timestamp =
    new Date(date).getTime() + selectedIdx * 60 * 60 * 24 * 1000;
  const newDate = new Date(timestamp);

  return `${(newDate.getMonth() + 1).toString().padStart(2, '0')}.${newDate
    .getDate()
    .toString()
    .padStart(2, '0')}/${Day[newDate.getDay()]}`;
};

export const getTime = (time: string) => {
  const date = new Date(time);
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const ISOToScheduleFormat = (date: string) => {
  const d = new Date(date);

  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
};

export function calculateDday(targetDateString: string) {
  // 현재 날짜 구하기
  const currentDate = new Date();

  // 특정 날짜 구하기
  const targetDate = new Date(targetDateString.split('T')[0]);

  // 특정 날짜로부터 현재 날짜까지의 시간 차이 계산
  const timeDifference = currentDate.getTime() - targetDate.getTime();

  // 밀리초(ms)를 일(day)로 변환
  const daysDifference = Math.abs(
    Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
  );

  return daysDifference;
}
