import CBottomSheetContainer from '@components/common/CBottomSheet/containers/CBottomSheetContainer';
import {
  SelfTourDetailState,
  SelfTourModalState,
} from '@store/atom/SelfTourAtom';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { getTourPlaceTimeFormat } from '@libs/factory';
import ModalEditTime from '../components/ModalEditTime';

const ModalEditTimeContainer = () => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(SelfTourModalState);
  const [selfTourDetail, setSelfTourDetail] =
    useRecoilState(SelfTourDetailState);
  const { schedule, placeList, editableItemIdx, selectedDay } = selfTourDetail;
  const [time, setTime] = useState({ from: '09:00', to: '10:00' });

  const handleTimeChange = (key: 'from' | 'to', value: string) => {
    setTime((prev) => {
      const clone = { ...prev };
      clone[key] = value;
      return clone;
    });
  };

  const handleApplyClick = () => {
    if (time.from >= time.to) {
      alert('시작 시각을 종료 시각보다 빠르게 설정해주세요');
      return;
    }

    const clone = new Map(placeList);
    const activePlaceList = placeList.get(selectedDay) ?? [];

    activePlaceList[editableItemIdx].from = getTourPlaceTimeFormat(
      Array.from(schedule).sort()[0],
      selectedDay,
      time.from,
    );
    activePlaceList[editableItemIdx].to = getTourPlaceTimeFormat(
      Array.from(schedule).sort()[0],
      selectedDay,
      time.to,
    );

    clone.set(selectedDay, activePlaceList);

    setSelfTourDetail({
      ...selfTourDetail,
      placeList: clone,
      selectedPlace: null,
    });
    setIsModalOpen({ ...isModalOpen, time: false });
  };

  useEffect(() => {
    if (placeList.size === 0 || editableItemIdx === -1) return;

    const activePlaceList = placeList.get(selectedDay) ?? [];
    const from = new Date(activePlaceList[editableItemIdx].from);
    const to = new Date(activePlaceList[editableItemIdx].to);
    setTime({
      from: `${from.getHours().toString().padStart(2, '0')}:${from
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
      to: `${to.getHours().toString().padStart(2, '0')}:${to
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
    });
  }, []);

  return isModalOpen.time ? (
    <CBottomSheetContainer
      open={isModalOpen.time}
      title={'시간 수정'}
      onClosedBtnClick={() => {
        setIsModalOpen({ ...isModalOpen, time: false });
      }}
      onApplyBtnClick={handleApplyClick}>
      <ModalEditTime time={time} handleTimeChange={handleTimeChange} />
    </CBottomSheetContainer>
  ) : (
    <div></div>
  );
};

export default ModalEditTimeContainer;
