/** @jsxImportSource @emotion/react */
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { css } from '@emotion/react';
import InfoSectionContainer from './containers/InfoSectionContainer';
import PreviewSectionContainer from './containers/PreviewSectionContainer';
import TabSectionContainer from './containers/TabSectionContainer';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import FloatingBottomButtonContainer from '@components/common/FloatingBottomButton/containers/FloatingBottomButtonContainer';

type Props = {
  place: PlaceDetailType;
  searchParams: URLSearchParams;
  handleAddBtnClick: () => void;
};

const PlaceDetail = ({ place, searchParams, handleAddBtnClick }: Props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
      `}>
      <PageHeaderContainer />
      <div
        css={css`
          flex: 1;
          overflow-y: auto;
        `}>
        <PreviewSectionContainer place={place} />
        <InfoSectionContainer place={place} />
        <TabSectionContainer place={place} />
      </div>
      {/* 투어만들기에서 접근할 경우, 투어에 추가 버튼 렌더링 */}
      {searchParams.get('add') === 'true' && (
        <FloatingBottomButtonContainer>
          <ButtonContainer
            isFilled={true}
            btnText={'투어에 추가'}
            backgroundColor={'primary'}
            onClick={handleAddBtnClick}
          />
        </FloatingBottomButtonContainer>
      )}
    </div>
  );
};

export default PlaceDetail;
