import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { atom, selector } from 'recoil';

export const placeListState = atom<PlaceDetailType[]>({
  key: 'PLACE_LIST_KEY',
  default: [],
});

type NearByStateType = {
  isSubmitted: boolean;
  page: number;
  placeTypeId: number;
  activityIds: Set<number>;
  unitIds: Set<number>;
  name: string;
  x: number;
  y: number;
  placeList: PlaceDetailType[];
};

export const nearByState = atom<NearByStateType>({
  key: 'NEAR_BY_KEY',
  default: {
    isSubmitted: false,
    page: 0,
    placeTypeId: 0,
    activityIds: new Set(),
    unitIds: new Set(),
    name: '',
    x: 0,
    y: 0,
    placeList: [],
  },
});

export const nearBySelector = selector<Partial<NearByStateType>>({
  key: 'NEAR_BY_SELECTOR_KEY',
  get: ({ get }) => get(nearByState),
  set: ({ get, set }, newValue) =>
    set(nearByState, { ...get(nearByState), ...newValue }),
});
