/** @jsxImportSource @emotion/react */
import SearchBoxContainer from '@components/common/SearchBox/containers/SearchBoxContainer';
import { BLACK, WHITE } from '@constants/colors';
import { css } from '@emotion/react';

type Props = {
  searchInput: string;
  handleChangeInput: (value: string) => void;
  handleSubmit: () => void;
};

const SearchSection = ({
  searchInput,
  handleChangeInput,
  handleSubmit,
}: Props) => {
  return (
    <div
      css={css`
        background-color: ${WHITE};
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      `}>
      <div
        css={css`
          width: 100%;
        `}>
        <SearchBoxContainer
          onSubmit={handleSubmit}
          className='add-location-search'
          value={searchInput}
          onChange={({ value }) => {
            handleChangeInput(value);
          }}
          onReset={() => {
            handleChangeInput('');
          }}
        />
      </div>
    </div>
  );
};

export default SearchSection;
