import { authState } from '@store/atom/AuthAtom';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

export function useLoginRedirect() {
  const navigate = useNavigate();

  const loginRedirect = useCallback(() => {
    const isConfirm = window.confirm(
      '로그인이 필요한 기능입니다.\n로그인 페이지로 이동하시겠습니까?',
    );
    if (isConfirm) {
      navigate('/login');
    }
  }, [navigate]);

  return {
    loginRedirect,
  };
}
