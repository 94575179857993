import RecommendTab from '../components/RecommendTab';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { SelfTourDetailState } from '@store/atom/SelfTourAtom';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { getTokenFromStorage } from '@libs/storage';
import { TourType } from '@typedef/components/home/home.types';

type Props = {
  place: PlaceDetailType;
};

const RecommendTabContainer = ({ place }: Props) => {
  const [placeList, setPlaceList] = useState<PlaceDetailType[]>([]);
  const [tourList, setTourList] = useState<TourType[]>([]);

  const getPlaceList = useCallback(async () => {
    const {
      isTokenStored,
      token: { hc_eat },
    } = getTokenFromStorage('SESSION_STORAGE_TYPE');

    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place +
        buildQueryString({
          page: 0,
          size: 10,
          sort: 'rating',
          x: place.point.x,
          y: place.point.y,
        }),

      'get',
    );

    if (!isSuccessful) {
      alert('장소 리스트를 불러오는데에 실패했습니다');
      return;
    }
    setPlaceList(data.content);
  }, [place]);

  const getPopularTourList = useCallback(async () => {
    const {
      isTokenStored,
      token: { hc_eat },
    } = getTokenFromStorage('SESSION_STORAGE_TYPE');

    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<TourType>>(
      API_ROUTE.tour +
        buildQueryString({
          page: 0,
          limit: 10,
          name: place.title,
        }),

      'get',
    );

    if (!isSuccessful) {
      alert('장소 리스트를 불러오는데에 실패했습니다');
      return;
    }
    setTourList(data.content);
  }, [place]);

  useEffect(() => {
    getPopularTourList();
  }, [getPopularTourList]);

  useEffect(() => {
    getPlaceList();
  }, [getPlaceList]);

  return <RecommendTab placeList={placeList} tourList={tourList} />;
};

export default RecommendTabContainer;
