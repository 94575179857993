/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  text: string;
};

const MyPageCheckBox = ({ text }: Props) => {
  return (
    <StyledLabel htmlFor={text}>
      <StyledInput type="checkbox" id={text} name={text} />
      <StyledP className="text-m">{text}</StyledP>
    </StyledLabel>
  );
};

export default MyPageCheckBox;

const StyledInput = styled.input`
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid ${colors.greyScale.grey2};
  border-radius: 2px;

  &:checked {
    border-color: transparent;
    background-image: url(${images.selectboxAtv});
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    border: none;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
`;

const StyledP = styled.p`
  margin-left: 8px;
`;
