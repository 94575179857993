import ChipsContainer from '@components/common/button/containers/ChipsContainer';
import CalendarContainer from '@components/common/Calendar/containers/CalendarContainer';
import CBottomSheetContainer from '@components/common/CBottomSheet/containers/CBottomSheetContainer';
import { getDateString } from '@libs/factory';
import IsTourOpenContainer from './containers/ModalTourScopeContainer';
import './styles/selftour.css';
import CInputBoxContainer from '@components/common/CinputBox/containers/CInputBoxContainer';
import { images } from '@components/assets/images';
import ScheduleSectionContainer from './containers/ScheduleSectionContainer';
import {
  ScopeLabel,
  SelfTourDetailType,
  SelfTourModalType,
} from '@typedef/components/SelfTour/selftour.types';
import FloatingBottomButtonContainer from '@components/common/FloatingBottomButton/containers/FloatingBottomButtonContainer';
import TextButtonContainer from '@components/common/button/containers/TextButtonContainer';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import ModalExitTourContainer from './containers/ModalExitTourContainer';
import ModalSelectRegionContainer from './containers/ModalRegionContainer';
import ModalTourScopeContainer from './containers/ModalTourScopeContainer';

type Props = {
  selfTourDetail: SelfTourDetailType;
  isModalOpen: SelfTourModalType;
  handleModalOpen: (key: keyof SelfTourModalType, value: boolean) => void;
  onClosedBtnClicked: () => void;
  onApplyBtnClicked: () => void;
  onResetTourName: () => void;
  onChangeTourName: (value: string) => void;
  handleScheduleChange: (start: string, end: string) => void;
  handleResetPlaceList: () => void;
  handleSubmitSelfTour: () => void;
};
const SelfTourPage = ({
  selfTourDetail,
  isModalOpen,
  handleScheduleChange,
  onApplyBtnClicked,
  onResetTourName,
  onChangeTourName,
  handleModalOpen,
  handleResetPlaceList,
  handleSubmitSelfTour,
}: Props) => {
  return (
    <div className={`selftour-root' selftour-root`}>
      <div className={'title-section'}>
        <button
          className={'close-btn'}
          onClick={() => handleModalOpen('exit', true)}>
          <img className={'close'} src={images.header.close} alt='close' />
        </button>
        {isModalOpen.exit && (
          <ModalExitTourContainer
            handleModalOpen={(value) => {
              if (value) onApplyBtnClicked();
              else handleModalOpen('exit', value);
            }}
          />
        )}

        <h2 className={`h2 title`}>직접 투어 짜기</h2>
      </div>
      <div className={'subtitle-section'}>
        <h1 className='h1'>시즈니를 위한</h1>
        <h1 className='h1'>투어를 직접 계획해주세요</h1>
      </div>
      <div className={'tourInput-section'}>
        <CInputBoxContainer
          className='tourInput'
          value={selfTourDetail.name}
          variant='flushed'
          onChange={({ value }) => onChangeTourName(value)}
          onReset={onResetTourName}
          placeholder='투어 제목 입력'
        />
      </div>
      <div className={'btn-section'}>
        <ChipsContainer
          isClick={selfTourDetail.region.name !== '' ? true : false}
          onClickHandler={() => {
            handleModalOpen('region', true);
          }}
          btntext={selfTourDetail.region.name || '지역 선택'}
        />
        {isModalOpen.region && (
          <ModalSelectRegionContainer
            handleModalOpen={(value: boolean) =>
              handleModalOpen('region', value)
            }
          />
        )}
        <ChipsContainer
          isClick={selfTourDetail.schedule.size > 0}
          onClickHandler={() => {
            handleModalOpen('calendar', true);
          }}
          btntext={
            selfTourDetail.schedule.size > 0
              ? getDateString(selfTourDetail.schedule)
              : '날짜'
          }
        />
        <CalendarContainer
          open={isModalOpen.calendar}
          initalSelectDate={selfTourDetail.schedule}
          onClosedBtnClicked={() => handleModalOpen('calendar', false)}
          onChangeSchedule={handleScheduleChange}
        />
        {/* 알람기능은 첫 배포 때 빠짐 */}
        {/* <ChipsContainer
          isClick={isAlarmBtnClick}
          onClickHandler={() => {
            isAlarmBsOpenHandler(true);
          }}
          btntext={alarmText}
        />
        <AlarmContainer /> */}
        <ChipsContainer
          isClick={selfTourDetail.scope !== null}
          onClickHandler={() => {
            handleModalOpen('scope', true);
          }}
          btntext={
            selfTourDetail.scope
              ? ScopeLabel[selfTourDetail.scope]
              : '투어 공개 여부'
          }
        />
        {isModalOpen.scope && (
          <ModalTourScopeContainer
            handleModalOpen={(value: boolean) =>
              handleModalOpen('scope', value)
            }
          />
        )}
      </div>
      <div className={'tour-schedule-section'}>
        {selfTourDetail.region.name !== '' &&
        selfTourDetail.schedule.size > 0 ? (
          <ScheduleSectionContainer />
        ) : (
          <p className={`text-m text`}>
            {'지역과 날짜를 등록하면 투어 스케줄을\n한눈에 관리할 수 있어요'}
          </p>
        )}
      </div>
      <FloatingBottomButtonContainer>
        <div className={'floating-container'}>
          <div className={'button-container'}>
            <TextButtonContainer
              btnName={'초기화'}
              onClick={handleResetPlaceList}
            />
          </div>

          <ButtonContainer
            isFilled={true}
            btnText={'저장하기'}
            backgroundColor={'primary'}
            onClick={handleSubmitSelfTour}
          />
        </div>
      </FloatingBottomButtonContainer>
    </div>
  );
};

export default SelfTourPage;
