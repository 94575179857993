import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchPopup from '../components/SearchPopup';
import { useQuery } from '@tanstack/react-query';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { useRecoilState } from 'recoil';
import { nearByState } from '@store/atom/NearByAtom';

type Props = {
  onClosePopoup: () => void;
};

const SearchPopupContainer = (props: Props) => {
  const [value, setValue] = useRecoilState(nearByState);
  const { name, x, y } = value;

  const [searchInput, setSearchInput] = useState(name);

  const handleInputChange = (value: string) => {
    setSearchInput(value);
  };

  const getPlaceList = async () => {
    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place +
        buildQueryString({
          page: 0,
          limit: 5,
          x,
          y,
          name: searchInput,
          sort: 'distance',
        }),
      'get',
    );

    if (!isSuccessful) {
      throw new Error('장소 리스트를 불러오는데에 실패했습니다');
    }

    return data.content;
  };

  const { data } = useQuery([`nearbyPlaceNameList`, searchInput], getPlaceList);

  return (
    <SearchPopup
      {...props}
      searchInput={searchInput}
      searchedList={data}
      handleInputChange={handleInputChange}
    />
  );
};

export default SearchPopupContainer;
