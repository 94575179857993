import React from 'react';
import { LoadingDiv } from './styles/LoadingStyles';

const Loading = () => {
  return (
    <LoadingDiv>
      <div className='spinner' />
    </LoadingDiv>
  );
};

export default Loading;
