import React from 'react';

type CalendarContextTypes = {
  selectDate: Set<string>;
  handleDateClick: (date: string) => void;
  handleResetClick: () => void;
};

export const CalendarContext = React.createContext<CalendarContextTypes>({
  selectDate: new Set(),
  handleDateClick: () => {},
  handleResetClick: () => {},
});
