/** @jsxImportSource @emotion/react */
import React from 'react';
import styles from '../styles/selectregion.module.css';
import { ConstantItemType } from '@typedef/api/common.types';

type Props = {
  regionList: ConstantItemType[];
  selectRegionIdx: number;
  handleRegionBtnClick: (idx: number) => void;
};
const SelectRegionModal = ({
  regionList,
  selectRegionIdx,
  handleRegionBtnClick,
}: Props) => {
  return (
    <div className={styles['select-region-root']}>
      <p className={`text-xxl ${styles['text-title']}`}>
        어디로 떠나고 싶으신가요?
      </p>
      <div className={styles['region-card-container']}>
        {regionList.map((item, idx) => (
          <div
            onClick={() => handleRegionBtnClick(idx)}
            className={`${styles['region-card-body']}`}
            key={idx}>
            <button
              className={`${styles['region']}   ${
                styles[selectRegionIdx === idx ? 'select' : '']
              }`}>
              <img
                className={`${styles['region-img']}`}
                // src={item.image}
                alt=''
              />
              <p className={`text-xxl ${styles['text']}`}>{item.name}</p>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectRegionModal;
