import React, { memo } from 'react';
import InfoSection from '../components/InfoSection';
import { PlaceDetailType } from '@typedef/components/Location/location.types';

type Props = {
  place: PlaceDetailType;
};

const InfoSectionContainer = memo(({ place }: Props) => {
  return <InfoSection place={place} />;
});

export default InfoSectionContainer;
