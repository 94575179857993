import React from 'react';
import * as S from '../styles/calendarStyles';

const Days = () => {
  return (
    <S.DaysContainer>
      {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
        <div key={day} className={`day text-xl grey5`}>
          {day}
        </div>
      ))}
    </S.DaysContainer>
  );
};

export default Days;
