import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddPlaceList from '../components/AddPlaceList';
import { nearByState } from '@store/atom/NearByAtom';
import { useInfiniteQuery } from '@tanstack/react-query';

const AddPlaceListContainer = () => {
  const navigate = useNavigate();

  //recoil
  const [value, setValue] = useRecoilState(nearByState);
  const { placeTypeId, x, y, name, unitIds, activityIds } = value;
  const resetNearByState = useResetRecoilState(nearByState);

  const [searchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(
    searchParams.get('name') ?? '',
  );

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    [
      `infiniteNearbyPlaceList`,
      x,
      y,
      name,
      placeTypeId,
      Array.from(unitIds.values()).join('+'),
      Array.from(activityIds.values()).join('+'),
    ],
    ({ pageParam }) => getPlaceList(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.pageParam + 1 > lastPage.maxPage
          ? undefined
          : lastPage.pageParam + 1;
      },
      staleTime: 1000 * 60,
      refetchOnWindowFocus: true,
      cacheTime: 1000 * 60 * 5,
    },
  );

  const getPlaceList = async (pageParam = 0) => {
    const activityIdQueryString =
      activityIds.size !== 0
        ? Array.from(activityIds.values())
            .map((id) => `&activityId=${id}`)
            .join('')
        : '';

    const unitIdQueryString =
      unitIds.size !== 0
        ? Array.from(unitIds.values())
            .map((id) => `&unitId=${id}`)
            .join('')
        : '';

    const {
      data: {
        content,
        meta: { maxPage },
      },
      config: { isSuccessful },
    } = await request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place +
        buildQueryString({
          page: pageParam ?? 0,
          limit: 20,
          x,
          y,
          sort: 'distance',
          ...(placeTypeId !== 0 && { placeTypeId }),
          ...(name && { name }),
        }) +
        activityIdQueryString +
        unitIdQueryString,
      'get',
    );

    if (!isSuccessful) {
      alert('장소 리스트를 불러오는데에 실패했습니다');
      throw new Error('장소 리스트를 불러오는데에 실패했습니다');
    }

    setValue((prev) => {
      const clone = { ...prev };
      clone.page = pageParam;
      return clone;
    });

    return { content, pageParam, maxPage };
  };

  useEffect(() => {
    setSearchInput(searchParams.get('name') ?? '');
  }, [searchParams]);

  //리코일 값 바뀔 때마다 쿼리스트링 업데이트
  useEffect(() => {
    const obj = {
      add: 'true',
      x: value.x,
      y: value.y,
      name: value.name,
      placeTypeId: value.placeTypeId,
      unitIds: Array.from(value.unitIds.values()).join('+'),
      activityIds: Array.from(value.activityIds.values()).join('+'),
    };
    navigate(buildQueryString(obj), { replace: true });
  }, [value]);

  //장소 상세 페이지 들어갔다가 돌아왔을 때 쿼리스트링에 있는 필터링 값들 리코일에 저장
  useEffect(() => {
    setValue((prev) => {
      return {
        ...prev,
        x: searchParams.get('x') ? Number(searchParams.get('x')) : prev.x,
        y: searchParams.get('y') ? Number(searchParams.get('y')) : prev.y,
        name: searchParams.get('name') || prev.name,
        placeTypeId: searchParams.get('placeTypeId')
          ? Number(searchParams.get('placeTypeId'))
          : prev.placeTypeId,
        unitIds: searchParams.get('unitIds')
          ? new Set(
              searchParams
                .get('unitIds')
                ?.split('+')
                .map((item) => Number(item)),
            )
          : prev.unitIds,
        activityIds: searchParams.get('activityIds')
          ? new Set(
              searchParams
                .get('activityIds')
                ?.split('+')
                .map((item) => Number(item)),
            )
          : prev.activityIds,
      };
    });

    return () => {
      resetNearByState();
    };
  }, []);

  return (
    <AddPlaceList placeList={data?.pages.map((item) => item.content).flat(1)} />
  );
};

export default AddPlaceListContainer;
