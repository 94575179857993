import TextButtonContainer from '@components/common/button/containers/TextButtonContainer';
import LocationItem from './LocationItem';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import * as S from '../styles/EditModeStyles';
import { images } from '@components/assets/images';
import {
  SelfTourModalType,
  SelfTourPlaceType,
} from '@typedef/components/SelfTour/selftour.types';

import EditTimeModalContainer from '../containers/ModalEditTimeContainer';
import ModalEditDayContainer from '../containers/ModalEditDayContainer';
import ModalEditTimeContainer from '../containers/ModalEditTimeContainer';

type Props = {
  list: SelfTourPlaceType[];
  handlePlaceClick: (id: number) => void;
  handleEditDone: () => void;
  handleDeletePlaces: () => void;
  handleModalOpen: (key: keyof SelfTourModalType, value: boolean) => void;
};

const EditMode = ({
  list,
  handlePlaceClick,
  handleEditDone,
  handleDeletePlaces,
  handleModalOpen,
}: Props) => {
  return (
    <div>
      <S.SettingBarDiv>
        <button
          onClick={handleEditDone}
          className='done-btn text-l secondary-dark'>
          {'완료'}
        </button>
      </S.SettingBarDiv>
      <div className='location-list'>
        {list
          ?.sort((a, b) => (a.from < b.from ? -1 : 1))
          .map((item, itemIdx) => (
            <div key={item.place.id}>
              <S.DraggableItemDiv key={item.place.id}>
                <label htmlFor={`checkbox-${item.place.id}-${itemIdx}`}>
                  <img
                    src={
                      item.isSelected ? images.selectboxAtv : images.selectbox
                    }
                    alt='체크박스'
                  />
                  <input
                    hidden
                    onChange={() => handlePlaceClick(itemIdx)}
                    type='checkbox'
                    name={`checkbox-${item.place.id}-${itemIdx}`}
                    id={`checkbox-${item.place.id}-${itemIdx}`}
                  />
                </label>
                <div className='location-item'>
                  <LocationItem tourPlaceInfo={item} />
                </div>
              </S.DraggableItemDiv>
            </div>
          ))}
      </div>
      <S.EditLocationInfoSection>
        <ButtonContainer
          isDisabled={list.filter((item) => item.isSelected).length === 0}
          isFilled={false}
          btnText={'삭제'}
          backgroundColor={'primary'}
          buttonStyle={{ margin: '12px 0' }}
          onClick={handleDeletePlaces}
        />
        <ButtonContainer
          isDisabled={
            list.filter((item) => item.isSelected).length === 0 ||
            list.filter((item) => item.isSelected).length > 1
          }
          isFilled={false}
          btnText={'날짜 변경'}
          backgroundColor={'primary'}
          buttonStyle={{ margin: '12px 0' }}
          onClick={() => handleModalOpen('day', true)}
        />
        <ButtonContainer
          isDisabled={
            list.filter((item) => item.isSelected).length === 0 ||
            list.filter((item) => item.isSelected).length > 1
          }
          isFilled={false}
          btnText={'시간 수정'}
          backgroundColor={'primary'}
          buttonStyle={{ margin: '12px 0' }}
          onClick={() => handleModalOpen('time', true)}
        />
      </S.EditLocationInfoSection>
      <ModalEditDayContainer />
      <ModalEditTimeContainer />
    </div>
  );
};

export default EditMode;
