import {
  SelfTourDetailType,
  SelfTourModalType,
  SelfTourPlaceType,
} from '@typedef/components/SelfTour/selftour.types';
import { atom, selector } from 'recoil';

export const SelfTourDetailState = atom<SelfTourDetailType>({
  key: 'selfTourState',
  default: {
    name: '',
    region: { id: 0, name: '' },
    scope: null,
    schedule: new Set<string>(),
    selectedPlace: null,
    selectedDay: 0,
    editableItemIdx: -1,
    placeList: new Map<number, SelfTourPlaceType[]>(),
  },
});

export const SelfTourDetailSelector = selector<Partial<SelfTourDetailType>>({
  key: 'selfTourSelector',
  get: ({ get }) => get(SelfTourDetailState),
  set: ({ get, set }, newValue) =>
    set(SelfTourDetailState, { ...get(SelfTourDetailState), ...newValue }),
});

export const SelfTourModalState = atom<SelfTourModalType>({
  key: 'SelfTourModalState',
  default: {
    exit: false,
    region: false,
    scope: false,
    calendar: false,
    time: false,
    day: false,
  },
});
