import { BLACK, GREY2, WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const PageHeader = styled.header`
  min-height: 56px;

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 56px;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${WHITE};
    /* border-bottom: 1px solid ${GREY2}; */
    z-index: 3;

    button {
      position: absolute;
      left: 20px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .h2 {
      color: ${BLACK};
    }
  }
`;
