/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { images } from '@components/assets/images';
import { useNavigate } from 'react-router-dom';
import { colors } from '@components/assets/colors';
import MyReviewCard from '@components/common/MyReviwCard/MyReviewCard';

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  text-align: center;
  padding: 17px 0 17px 0;
  background-color: white;
`;

const BackButtonSection = styled.section`
  position: absolute;
  left: 24px;
`;

const Separator = styled.div`
  background-color: ${colors.greyScale.grey1};
  height: 8px;
  width: 100%;
`;

const MyReview = () => {
  const navigate = useNavigate();

  return (
    <RootContainer>
      <Header>
        <BackButtonSection onClick={() => navigate('/mypage')}>
          <img
            src={images.arrowBack}
            alt=''
            css={css`
              width: 24px;
              height: 24px;
            `}
          />
        </BackButtonSection>
        <h1
          css={css`
            flex: 1;
            font-weight: 700;
            font-size: 18px;
            letter-spacing: -0.02em;
          `}>
          나의 후기
        </h1>
      </Header>
      <Separator
        css={css`
          margin-top: 56px;
        `}
      />
      <MyReviewCard />
      <Separator
        css={css`
          margin-top: 12px;
        `}
      />
      <MyReviewCard />
    </RootContainer>
  );
};

export default MyReview;
