/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import StarRatingContainer from '../StarRating/containers/StarRatingContainer';
import styled from '@emotion/styled';
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import CInputBox from '../CinputBox/CInputBox';
import CInputBoxContainer from '../CinputBox/containers/CInputBoxContainer';

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const AddPicture = styled.div`
  background-color: ${colors.greyScale.grey2};
  border-radius: 6px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

type Props = {
  test: number;
};

const CommentListItem = ({ test }: Props) => {
  const [rate, setRate] = useState(100);

  const onHandleRate = useCallback((num: number) => {
    setRate(num);
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}>
      <div className='text-m'>송도 해상 케이블카</div>
      <div
        css={css`
          margin-top: 16px;
        `}>
        <CInputBoxContainer
          onChange={function ({ value }: { value: string }): void {
            throw new Error('Function not implemented.');
          }}
          onReset={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
        <AddPicture>
          <img
            css={css`
              height: 24px;
              width: 24px;
            `}
            src={images.camera2}
            alt=''
          />
        </AddPicture>
        <RatingContainer>
          <StarRatingContainer
            rate={rate}
            onHandleRate={onHandleRate}
            test={test}
          />
        </RatingContainer>
      </div>
    </div>
  );
};

export default CommentListItem;
