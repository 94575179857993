/** @jsxImportSource @emotion/react */
import MyPageBottomSheetContent from '@components/MyPage/components/BottomSheet/MyPageBottomSheetContent';
import MyPageCheckBoxContainer from '@components/MyPage/components/CheckBox/containers/MyPageCheckBoxContainer';
import MyPageHeaderContainer from '@components/MyPage/components/Header/containers/MyPageHeaderContainer';
import MyPageSelectBoxContainer from '@components/MyPage/components/SelectBox/containers/MyPageSelectBoxContainer';
import { colors } from '@components/assets/colors';
import Button from '@components/common/button/components/Button';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';

const WithdrawalPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ContainerStyled>
      <MyPageHeaderContainer title="탈퇴하기" />
      <h2
        css={css`
          font-weight: 700;
          font-size: 16px;
          margin: 0 20px;
          margin-top: 24px;
        `}
      >
        탈퇴 전 안내사항을 확인해주세요
      </h2>
      <ExplanationBoxStyled>
        <ExplanationListStyled>
          <li>
            내가 만든 투어일정, 찜한 장소, 후기, 프로필 등 모든 정보가
            삭제됩니다.
          </li>
          <li>한줄평은 삭제되지 않으니 미리 확인 바랍니다.</li>
          <li>
            이용중인 계정정보가 삭제되며 모든 데이터는 복구가 불가능합니다.
          </li>
        </ExplanationListStyled>
      </ExplanationBoxStyled>
      <CheckBoxAreaStyled>
        <MyPageCheckBoxContainer
          text={'탈퇴시 안내사항을 모두 확인하였으며 이에 동의합니다.'}
        />
      </CheckBoxAreaStyled>
      <DivisionStyled />
      <h2
        css={css`
          margin: 24px 20px 20px 20px;
          font-weight: 700;
          font-size: 16px;
        `}
      >
        떠나시는 이유를 알려주세요
      </h2>
      <SelectBoxAreaStyled>
        <MyPageSelectBoxContainer
          onClickCloseBtn={() => setIsModalOpen(true)}
        />
      </SelectBoxAreaStyled>
      <Footer>
        <DivisionStyled />
        <WithdrawalButtonAreaStyled>
          <Button
            isFilled={true}
            btnText={'프로필 수정 완료'}
            backgroundColor={'primary'}
            onClick={() => {}}
          />
        </WithdrawalButtonAreaStyled>
      </Footer>
      {isModalOpen && (
        <MyPageBottomSheetContent
          title={'탈퇴 사유 선택'}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </ContainerStyled>
  );
};

export default WithdrawalPage;

const ContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  padding-top: 56px;
`;

const ExplanationBoxStyled = styled.div`
  display: flex;
  margin: 0 20px;
  height: 156px;
  margin-top: 20px;
  background-color: ${colors.greyScale.grey1};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding: 0 17px;
`;

const ExplanationListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    margin-left: 17px;
    text-indent: -12px;
    color: ${colors.greyScale.grey5};
  }

  li::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: -5px 8px 0 0px;
    vertical-align: middle;
    background: ${colors.greyScale.grey5};
    border-radius: 50%;
  }
`;

const CheckBoxAreaStyled = styled.div`
  margin: 23px 20px 39px 20px;
`;

const DivisionStyled = styled.div`
  height: 1px;
  background-color: ${colors.greyScale.grey2};
`;

const SelectBoxAreaStyled = styled.div`
  margin: 0 20px;
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WithdrawalButtonAreaStyled = styled.div`
  margin: 7px 20px;
`;
