import { GREY2 } from '@constants/colors';
import styled from '@emotion/styled';

export const DefaultModePageDiv = styled.div`
  padding-bottom: 40px;
`;

export const DefaultModeHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid ${GREY2};
`;

export const DefaultLocationItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;

  .button-container {
    display: flex;
    gap: 9px;
  }
`;

export const AddPlaceByMySelfDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;
