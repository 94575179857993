import { images } from '@components/assets/images';
import {
  CSSProperties,
  Children,
  ForwardedRef,
  ReactNode,
  isValidElement,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import './styles/modal.styles.css';
import _ from 'lodash';

//header
type ModalHeaderProps = {
  title?: string;
  hasCloseBtn?: boolean;
  onCloseClick?: () => void;
};

const ModalHeader = ({
  title,
  hasCloseBtn,
  onCloseClick,
}: ModalHeaderProps) => {
  return (
    <div className='modal-header-root'>
      <div className='handle-bar-container'>
        <div className='bar' />
      </div>
      <div className='header-container'>
        <h1 className='h2'>{title}</h1>
        {hasCloseBtn && (
          <button className='close-btn' onClick={onCloseClick}>
            <img src={images.header.close} alt='close-button' />
          </button>
        )}
      </div>
    </div>
  );
};

const ModalHeaderType = (<ModalHeader />).type;

const getModalHeader = (children: ReactNode) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.filter(
    (child) => isValidElement(child) && child.type === ModalHeaderType,
  );
};

//content
type ModalContentProps = { children?: ReactNode };

const ModalContent = ({ children }: ModalContentProps) => {
  return <div className={'modal-content-root'}>{children}</div>;
};

const ModalContentType = (<ModalContent />).type;

const getModalContent = (children: ReactNode) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.filter(
    (child) => isValidElement(child) && child.type === ModalContentType,
  );
};

//footer

type ModalFooterProps = {
  children?: ReactNode;
};

const ModalFooter = ({ children }: ModalFooterProps) => {
  return <div className='modal-footer-root'>{children}</div>;
};

const ModalFooterType = (<ModalFooter />).type;

const getModalFooter = (children: ReactNode) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.filter(
    (child) => isValidElement(child) && child.type === ModalFooterType,
  );
};

//main modal

type ModalMainProps = {
  isOpen?: boolean;
  children: ReactNode;
  style?: CSSProperties;
  modalStyle?: CSSProperties;
  onCloseClick?: () => void;
};

const ModalMain = ({ children, onCloseClick }: ModalMainProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const modalHeader = getModalHeader(children);
  const modalContent = getModalContent(children);
  const modalFooter = getModalFooter(children);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.classList.remove('invisible');
    }, 0);
  }, []);

  return createPortal(
    <div
      className='modal-root'
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          onCloseClick && onCloseClick();
        }
      }}>
      <div ref={ref} className='modal-main invisible'>
        {modalHeader}
        {modalContent}
        {modalFooter}
      </div>
    </div>,
    document.body,
  );
};

export const Modal = Object.assign(ModalMain, {
  Header: ModalHeader,
  Content: ModalContent,
  Footer: ModalFooter,
});
