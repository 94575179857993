/** @jsxImportSource @emotion/react */
/** @jsxImportSource @emotion/react */
import React from 'react';
import BottomSheet from './components/BottomSheet';
import styled from '@emotion/styled';
import Button from '@components/common/button/components/Button';

const ControllerArea = styled.div`
  display: flex;
  margin-top: 47px;
  margin-bottom: 41px;
  gap: 8px;
  padding: 0 20px;
`;

type Props = {
  closeModal: () => void;
};

const MyReviewBottomSheet = ({ closeModal }: Props) => {
  return (
    <BottomSheet closeModal={closeModal}>
      <ControllerArea>
        <Button
          isFilled={false}
          btnText='삭제하기'
          backgroundColor='primary'
          onClick={() => {}}></Button>
        <Button
          isFilled={true}
          btnText='수정하기'
          backgroundColor='primary'
          onClick={() => {}}></Button>
      </ControllerArea>
    </BottomSheet>
  );
};

export default MyReviewBottomSheet;
