import React from 'react';
import SelfTourCloseModal from '../components/ModalExitTour';
import { Modal } from '@components/common/Modal/Modal';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';

type Props = { handleModalOpen: (value: boolean) => void };

const ModalExitTourContainer = ({ handleModalOpen }: Props) => (
  <Modal onCloseClick={() => handleModalOpen(false)}>
    <Modal.Header
      title='나가기'
      hasCloseBtn={true}
      onCloseClick={() => handleModalOpen(false)}
    />
    <Modal.Content>
      <SelfTourCloseModal />
    </Modal.Content>
    <Modal.Footer>
      <ButtonContainer
        isFilled={false}
        btnText={'취소'}
        backgroundColor={'primary'}
        onClick={() => handleModalOpen(false)}
      />
      <ButtonContainer
        isFilled={true}
        btnText={'삭제'}
        backgroundColor={'primary'}
        onClick={() => handleModalOpen(true)}
      />
    </Modal.Footer>
  </Modal>
);

export default ModalExitTourContainer;
