/** @jsxImportSource @emotion/react */
import { images } from '@components/assets/images';
import MultiFileInputContainer from '@components/common/MultiFileInput/containers/MultiFileInputContainer';
import CBottomSheetContainer from '@components/common/CBottomSheet/containers/CBottomSheetContainer';
import { GREY4 } from '@constants/colors';
import { css } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import CInputBoxContainer from '@components/common/CinputBox/containers/CInputBoxContainer';
import StarRatingContainer from '@components/common/StarRating/containers/StarRatingContainer';

type Props = {
  isOpen: boolean;
  onClosedBtnClick: () => void;
  rate: number;
  onHandleRate: (num: number) => void;
  files: File[];
  onSelectedFileChanged: (files: File[]) => void;
  inputValue: string;
  onChange: ({ value }: { value: string }) => void;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  handleCommentPost: () => void;
};

const CommentBottomSheet = ({
  isOpen,
  onClosedBtnClick,
  rate,
  files,
  onSelectedFileChanged,
  onHandleRate,
  inputValue,
  onChange,
  setInputValue,
  handleCommentPost,
}: Props) => {
  return (
    <div>
      <CBottomSheetContainer
        open={isOpen}
        title={'한줄평 남기기'}
        onClosedBtnClick={onClosedBtnClick}
        onApplyBtnClick={handleCommentPost}
        type={'one-button'}
        text={'한줄평 작성 완료'}>
        <div
          css={css`
            padding: 0 20px;
          `}>
          <span
            css={css`
              display: inline-block;
              margin-bottom: 24px;
            `}
            className='text-xxl'>
            {'이 장소에 대한 한줄평을 남겨주세요'}
          </span>
          <div
            css={css`
              margin-bottom: 36px;
            `}>
            <StarRatingContainer
              rate={rate}
              onHandleRate={onHandleRate}
              test={rate}
              size={48}
            />
          </div>
          <div
            css={css`
              margin-bottom: 36px;
            `}>
            <MultiFileInputContainer
              initialValue={files}
              onSelectdFilesChanged={onSelectedFileChanged}
            />
          </div>
          <CInputBoxContainer
            value={inputValue}
            onChange={onChange}
            onReset={() => {
              setInputValue('');
            }}
          />
          <p
            css={css`
              font-size: 12px;
              color: ${GREY4};
              white-space: pre-line;
              line-height: 16px;
              margin-top: 12px;
            `}
            className=''>
            {`한줄평 장인으로 소문난 시즈니들!\n공백포함 50자 이내로 한줄평을 남겨주세요`}
          </p>
        </div>
      </CBottomSheetContainer>
    </div>
  );
};

export default CommentBottomSheet;
