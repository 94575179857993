import { BLACK, GREY2, GREY3, GREY5, PRIMARY, WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const ModalContentRoot = styled.div`
  padding: 12px 20px 40px;

  .description {
    margin: 20px 0;
  }
`;

export const Section = styled.section`
  padding: 24px 20px;

  .title {
    padding: 12px 0;
  }
`;

export const OptionItemLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid ${GREY3};
  .text {
    color: ${GREY5};

    &.check {
      color: ${BLACK};
    }
  }

  .public-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    border: 1px solid ${GREY3};

    &.check {
      background-color: ${PRIMARY};
      border-color: ${PRIMARY};
    }

    .inner-btn {
      border-radius: 20px;
      width: 12px;
      height: 12px;
      background-color: ${GREY3};

      &.check {
        background-color: white;
      }
    }
  }
`;

export const TimeItem = styled.label`
  .time-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid ${GREY2};
    .time-text {
    }
    .input-time {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      .time {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const DoneButtonDiv = styled.div`
  .button-section {
    position: fixed;
    border: 0;
    bottom: 0;
    padding: 7px 20px;
    width: 100%;
    background-color: ${WHITE};
  }

  .dummy {
    min-height: 70px;
  }
`;
