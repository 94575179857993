/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

type Props = {
  starArray: string[];
  calculateScore: (e: React.MouseEvent) => void;
  ratesResArr: number[];
  size?: number;
};

const StarRating = ({
  starArray,
  calculateScore,
  ratesResArr,
  size,
}: Props) => {
  return (
    <RatingBox onClick={calculateScore}>
      {starArray.map((item, idx) => {
        return (
          <span className="star_icon" key={`${item}_${idx}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={size || '32'}
              height={size || '32'}
              viewBox="0 0 14 13"
              fill="#EEEEEE"
            >
              {/* id는 별 하나하나 마다 다른 값을 가지고 있어야 합니다 */}
              <clipPath id={`${item}StarClip`}>
                {/* 새로 생성한 리스트에서 별 길이를 넣어줍니다. */}
                <rect width={`${ratesResArr[idx]}`} height="48" />
              </clipPath>
              <path
                id={`${item}Star`}
                d="M9,2l2.163,4.279L16,6.969,12.5,10.3l.826,4.7L9,12.779,4.674,15,5.5,10.3,2,6.969l4.837-.69Z"
                transform="translate(-2 -2)"
              />
              <use
                clipPath={`url(#${item}StarClip)`}
                href={`#${item}Star`}
                fill="#C4E149"
              />
            </svg>
          </span>
        );
      })}
    </RatingBox>
  );
};

export default StarRating;

const RatingBox = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 6px;
`;
