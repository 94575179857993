/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import { LoadingDiv } from '@components/common/Loading/styles/LoadingStyles';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { ISOToScheduleFormat, calculateDday, getDateDiff } from '@libs/factory';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { TourType } from '@typedef/components/home/home.types';
import React from 'react';

// 더미 데이터
const tourItinerary = [
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
  {
    title: '제노따라 자전거 여행',
    dDay: 12,
    itinerary: '12.20/화 ~ 12.22/목',
    img: images.dummy.location1,
  },
];

const ComingTourContent = () => {
  const getUpcomingTourList = async (pageParam = 0) => {
    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<TourType>>(
      API_ROUTE.tour +
        '/me' +
        buildQueryString({
          page: pageParam,
          limit: 10,
          isPast: 'false',
        }),
      'get',
    );

    if (!isSuccessful) {
      throw Error('장소 리스트를 불러오는데에 실패했습니다');
    }

    return { content: data.content, pageParam, maxPage: data.meta.maxPage };
  };

  const { data, isLoading, isSuccess } = useInfiniteQuery(
    ['upcomingTourQuery'],
    ({ pageParam }) => getUpcomingTourList(pageParam),
  );

  if (isLoading) {
    return (
      <ComeTourContentSection>
        <LoadingDiv />
      </ComeTourContentSection>
    );
  }
  return (
    <ComeTourContentSection>
      {isSuccess ? (
        <>
          {data?.pages
            .map((item) => item.content)
            .flat(1)
            .map((item) => {
              return (
                <TourCard key={item.id}>
                  <img
                    height={60}
                    width={60}
                    css={css`
                      border-radius: 6px;
                      margin-right: 10px;
                      object-fit: cover;
                    `}
                    src={`${images.tour}${Math.floor(
                      Math.random() * 5 + 1,
                    )}.svg`}
                    alt='투어 썸네일'
                  />
                  <div>
                    <div className='text-m'>{item.name}</div>
                    <div
                      className='text-xs'
                      css={css`
                        margin-top: 5px;
                        color: ${colors.greyScale.grey5};
                      `}>
                      {`${ISOToScheduleFormat(
                        item.from,
                      )} ~ ${ISOToScheduleFormat(item.to)}`}
                    </div>
                  </div>
                  <div
                    className='text-xl'
                    css={css`
                      margin-left: auto;
                    `}>
                    D-{calculateDday(item.from)}
                  </div>
                </TourCard>
              );
            })}
        </>
      ) : (
        ''
      )}
    </ComeTourContentSection>
  );
};

export default ComingTourContent;

const ComeTourContentSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 23px 20px;
`;

const TourCard = styled.div`
  display: flex;
  background-color: ${colors.greyScale.grey1};
  border: 1px solid ${colors.greyScale.grey2};
  border-radius: 6px;
  height: 92px;
  min-width: 321px;
  padding: 16px;
  align-items: center;
`;
