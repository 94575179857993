/** @jsxImportSource @emotion/react */
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { PlaceType } from '../containers/AddOtherPlaceContainer';
import { images } from '@components/assets/images';
import CInputBoxContainer from '@components/common/CinputBox/containers/CInputBoxContainer';
import styled from '@emotion/styled';
import { colors } from '@components/assets/colors';
import ChipswithImageContainer from '@components/common/button/containers/ChipswithImageContainer';
import { css } from '@emotion/react';
import { useCallback, useEffect, useState } from 'react';
import CRadioListItem from '@components/common/CListItems/components/CRadioListItem';
import Button from '@components/common/button/components/Button';

const AddOtherPlace = () => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dateList, setDateList] = useState(['1일차', '2일차', '3일차']);
  const [selectedValue, setSelectedValue] = useState('1일차');

  // const onRadioClicked = useCallback(() => {
  //   setIsChecked((prev) => setSelectedValue());
  // }, []);

  const onHandleChangeRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.value);

      setSelectedValue(e.target.value);
    },
    [],
  );

  const onChangeStartTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setStartTime(e.target.value);
    },
    [],
  );

  const onChangeEndTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEndTime(e.target.value);
    },
    [],
  );

  // useEffect(() => {
  //   if (startTime >= endTime) {
  //     console.log('error!!');
  //     // setEndTime('');
  //   }
  //   console.log('[StartTime]', startTime);
  //   console.log('[EndTime]', endTime);
  // }, [startTime, endTime]);

  useEffect(() => {
    console.log(selectedValue);
  }, [selectedValue]);

  return (
    <div>
      <PageHeaderContainer isBackbuttonVisible={true} title='장소 직접 추가' />
      <div>
        <PlaceNameInputArea>
          <div>{'장소명'}</div>
          <CInputBoxContainer
            placeholder='실제 장소 명칭을 적어주세요'
            onChange={function ({ value }: { value: string }): void {}}
            onReset={function (): void {}}
          />
        </PlaceNameInputArea>
        <AddressInputArea>
          <div>{'주소'}</div>
          <SearchInputBox>
            <SearchInput className='text-m' placeholder='주소를 검색해주세요' />
            <img src={images.search} alt='search' />
          </SearchInputBox>
        </AddressInputArea>
        <PlaceClassificationArea>
          <div>{'장소 분류'}</div>
          <ChipBox>
            {PlaceType.map((item) => {
              return (
                <div
                  css={css`
                    margin-right: 8px;
                    margin-top: 12px;
                  `}>
                  <ChipswithImageContainer
                    isClick={false}
                    btntext={item.name}
                    imgSrc={item.imgUrl}
                  />
                </div>
              );
            })}
          </ChipBox>
        </PlaceClassificationArea>
        <DateChoiceArea>
          <div className='sub-title'>{'날짜 선택'}</div>
          <div>
            {dateList.map((item, index) => (
              <CRadioListItem
                key={index.toString()}
                name={item}
                id={item}
                value={item}
                isChecked={item === `${selectedValue}`}
                labelText={item}
                handleClick={() => {
                  console.log('click');
                }}
                onChange={onHandleChangeRadio}
              />
            ))}
          </div>
        </DateChoiceArea>

        <TimeChoiceArea>
          <div className='sub-title'>{'시간 설정'}</div>
          <TimeLineStyled>
            <div>{'시작 시각'}</div>
            <input type='time' value={startTime} onChange={onChangeStartTime} />
          </TimeLineStyled>
          <TimeLineStyled>
            <div>{'종료 시각'}</div>
            <input
              type='time'
              min={startTime}
              value={endTime}
              onChange={onChangeEndTime}
            />
          </TimeLineStyled>
        </TimeChoiceArea>
      </div>
      <ButtonArea>
        <Button
          isFilled={true}
          btnText={'완료'}
          backgroundColor={'primary'}
          onClick={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
      </ButtonArea>
    </div>
  );
};

export default AddOtherPlace;

const PlaceNameInputArea = styled.div`
  margin: 24px 20px 0 20px;
  div {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

const AddressInputArea = styled.div`
  margin: 40px 20px 0 20px;
  div {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

const PlaceClassificationArea = styled.div`
  margin: 40px 20px 0 20px;
  div {
    font-weight: 700;
    font-size: 16px;
  }
`;

const DateChoiceArea = styled.div`
  margin: 40px 20px 0 20px;
  .sub-title {
    font-weight: 700;
    font-size: 16px;
  }
`;

const TimeChoiceArea = styled.div`
  margin: 40px 20px 140px 20px;
  .sub-title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 6px 20px;
  background-color: white;
  border-top: 1px solid ${colors.greyScale.grey2};
`;

const SearchInputBox = styled.div`
  position: relative;
  display: flex;
  height: 56px;
  width: 100%;

  img {
    position: absolute;
    height: 18px;
    width: 18px;
    left: 16px;
    top: 19px;
  }
`;

const SearchInput = styled.input`
  background: ${colors.greyScale.grey1};
  height: 56px;
  width: 100%;
  padding: 0 16px 0 42px;
  border: 1px solid ${colors.greyScale.grey2};
  border-radius: 6px;

  &:focus {
    border: 1px solid ${colors.primary.primary};
  }
`;

const ChipBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TimeLineStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  height: 48px;
  border-bottom: 1px solid ${colors.greyScale.grey2};

  input {
    color: ${colors.greyScale.grey5};
    width: fit-content;
    &:focus {
      color: black;
    }
  }
`;
