import { getDateDiff, getTourScheduleFormat } from '@libs/factory';
import * as S from '../styles/ScheduleSectionStyles';
import DefaultModeContainer from '../containers/DefaultModeContainer';
import EditModeContainer from '../containers/EditModeContainer';

type Props = {
  scheduleMode: 'default' | 'edit';
  schedule: Set<string>;
  activeDay: number;
  handleDateClick: (idx: number) => void;
};

const ScheduleSection = ({
  scheduleMode,
  schedule,
  activeDay,
  handleDateClick,
}: Props) => {
  return (
    <S.ScheduleSectionStyledSection>
      <S.DateSectionDiv>
        {new Array(getDateDiff(Array.from(schedule))).fill(0).map((_, idx) => {
          return (
            <div
              onClick={() => handleDateClick(idx)}
              className={`date ${idx === activeDay ? 'selected' : ''}`}>
              <span className={`main text-xxl `}>{`${idx + 1}일차`}</span>
              <span className={`sub text-m grey5`}>
                {getTourScheduleFormat(Array.from(schedule).sort()[0], idx)}
              </span>
            </div>
          );
        })}
      </S.DateSectionDiv>
      {scheduleMode === 'default' && <DefaultModeContainer />}
      {scheduleMode === 'edit' && <EditModeContainer />}
    </S.ScheduleSectionStyledSection>
  );
};

export default ScheduleSection;
