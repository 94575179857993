import { useQuery } from '@tanstack/react-query';
import { ActivityRepository } from 'repositories/activityRepository';
import { PlaceTypeRepository } from 'repositories/placeTypeRepository';
import { RegionRepository } from 'repositories/regionRepository';
import { UnitRepository } from 'repositories/unitRepository';

export const CONSTANT_STALE_TIME = 60 * 60 * 1000;

export function useCommonQuery() {
  const regionRepo = new RegionRepository();
  const placeTypeRepo = new PlaceTypeRepository();
  const unitRepo = new UnitRepository();
  const activityRepo = new ActivityRepository();

  const regionList = useQuery(regionRepo.queryKey.get, regionRepo.get, {
    staleTime: CONSTANT_STALE_TIME,
  });

  const placeTypeList = useQuery(
    placeTypeRepo.queryKey.get,
    placeTypeRepo.get,
    {
      staleTime: CONSTANT_STALE_TIME,
    },
  );

  const unitList = useQuery(unitRepo.queryKey.get, unitRepo.get, {
    staleTime: CONSTANT_STALE_TIME,
  });

  const activityList = useQuery(activityRepo.queryKey.get, activityRepo.get, {
    staleTime: CONSTANT_STALE_TIME,
  });

  return {
    regionList,
    placeTypeList,
    unitList,
    activityList,
  };
}
