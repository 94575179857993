import { Theme } from '@emotion/react';

export const FontStyle = {
  h1: `
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
`,

  h2: `
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`,

  text_xxl: `
  font-weight: 700;
  font-size: 16px;
`,

  text_xl: `
  font-weight: 400;
  font-size: 16px;
`,

  text_l: `
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`,

  text_m: `
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`,

  text_s: `
  font-weight: 700;
  font-size: 12px;
`,

  text_xs: `
  font-weight: 400;
  font-size: 12px;
`,

  text_button: `
  font-weight: 700;
  font-size: 15px;
`,

  text_input: `
  font-weight: 400;
  font-size: 15px;
`,
};

export const ColorStyle = {
  primary_lighten: '#f9fbf2',
  primary: '#bbe309',
  primary_dark: '#94b20a',
  primary_bg: `linear-gradient(
  180.54deg,
  rgba(187, 227, 9, 0.24) 0.71%,
  rgba(187, 227, 9, 0) 99.79%
)`,

  // secondary
  secondary_lighten: '#f1e1f4',
  secondary: '#b867c6',
  secondary_dark: '#863895',

  // grey scale
  white: '#ffffff',
  grey1: '#fafafa',
  grey2: '#eeeeee',
  grey3: '#d9d9d9',
  grey4: '#999999',
  grey5: '#767676',
  black: '#111111',

  // system
  error: '#dc0000',
  success: '#42c803',
};

export const theme = {
  font: FontStyle,
  color: ColorStyle,
};
