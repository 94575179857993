import { AuthItemTypes } from '@libs/storage';
import { atom } from 'recoil';

export type authStateType = {
  isLoggedIn: boolean;
  token: AuthItemTypes;
};

export const authState = atom<authStateType>({
  key: 'authState',
  default: {
    isLoggedIn: false,
    token: { hc_eat: '', hc_ert: '' },
  },
});
