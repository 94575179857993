import React, { useCallback, useState } from 'react';
import Chips from '../components/Chips';
/**
 * 사용법 예시
 *
 * //클릭여부확인하여 색상 바꿔줌 (버튼의 텍스트가 바뀌면 isClick를 true로 활성화?)
 * const [isClick, setIsClick] = useState(false);
 *
 * const [btnText, setBtnText] = useState('+지역'); //버튼 텍스트
 *
 * return (
 *  <div>
 *    <ChipsContainer isClick={isClick}
 *                    isMedium={true} // 중간 사이즈할지 말지
 *                    onClickHandler = {()=>{}}
 *                    btntext={btnText}
 *    />
 *  </div>
 * );
 *
 */
type Props = {
  isClick: boolean;
  btntext: string;
  isMedium?: boolean;
  onClickHandler: () => void;
};
const ChipsContainer = (props: Props) => {
  return <Chips {...props} />;
};

export default ChipsContainer;

/**
 *
 * isClick보다 isActive가 알기 쉬울 것 같아요
 *
 *
 */
