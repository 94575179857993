export const images = {
  header: {
    backBlack: '/assets/images/header/ic-back-black.png',
    backWhtie: '/assets/images/header/ic-back-white.png',
    close: '/assets/images/header/ic-close.png',
  },
  navigation: {
    home: '/assets/images/navigation/ic-home.png',
    homeAtv: '/assets/images/navigation/ic-home-atv.png',
    nearby: '/assets/images/navigation/ic-nearby.png',
    nearbyAtv: '/assets/images/navigation/ic-nearby-atv.png',
    mypage: '/assets/images/navigation/ic-mypage.png',
    mypageAtv: '/assets/images/navigation/ic-mypage-atv.png',
  },
  region: {
    busan: '/assets/images/region/img_busan.svg',
    seoul: '/assets/images/region/img_seoul.svg',
    gyeonggi: '/assets/images/region/img_gyeonggu.svg',
    gangwon: '/assets/images/region/img_gangwon.svg',
    jeju: '/assets/images/region/img_jeju.svg',
    incheon: '/assets/images/region/img_incheon.svg',
    etc: '/assets/images/region/img_etc.svg',
  } as RegionType,
  tour: '/assets/images/tour/img_tour0',
  dummy: {
    cafe: '/assets/images/dummy/cafe.png',
    tourThumbnail: '/assets/images/dummy/tour-thumbnail.png',
    location1: '/assets/images/dummy/location1.png',
    location2: '/assets/images/dummy/location2.png',
    location3: '/assets/images/dummy/location3.png',
    location4: '/assets/images/dummy/location4.png',
    background: '/assets/images/dummy/background.png',
    locationDetail: '/assets/images/dummy/location-detail.png',
    comment: '/assets/images/dummy/comment.png',
  },
  onBoarding: [
    '/assets/images/onBoarding/step1.webp',
    '/assets/images/onBoarding/step2.webp',
    '/assets/images/onBoarding/step3.webp',
    '/assets/images/onBoarding/step4.webp',
  ],
  star: {
    active: '/assets/images/ic-star-active.png',
    half: '/assets/images/ic-star-active-half.png',
    inactive: '/assets/images/ic-star-inactive.png',
  },
  locationCategory: {
    activity: '/assets/images/locationCategory/activity.svg',
    etc: '/assets/images/locationCategory/etc.png',
    lodging: '/assets/images/locationCategory/lodging.png',
    meal: '/assets/images/locationCategory/meal.png',
    shopping: '/assets/images/locationCategory/shopping.png',
    tour: '/assets/images/locationCategory/tour.png',
    transportation: '/assets/images/locationCategory/transportation.png',
    cafe: '/assets/images/locationCategory/cafe.png',
    park: '/assets/images/locationCategory/park.png',
    culturalFacilities:
      '/assets/images/locationCategory/cultural-facilities.png',
  } as LocationCategoryType,
  sns: {
    twitter: '/assets/images/share/ic-twitter.svg',
    kakao: '/assets/images/share/ic-kakao.svg',
    instagram: '/assets/images/share/ic-instagram.svg',
    facebook: '/assets/images/share/ic-facebook.svg',
  },
  logo: '/assets/images/ic-logo.png',
  marker: '/assets/images/ic-marker.svg',
  myPosition: '/assets/images/locationCategory/ic-my-position.svg',
  arrowRight: '/assets/images/ic-arrow-right.png',
  arrowDown: '/assets/images/ic-arrow-down.png',
  calendar: '/assets/images/ic-calendar.png',
  clock: '/assets/images/ic-clock.png',
  delete: '/assets/images/ic-delete.png',
  delete1: '/assets/images/ic-delete1.png',
  filter: '/assets/images/ic-filter.png',
  filter2: '/assets/images/ic-filter2.png',
  filter3: '/assets/images/ic-filter3.png',
  google: '/assets/images/ic-google.svg',
  kakao: '/assets/images/ic-kakao.svg',
  twitter: '/assets/images/ic-twitter.png',
  naver: '/assets/images/ic-naver.svg',
  radio: '/assets/images/ic-radio-btn.png',
  radioAtv: '/assets/images/ic-radio-btn-atv.png',
  retry: '/assets/images/ic-retry.png',
  retryBlack: '/assets/images/ic-retry-black.png',
  searchBlack: '/assets/images/ic-search-black.png',
  searchWhite: '/assets/images/ic-search-white.png',
  search: '/assets/images/ic-search.png',
  selectbox: '/assets/images/ic-selectbox.png',
  selectboxAtv: '/assets/images/ic-selectbox-atv.png',
  likeAtv: '/assets/images/ic-like-atv.png',
  like: '/assets/images/ic-like.png',
  share: '/assets/images/ic-share.png',
  arrowBack: '/assets/images/ic-arrow-back.svg',
  camera: '/assets/images/ic-camera.png',
  cameraGrey3: '/assets/images/ic-camera-grey3.png',
  camera2: '/assets/images/ic-camera-grey.png',
  tourComplete: '/assets/images/ic-tour-complete.png',
  user: '/assets/images/ic-user.png',
  dots: '/assets/images/ic-dots.png',
  placeholder: '/assets/images/placeholder-img.svg',
  profile: '/assets/images/ic-profile.png',
};

export type RegionKeyType =
  | 'seoul'
  | 'busan'
  | 'gangwon'
  | 'jeju'
  | 'gyeonggi'
  | 'incheon'
  | 'etc';

type RegionType = {
  [key in RegionKeyType]: string;
};

export type LocationCategoryKeyType =
  | 'activity'
  | 'etc'
  | 'lodging'
  | 'meal'
  | 'shopping'
  | 'tour'
  | 'transportation'
  | 'cafe'
  | 'park'
  | 'culturalFacilities';
type LocationCategoryType = {
  [key in LocationCategoryKeyType]: string;
};
