// primary
export const PRIMARY_LIGHTEN = '#f9fbf2';
export const PRIMARY = '#bbe309';
export const PRIMARY_DARK = '#94b20a';
export const PRIMARY_BG = `linear-gradient(
  180.54deg,
  rgba(187, 227, 9, 0.24) 0.71%,
  rgba(187, 227, 9, 0) 99.79%
)`;

// secondary
export const SECONDARY_LIGHTEN = '#f1e1f4';
export const SECONDARY = '#b867c6';
export const SECONDARY_DARK = '#863895';

// grey scale
export const WHITE = '#ffffff';
export const GREY1 = '#fafafa';
export const GREY2 = '#eeeeee';
export const GREY3 = '#d9d9d9';
export const GREY4 = '#999999';
export const GREY5 = '#767676';
export const BLACK = '#111111';

// system
export const ERROR = '#dc0000';
export const SUCCESS = '#42c803';
