import React from 'react';
import FloatingBottomButton from '../FloatingBottomButton';

type Props = {
  children: JSX.Element;
};
const FloatingBottomButtonContainer = ({ children }: Props) => {
  return <FloatingBottomButton>{children}</FloatingBottomButton>;
};

export default FloatingBottomButtonContainer;
