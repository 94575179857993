import { colors } from '@components/assets/colors';
import styled from '@emotion/styled';

export const HeaderDiv = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  padding: 17px 0;
  margin-bottom: 41px;

  & > h1 {
    flex: 1;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.02em;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ProfileCard = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  .info-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: auto 0;
    gap: 6px;

    .nickname {
      font-weight: 700;
      font-size: 16px;
    }

    .email {
      font-weight: 400;
      font-size: 14px;
      color: ${colors.greyScale.grey5};
    }
  }
`;

export const Line = styled.div`
  height: 8px;
  background-color: ${colors.greyScale.grey1};
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.greyScale.grey2};
  height: 60px;
  align-items: center;
  margin: 0 20px;
`;

export const ArrowRight = styled.img`
  width: 20px;
  height: 20px;
`;

export const TourCard = styled.div`
  display: flex;
  background-color: ${colors.greyScale.grey1};
  border: 1px solid ${colors.greyScale.grey2};
  border-radius: 6px;
  height: 92px;
  min-width: 321px;
  padding: 16px;
  align-items: center;
  margin-right: 10px;
`;

export const Empty = styled.div`
  height: calc(100vh - 315px);
  background-color: ${colors.greyScale.grey1};
`;

export const MyPageFooterDiv = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;
