import React, { useCallback, useEffect, useState } from 'react';
import HomePage from '../Home';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { SelfTourDetailState } from '@store/atom/SelfTourAtom';
import { buildQueryString } from '@libs/api';
import { authState } from '@store/atom/AuthAtom';
import { useQuery } from '@tanstack/react-query';
import { PlaceRepository } from 'repositories/placeRepository';
import { useCommonQuery } from '@hooks/api/useCommonQuery';
import { TourRepository } from 'repositories/tourRepository';
import { useLoginRedirect } from '@hooks/useGotoLogin';

const HomeContainer = () => {
  const navigate = useNavigate();
  const { loginRedirect } = useLoginRedirect();
  const tourRepo = new TourRepository();
  const placeRepo = new PlaceRepository();
  const { regionList } = useCommonQuery();
  const resetSelfTourDetail = useResetRecoilState(SelfTourDetailState);
  const [auth] = useRecoilState(authState);
  const [selectedRegion, setSelectedRegion] = useState<number | null>(null);

  const handleRegionClick = useCallback((id: number | null) => {
    setSelectedRegion(id);
  }, []);

  const handleSelfTourClick = useCallback(() => {
    if (!auth.isLoggedIn) {
      loginRedirect();
      return;
    }

    resetSelfTourDetail();
    navigate('/selftour');
  }, [resetSelfTourDetail, navigate]);

  const handleRecommendPlaceMore = useCallback(() => {
    navigate('/place/list');
  }, [navigate]);

  const handleTourMore = useCallback(() => {
    navigate('/tour/list');
  }, [navigate]);

  const { data: tourList, isLoading: isTourListLoading } = useQuery(
    tourRepo.queryKey.getList,
    () => tourRepo.getList(),
  );

  const { data: placeList, isLoading: isPlaceListLoading } = useQuery(
    [...placeRepo.queryKey.getRecommends, selectedRegion],
    () =>
      placeRepo.getRecommends(
        buildQueryString({
          page: 0,
          size: 10,
          ...(selectedRegion && { regionIds: selectedRegion }),
        }),
      ),
  );

  useEffect(() => {
    if (
      window.location.origin === 'https://nctzentour.com' ||
      window.location.origin === 'https://www.nctzentour.com'
    )
      window.location.replace(`${window.location.origin}/onboarding`);
  }, []);

  return (
    <HomePage
      isTourListLoading={isTourListLoading}
      isPlaceListLoading={isPlaceListLoading}
      selectedRegion={selectedRegion}
      regionList={regionList.data}
      placeList={placeList}
      tourList={tourList}
      handleSelfTourClick={handleSelfTourClick}
      handleRegionClick={handleRegionClick}
      handleRecommendPlaceMore={handleRecommendPlaceMore}
      handleTourMore={handleTourMore}
    />
  );
};

export default HomeContainer;
