/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { css, jsx } from '@emotion/react';
import { images } from '../../../assets/images';
import { SECONDARY } from '../constants/color';
import { CalendarContext } from '@components/common/Calendar/context/CalendarContext';

type Props = {
  btnName: string;
  onClick: () => void;
};
const TextButton = ({ btnName, onClick }: Props) => {
  return (
    <div>
      <button
        onClick={onClick}
        css={css`
          display: flex;
          text-align: center;
          align-items: center;
          width: 60px;
          height: 30px;
        `}>
        <p
          css={css`
            flex: 1;
            font-size: 15px;
            font-weight: 700;
            text-align: center;
            //margin-right: 7px;
            color: ${SECONDARY};
          `}>
          {btnName}
        </p>
        <img
          css={css`
            margin-bottom: 5px;
          `}
          src={images.retry}
          alt=''
        />
      </button>
    </div>
  );
};

export default TextButton;
