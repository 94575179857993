import React, { createContext, useCallback, useMemo, useState } from 'react';

import { PlaceDetailType } from '@typedef/components/Location/location.types';
import {
  ActivityType,
  PlaceClassficationType,
  RegionType,
  UnitType,
} from '@typedef/api/common.types';

type PlaceListContextValueType = {
  isSubmitted: boolean;
  page: number;
  placeTypeId: number;
  activityIds: Set<number>;
  unitIds: Set<number>;
  regionId: number;
  name: string;
  x: number;
  y: number;
  isSortedByRate: boolean;
  placeTypes: PlaceClassficationType[];
  activities: ActivityType[];
  units: UnitType[];
  placeList: PlaceDetailType[];
  regionList: RegionType[];
};

type PlaceListContextType = {
  value: PlaceListContextValueType;
  actions: {
    updateContext: (payload: Partial<PlaceListContextValueType>) => void;
  };
};

export const PlaceListContext = createContext<PlaceListContextType>({
  value: {
    isSubmitted: false,
    page: 0,
    placeTypeId: 0,
    activityIds: new Set<number>(),
    unitIds: new Set<number>(),
    name: '',
    x: 0,
    y: 0,
    isSortedByRate: false,
    regionId: -1,
    placeTypes: [],
    activities: [],
    units: [],
    placeList: [],
    regionList: [],
  },
  actions: {
    updateContext: () => {},
  },
});

type Props = {
  children: JSX.Element;
};

const PlaceListProvider = ({ children }: Props) => {
  const [value, setValue] = useState<PlaceListContextValueType>({
    isSubmitted: false,
    page: 0,
    placeTypeId: 0,
    activityIds: new Set<number>(),
    unitIds: new Set<number>(),
    regionId: -1,
    name: '',
    x: 0,
    y: 0,
    isSortedByRate: false,
    activities: [],
    units: [],
    placeTypes: [],
    placeList: [],
    regionList: [],
  });

  const updateContext = useCallback(
    (payload: Partial<PlaceListContextValueType>) => {
      setValue((prev) => {
        return { ...prev, ...payload };
      });
    },
    [],
  );

  const store = useMemo(() => {
    return {
      value,
      actions: { updateContext },
    };
  }, [value, updateContext]);

  return (
    <PlaceListContext.Provider value={store}>
      {children}
    </PlaceListContext.Provider>
  );
};

export default PlaceListProvider;
