import React, { ReactElement, useEffect, useState } from 'react';
import { useBottomSheet } from '../hooks/useBottomSheet';
import * as S from '../styles/BottomSheetStyles';

type Props = {
  children: ReactElement;
};

const BottomSheet = ({ children }: Props) => {
  const { handleBarRef, sheetRef } = useBottomSheet();

  return (
    <S.BottomSheetDiv ref={sheetRef}>
      <div className='handle-bar' ref={handleBarRef}>
        <div className='bar' />
      </div>
      <S.ContentDiv>{children}</S.ContentDiv>
    </S.BottomSheetDiv>
  );
};

export default BottomSheet;
