import { colors } from '@components/assets/colors';
import styled from '@emotion/styled';

export const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 20px;
`;

export const ListContainerUl = styled.ul`
  display: flex;
  justify-content: center;
  gap: 20px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    img {
      width: 48px;
      height: 48px;
    }
    span {
      color: ${colors.greyScale.grey5};
    }
  }
`;

export const LinkContainerDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 9px 16px;
  background-color: ${colors.greyScale.grey1};
  border: 1px solid ${colors.greyScale.grey2};
  border-radius: 6px;

  span {
    flex: 1;
    color: ${colors.primary.primaryDark};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  button {
    color: ${colors.primary.primaryDark};
    background-color: ${colors.greyScale.white};
    border: 1px solid ${colors.greyScale.grey2};
    padding: 12px 14px;
    border-radius: 6px;
  }
`;

export const DescriptionContainerP = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.greyScale.grey5};
  white-space: pre-wrap;
  text-align: center;
  padding: 24px 0;
  background-color: ${colors.greyScale.grey1};
`;
