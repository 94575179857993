/** @jsxImportSource @emotion/react */
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { BLACK, GREY2, WHITE } from '@constants/colors';
import { css } from '@emotion/react';
import React, { useContext, useEffect, useRef } from 'react';
import Loading from '@components/common/Loading/Loading';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import TourCardListItemContainer from '@components/common/TourCardListItem/containers/TourCardListItemContainer';
import { TourListContext } from './context/TourListProvider';
import { TourType } from '@typedef/components/home/home.types';
import SearchSectionContainer from './containers/SearchSectionContainer';
import { FetchNextPageOptions } from '@tanstack/react-query';
import _ from 'lodash';

type Props = {
  isLoading: boolean;
  tourList?: TourType[];
  total?: number;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => void;
};

const TourList = ({ isLoading, tourList, total, fetchNextPage }: Props) => {
  const {
    value: { name },
  } = useContext(TourListContext);

  const { setRoot, setTarget } = useInfiniteScroll(() => {
    fetchNextPage();
  });

  return (
    <div
      ref={setRoot}
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
      `}>
      <PageHeaderContainer title='시즈니 투어 목록' />
      <div
        css={css`
          flex: 1;
          overflow-y: auto;
          border-top: 1px solid ${GREY2};
        `}>
        <SearchSectionContainer />
        {name.length > 0 && (
          <div
            css={css`
              padding: 0 20px 20px;
            `}>{`'${name}' (으)로 검색된 결과 ${total}건`}</div>
        )}
        <div
          css={css`
            padding: 0 20px 20px;
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}>
          {tourList?.map((item, idx) => (
            <div ref={idx === tourList.length - 1 ? setTarget : undefined}>
              <TourCardListItemContainer key={item.id} tour={item} />
            </div>
          ))}
          {isLoading && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default TourList;
