import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import { CBottomSheetType } from '@typedef/components/common/cbottom.sheet.types';
import React from 'react';

type Props = {
  type?: CBottomSheetType;
  text?: string;
  onCancelBtnClick: () => void;
  onApplyBtnClick: () => void;
};

const CBottomSheetFooter = ({
  type = 'two-button',
  text,
  onCancelBtnClick,
  onApplyBtnClick,
}: Props) => {
  return (
    <div className='footer-container'>
      {type === 'two-button' ? (
        <>
          <ButtonContainer
            isFilled={false}
            btnText={'취소'}
            backgroundColor={'primary-lighten'}
            onClick={onCancelBtnClick}
          />
          <ButtonContainer
            isFilled={true}
            btnText={text ?? '적용'}
            backgroundColor={'primary'}
            onClick={onApplyBtnClick}
          />
        </>
      ) : (
        <ButtonContainer
          isFilled={true}
          btnText={text ?? '적용'}
          backgroundColor={'primary'}
          onClick={onApplyBtnClick}
        />
      )}
    </div>
  );
};

export default CBottomSheetFooter;
