/** @jsxImportSource @emotion/react */
import ChipsContainer from '@components/common/button/containers/ChipsContainer';
import {
  ISOToScheduleFormat,
  getDateDiff,
  getTourScheduleFormat,
} from '@libs/factory';
import * as S from './styles/ScheduleSectionStyles';
import LocationItem from '@components/SelfTour/components/LocationItem';
import { SelfTourPlaceType } from '@typedef/components/SelfTour/selftour.types';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { css } from '@emotion/react';
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import { TourDetailResType } from '@typedef/api/tour.types';

type Props = {
  tourDetail: TourDetailResType;
  placeList?: SelfTourPlaceType[];
  activeDay: number;
  handleDateClick: (idx: number) => void;
  handleMapClick: (lat: number, lng: number) => void;
};

const TourDetail = ({
  tourDetail,
  placeList,
  activeDay,
  handleDateClick,
  handleMapClick,
}: Props) => {
  return (
    <div>
      <PageHeaderContainer />
      <section>
        <div
          css={css`
            position: relative;
          `}>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100vw;
              max-width: 500px;
              max-height: 200px;
              background-color: ${colors.greyScale.grey2};
            `}
            className='img-wrapper'>
            <img
              css={css`
                height: 100%;
                width: 100%;
                object-fit: cover;

                &.error {
                  width: 40px;
                  height: 40px;
                }
              `}
              src={`${images.tour}1.svg`}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = images.placeholder;
                target.classList.add('error');
              }}
            />
          </div>
          <S.ButtonListDiv>
            <S.Button onClick={() => {}}>
              <img src={images.like} alt='like' />
              {/* {liked ? (
                <img src={images.likeAtv} alt='likeAtv' />
              ) : (
                <img src={images.like} alt='like' />
              )} */}
            </S.Button>
            <S.Button onClick={() => {}}>
              <img src={images.share} alt='공유하기' />
            </S.Button>
          </S.ButtonListDiv>
          {/* <ShareModalContainer
            place={place}
            isOpen={isOpen}
            onCloseClick={handleModalClose}
          /> */}
        </div>
      </section>
      <section>
        <div
          css={css`
            padding: 24px 20px;
          `}>
          <p
            css={css`
              margin-bottom: 6px;
            `}
            className='subtitle1'>
            {tourDetail.tourName}
          </p>
          <p className='text-m grey5'>{`${tourDetail.ownerName}의 투어`}</p>
        </div>
      </section>
      <div className='divider' />
      <div>
        <div
          css={css`
            padding: 24px 20px;
            display: flex;
            gap: 8px;
          `}>
          <ChipsContainer
            isClick={true}
            onClickHandler={() => {}}
            btntext={tourDetail.regionName}
          />
          <ChipsContainer
            isClick={true}
            onClickHandler={() => {}}
            btntext={`${ISOToScheduleFormat(
              tourDetail.from,
            )} / ${ISOToScheduleFormat(tourDetail.to)}`}
          />
        </div>
        <div className='divider' />
        <S.ScheduleSectionStyledSection>
          <S.DateSectionDiv>
            {new Array(getDateDiff([tourDetail.from, tourDetail.to]))
              .fill(0)
              .map((_, idx) => {
                return (
                  <div
                    onClick={() => handleDateClick(idx + 1)}
                    className={`date ${
                      idx + 1 === activeDay ? 'selected' : ''
                    }`}>
                    <span className={`main text-xxl `}>{`${idx + 1}일차`}</span>
                    <span className={`sub text-m grey5`}>
                      {getTourScheduleFormat(tourDetail.from, idx)}
                    </span>
                  </div>
                );
              })}
          </S.DateSectionDiv>
          <S.DefaultModePageDiv>
            <S.DefaultModeHeaderDiv>
              <ChipsContainer
                isClick={false}
                btntext={'기상청'}
                onClickHandler={() => {}}
              />
            </S.DefaultModeHeaderDiv>
            <div style={{ padding: '12px 20px' }}>
              {/* 시간순으로 정렬 */}
              {placeList
                ?.sort((a, b) => (a.from < b.from ? -1 : 1))
                .map((item, idx) => (
                  <S.DefaultLocationItemDiv>
                    <LocationItem
                      tourPlaceInfo={item}
                      isLineVisible={placeList.length - 1 !== idx}
                    />
                    <div className='button-container'>
                      <ButtonContainer
                        isFilled={true}
                        btnText={'길찾기'}
                        isMedium={true}
                        backgroundColor={'grey1'}
                        onClick={() => {}}
                      />
                      <ButtonContainer
                        isFilled={true}
                        btnText={'지도보기'}
                        isMedium={true}
                        backgroundColor={'grey1'}
                        onClick={() => {
                          handleMapClick(
                            item.place.point.y,
                            item.place.point.x,
                          );
                        }}
                      />
                    </div>
                  </S.DefaultLocationItemDiv>
                ))}

              {/* <S.AddPlaceByMySelfDiv>
          <div className='text-m grey5'>
            {'잠깐! 나만의 장소를 따로 넣고 싶다면?'}
          </div>
          <ChipsContainer
            isClick={false}
            btntext={'장소 직접 추가'}
            onClickHandler={() => {
              handleAddOtherPlaceClick();
            }}
          />
        </S.AddPlaceByMySelfDiv> */}
            </div>
          </S.DefaultModePageDiv>
        </S.ScheduleSectionStyledSection>
      </div>
    </div>
  );
};

export default TourDetail;
