import { colors } from '@components/assets/colors';
import { WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const BottomSheetDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  top: 400px;
  left: 50%;
  right: 0;
  height: calc(100vh - 20px);
  max-width: 500px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding-bottom: 120px;
  transform: translateX(-50%);

  .handle-bar {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 100px;
      height: 4px;
      background-color: ${colors.greyScale.grey2};
      border-radius: 4px;
    }
  }
`;

export const ContentDiv = styled.div`
  flex: 1;
  height: fit-content;
  overflow-y: scroll;
  background-color: ${WHITE};
`;
