import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  children: React.ReactNode;
};

const ModalPortal = ({ children }: Props) => {
  const el = document.getElementById('modal');
  return ReactDOM.createPortal(children, el!);
};

export default ModalPortal;
