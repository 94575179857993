/** @jsxImportSource @emotion/react */
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import TourCardListItemContainer from '@components/common/TourCardListItem/containers/TourCardListItemContainer';
import { css } from '@emotion/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles/home.styles.css';
import ChipsContainer from '@components/common/button/containers/ChipsContainer';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import Loading from '@components/common/Loading/Loading';
import { TourType } from '@typedef/components/home/home.types';
import { ConstantItemType } from '@typedef/api/common.types';
import * as S from './styles/HomeStyles';
import PlaceGridItemContainer from '@components/common/PlaceGridItem/containers/PlaceGridItemContainer';
import TourSkeleton from '@components/common/Skeleton/TourSkeleton';

type Props = {
  isTourListLoading: boolean;
  isPlaceListLoading: boolean;
  regionList?: ConstantItemType[];
  placeList?: PlaceDetailType[];
  tourList?: TourType[];
  selectedRegion: number | null;
  handleSelfTourClick: () => void;
  handleRecommendPlaceMore: () => void;
  handleRegionClick: (id: number | null) => void;
  handleTourMore: () => void;
};

const HomePage = ({
  isTourListLoading,
  isPlaceListLoading,
  regionList,
  placeList,
  tourList,
  selectedRegion,
  handleSelfTourClick,
  handleRecommendPlaceMore,
  handleRegionClick,
  handleTourMore,
}: Props) => {
  return (
    <S.RootDiv>
      <section
        css={css`
          padding: 0 20px;
          margin-top: 50px;
        `}>
        <h1
          className='h1'
          css={css`
            white-space: pre-wrap;
            margin-bottom: 40px;
          `}>
          {'엔시티를\n따라서\n떠나볼까요?'}
        </h1>
        <ButtonContainer
          isFilled={false}
          btnText={'직접 투어 짜기'}
          backgroundColor={'primary'}
          onClick={handleSelfTourClick}
        />
      </section>
      <S.TourSection>
        <S.SectionTitleDiv>
          <span className='h2'>{'시즈니투어'}</span>
          <span onClick={handleTourMore} className='text-l secondary-dark'>
            {'더보기'}
          </span>
        </S.SectionTitleDiv>
        <ul className='list-container'>
          {isTourListLoading ? (
            <TourSkeleton />
          ) : (
            tourList?.map((item) => (
              <li key={item.id}>
                <TourCardListItemContainer tour={item} key={item.id} />
              </li>
            ))
          )}
        </ul>
      </S.TourSection>
      <section
        css={css`
          margin-top: 40px;
        `}>
        <S.SectionTitleDiv>
          <span className='h2'>{'추천 투어 장소'}</span>
          <span
            onClick={handleRecommendPlaceMore}
            className='text-l secondary-dark'>
            {'더보기'}
          </span>
        </S.SectionTitleDiv>
        <S.RegionListUl>
          <li>
            <ChipsContainer
              isClick={selectedRegion === null}
              btntext={'전체'}
              onClickHandler={() => {
                handleRegionClick(null);
              }}
            />
          </li>
          {regionList?.map((item) => (
            <li key={item.id}>
              <ChipsContainer
                isClick={selectedRegion === item.id}
                btntext={item.name}
                onClickHandler={() => {
                  handleRegionClick(item.id);
                }}
              />
            </li>
          ))}
        </S.RegionListUl>
        <div>
          {isPlaceListLoading ? (
            <Loading />
          ) : (
            <S.PlaceListUl>
              {placeList?.map((item) => (
                <PlaceGridItemContainer place={item} />
              ))}
            </S.PlaceListUl>
          )}
        </div>
      </section>
    </S.RootDiv>
  );
};

export default HomePage;
