import {
  API_ROUTE,
  BasicResponseType,
  ListResponseType,
  request,
} from '@libs/api';
import { TourType } from '@typedef/components/home/home.types';

type PostResType = {
  liked: boolean;
};

export class TourLikeRepository {
  async get(queryString = '') {
    return request<ListResponseType<TourType>>(
      API_ROUTE.tourLike + 'likes/me' + queryString,
      'get',
    ).then((data) => data.data.content);
  }

  async post(id: number, like: boolean) {
    return request<BasicResponseType<PostResType>>(
      API_ROUTE.tourLike + `${id}/${like ? 'like' : 'dislike'}`,
      'post',
    ).then((data) => data.data);
  }
}
