import { useEffect, useRef } from 'react';

interface BottomSheetMetrics {
  startSheetY: number;
  nextSheetY: number;
  touchStartY: number;
}

export function useBottomSheet() {
  const handleBarRef = useRef<HTMLDivElement>(null);
  const sheetRef = useRef<HTMLDivElement>(null);

  const metrics: BottomSheetMetrics = {
    startSheetY: 0,
    nextSheetY: 0,
    touchStartY: 0,
  };

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      metrics.touchStartY = e.touches[0].clientY;
      metrics.startSheetY = metrics.nextSheetY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!sheetRef.current) return;

      const { startSheetY, touchStartY } = metrics;
      const currentTouchY = e.touches[0].clientY;

      const touchOffsetY = currentTouchY - touchStartY;

      const nextSheetY = Math.max(
        -300,
        Math.min(window.innerHeight - 500, startSheetY + touchOffsetY),
      );

      metrics.nextSheetY = nextSheetY;

      sheetRef.current.style.transform = `translateX(-50%) translateY(${nextSheetY}px)`;
    };

    handleBarRef.current?.addEventListener('touchstart', handleTouchStart);
    handleBarRef.current?.addEventListener('touchmove', handleTouchMove);

    return () => {
      handleBarRef.current?.removeEventListener('touchstart', handleTouchStart);
      handleBarRef.current?.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return { handleBarRef, sheetRef };
}
