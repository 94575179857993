/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@components/assets/colors';

const ModalExitTour = () => {
  return (
    <S.ModalRootDiv>
      <p
        className='text-xxl'
        css={css`
          margin-bottom: 8px;
        `}>
        {`투어를 나중에 완성하시겠어요?`}
      </p>
      <S.DescriptionP className='text-m'>
        {`삭제 버튼을 누르면 작성중인 내용이\n저장되지 않고 사라집니다`}
      </S.DescriptionP>
    </S.ModalRootDiv>
  );
};

export default ModalExitTour;

const ModalRootDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const DescriptionP = styled.p`
  color: ${colors.greyScale.grey5};
  white-space: pre-line;
  text-align: center;
`;

const S = { ModalRootDiv, DescriptionP };
