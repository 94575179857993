/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

const StyledModalBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0.5;
  z-index: 1000;
`;

const StyledBottomSheet = styled.div`
  z-index: 1000;
  position: fixed;
  width: min(42rem, 100%);
  height: fit-content;
  max-height: 90%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  background-color: ${colors.greyScale.white};
`;

const StyledBottomSheetHeader = styled.div`
  height: 4.4rem;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid ${colors.greyScale.white};
  position: sticky;
  top: 0;
  background: ${colors.greyScale.white};

  & > img {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: 52px;
    width: 14px;
    height: 14px;
  }

  & > div {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.7rem;
    position: absolute;
    left: 50%;
    top: 48px;
    transform: translateX(-50%);
  }
`;

type Props = {
  title: string;
  closeModal: () => void;
  children: ReactNode;
};

const BottomSheet = ({ title, closeModal, children }: Props) => {
  return (
    <>
      <StyledModalBackground />
      <StyledBottomSheet>
        <StyledBottomSheetHeader>
          <div>{title}</div>
          <img src={images.header.close} alt="x" onClick={closeModal} />
        </StyledBottomSheetHeader>
        <div>{children}</div>
      </StyledBottomSheet>
    </>
  );
};

export default BottomSheet;
