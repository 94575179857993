import React from 'react';
import MyPageCheckBox from '../MyPageCheckBox';

type Props = {
  text: string;
};

const MyPageCheckBoxContainer = ({ text }: Props) => {
  return <MyPageCheckBox text={text} />;
};

export default MyPageCheckBoxContainer;
