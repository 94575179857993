import { API_ROUTE, ListResponseType, request } from '@libs/api';
import {
  PatchTourDateReqType,
  PatchTourPlaceReqType,
  SelfTourReqType,
  TourDetailResType,
  TourReviewResType,
} from '@typedef/api/tour.types';

import { TourType } from '@typedef/components/home/home.types';

export class TourRepository {
  queryKey = {
    getList: ['getList'] as const,
  };

  async getList(queryString = '') {
    return request<ListResponseType<TourType>>(
      API_ROUTE.tour + queryString,
      'get',
    ).then((data) => data.data.content);
  }

  async getMyList(queryString = '') {
    return request<ListResponseType<TourType>>(
      API_ROUTE.tour + '/me' + queryString,
      'get',
    ).then((data) => data.data.content);
  }

  async getReviews(tourId: number) {
    return request<TourReviewResType>(
      API_ROUTE.tour + `/${tourId}/reviews`,
      'get',
    ).then((data) => data.data);
  }

  async getPlan(tourId: number) {
    return request<TourDetailResType>(
      API_ROUTE.tour + `/${tourId}/plan`,
      'get',
    ).then((data) => data.data);
  }

  async postMyTour(body: SelfTourReqType) {
    return request<TourDetailResType>(API_ROUTE.tour, 'post', {}, body).then(
      (data) => data.data,
    );
  }

  async patchDate(tourId: number, body: PatchTourDateReqType) {
    return request<boolean>(
      API_ROUTE.tour + `/${tourId}`,
      'patch',
      {},
      body,
    ).then((data) => data.config.isSuccessful);
  }

  async patchPlace(
    tourId: number,
    placeId: number,
    body: PatchTourPlaceReqType,
  ) {
    return request<boolean>(
      API_ROUTE.tour + `/${tourId}/place/${placeId}`,
      'patch',
      {},
      body,
    ).then((data) => data.config.isSuccessful);
  }
}
