import React from 'react';
import Comment from '../components/Comment';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { LocationReviewType } from '@typedef/components/Location/locationReview.types';

type Props = {
  commentItem: LocationReviewType;
};

const CommentContainer = ({ commentItem }: Props) => {
  return <Comment commentItem={commentItem} />;
};

export default CommentContainer;
