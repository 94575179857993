/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import Button from '@components/common/button/components/Button';
import { css } from '@emotion/react';
import { API_ROUTE, ListResponseType, request } from '@libs/api';
import {
  AUTH_KEY,
  SESSION_STORAGE_TYPE,
  removeItemToStorage,
} from '@libs/storage';
import { authState } from '@store/atom/AuthAtom';
import { ProfileType } from '@typedef/components/MyPage/profile.types';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as S from './styles/MyPageStyles';

import { ISOToScheduleFormat } from '@libs/factory';
import { useQuery } from '@tanstack/react-query';
import { userRepository } from 'repositories/userRepository';
import { TourType } from '@typedef/api/tour.types';

const MyPagePage = () => {
  const navigate = useNavigate();
  const [{ isLoggedIn }, setAuthState] = useRecoilState(authState);
  const userRepo = new userRepository();
  // const [upcomingTours, setUpcomingTours] = useState<UpcomingTourType[]>([]);

  const getProfile = async () => {
    if (!isLoggedIn) new Error('로그인이 필요한 기능입니다');

    const {
      data,
      config: { isSuccessful },
    } = await request<ProfileType>(API_ROUTE.user + '/profile', 'get');

    if (!isSuccessful) {
      setAuthState({
        isLoggedIn: false,
        token: {
          hc_eat: '',
          hc_ert: '',
        },
      });

      throw Error('프로필을 불러오는데에 실패했습니다');
    }
    return data;
  };

  const loadUpcomingTourList = async () => {
    if (!isLoggedIn) {
      throw Error('로그인이 필요한 기능입니다');
    }

    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<TourType>>(
      API_ROUTE.tour + '/me?isPast=false',
      'get',
    );

    if (!isSuccessful) {
      throw Error('다가오는 투어 리스트를 불러오는데에 실패했습니다');
    }

    return data.content;
  };

  const handleEditClick = () => {
    navigate('edit');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleWishListClick = () => {
    navigate('wishList');
  };

  const handleTourClick = () => {
    navigate('tour');
  };

  const handleMyReviewClick = () => {
    navigate('myReview');
  };

  const handleLogoutClick = () => {
    const isLoggedOut = window.confirm('로그아웃 하시겠습니까?');

    if (isLoggedOut) {
      removeItemToStorage(SESSION_STORAGE_TYPE, AUTH_KEY);
      window.location.reload();
      return;
    }
  };

  const { data: profileData, isSuccess } = useQuery(
    userRepo.queryKey.get,
    userRepo.get,
  );
  const { data: upcomingTours, isSuccess: isUpcomingSuccess } = useQuery(
    ['upcomingTours'],
    loadUpcomingTourList,
  );

  // useEffect(() => {
  //   loadUpcomingTourList();
  // }, []);

  if (isLoggedIn)
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}>
        <S.Container>
          <S.HeaderDiv>
            <h1>마이페이지</h1>
          </S.HeaderDiv>
          <S.ProfileCard>
            <img
              height={64}
              width={64}
              css={css`
                margin: 0 12px 0 21px;
                border-radius: 50%;
              `}
              alt='profile'
              src={
                isLoggedIn && isSuccess ? profileData.thumbnail : images.profile
              }
            />
            <div className='info-container'>
              <div className='nickname'>{profileData?.displayName}</div>
              <div className='email'>{profileData?.email}</div>
            </div>
          </S.ProfileCard>
          <div
            css={css`
              display: flex;
              margin: 24px 20px 34px 20px;
              gap: 8px;
            `}>
            <Button
              isFilled={true}
              btnText={'프로필 수정'}
              isMedium={true}
              backgroundColor={'primary'}
              onClick={handleEditClick}
            />
          </div>
        </S.Container>
        <>
          <S.Line />
          {isUpcomingSuccess && upcomingTours.length > 0 ? (
            <>
              <S.Container>
                <div
                  css={css`
                    padding: 0 0 36px 20px;
                  `}>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      height: 60px;
                    `}>
                    다가오는 투어&nbsp;
                    <span
                      css={css`
                        color: ${colors.primary.primaryDark};
                      `}>
                      {upcomingTours.length}
                    </span>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      overflow-x: scroll;
                    `}>
                    {upcomingTours.map((item) => {
                      return (
                        <S.TourCard key={item.id}>
                          <img
                            height={60}
                            width={60}
                            css={css`
                              border-radius: 6px;
                              margin-right: 10px;
                              object-fit: cover;
                            `}
                            src={`${images.tour}${Math.floor(
                              Math.random() * 5 + 1,
                            )}.svg`}
                            alt='투어 썸네일'
                          />
                          <div>
                            <div className='text-m'>{item.name}</div>
                            <div
                              className='text-xs'
                              css={css`
                                color: ${colors.greyScale.grey5};
                              `}>
                              {`${ISOToScheduleFormat(
                                item.from,
                              )} ~ ${ISOToScheduleFormat(item.to)}`}
                            </div>
                          </div>
                          <div
                            className='text-xl'
                            css={css`
                              margin-left: auto;
                            `}>
                            D-{1}
                          </div>
                        </S.TourCard>
                      );
                    })}
                  </div>
                </div>
              </S.Container>
              <S.Line />
            </>
          ) : (
            ''
          )}
          <S.Container>
            <S.Menu onClick={handleWishListClick}>
              <div className='text-xl'>나만의 찜 리스트</div>
              <S.ArrowRight src={`${images.arrowRight}`}></S.ArrowRight>
            </S.Menu>
            <S.Menu onClick={handleTourClick}>
              <div className='text-xl'>나의 투어 보기</div>
              <S.ArrowRight src={images.arrowRight}></S.ArrowRight>
            </S.Menu>
            <S.Menu onClick={handleMyReviewClick}>
              <div className='text-xl'>나의 후기</div>
              <S.ArrowRight src={`${images.arrowRight}`}></S.ArrowRight>
            </S.Menu>
          </S.Container>
          <S.Container
            css={css`
              margin-bottom: 56px;
            `}>
            <S.MyPageFooterDiv>
              <div className='text-m'>
                <div onClick={handleLogoutClick}>로그아웃</div>
              </div>
              <div
                css={css`
                  height: 10px;
                  width: 1px;
                  background-color: ${colors.greyScale.grey3};
                `}
              />
              <div className='text-m'>
                <Link to={'withdrawal'}>탈퇴하기</Link>
              </div>
              <div
                css={css`
                  height: 10px;
                  width: 1px;
                  background-color: ${colors.greyScale.grey3};
                `}
              />
              <div className='text-m'>이용가이드</div>
            </S.MyPageFooterDiv>
          </S.Container>
        </>
      </div>
    );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}>
      <S.Container>
        <S.HeaderDiv>
          <h1>마이페이지</h1>
        </S.HeaderDiv>
        <S.ProfileCard>
          <img
            height={64}
            width={64}
            css={css`
              margin: 0 12px 0 21px;
              border-radius: 50%;
            `}
            alt='profile'
            src={images.profile}
          />
          <div
            className='text-m'
            css={css`
              margin-right: 20px;
              white-space: pre;
            `}>
            {`로그인 하셔서 시즈니를\n위한 더 많은 기능을 체험해보세요!`}
          </div>
        </S.ProfileCard>
        <div
          css={css`
            display: flex;
            margin: 24px 20px 34px 20px;
            gap: 8px;
          `}>
          <>
            <Button
              isFilled={true}
              btnText={'로그인'}
              isMedium={true}
              backgroundColor={'primary'}
              onClick={handleLoginClick}
            />
          </>
        </div>
      </S.Container>
      <S.Empty />
    </div>
  );
};

export default MyPagePage;
