import React, { useCallback, useState } from 'react';
import TourCardListItem from '../TourCardListItem';
import { TourType } from '@typedef/components/home/home.types';
import { API_ROUTE, buildQueryString, request } from '@libs/api';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authState } from '@store/atom/AuthAtom';
import { useLoginRedirect } from '@hooks/useGotoLogin';
import { TourLikeRepository } from 'repositories/tourLikeRepository';

type Props = {
  tour: TourType;
};

const TourCardListItemContainer = ({ tour }: Props) => {
  const navigate = useNavigate();
  const [auth] = useRecoilState(authState);
  const { loginRedirect } = useLoginRedirect();
  const tourLikeRepo = new TourLikeRepository();

  const [liked, setLiked] = useState(tour.liked);

  const handleLikeClick = async () => {
    if (!auth.isLoggedIn) {
      loginRedirect();
      return;
    }

    const {
      data,
      config: { isSuccessful },
    } = await tourLikeRepo.post(tour.id, liked);

    if (!isSuccessful) {
      alert('요쳥에 실패했습니다');
      throw Error('투어 좋아요 추가에 실패했습니다');
    }

    setLiked(data.liked);
  };

  const handleTourClick = (tourId: number) => {
    navigate(`/tour/detail${buildQueryString({ tourId })}`);
  };

  return (
    <TourCardListItem
      liked={liked}
      tour={tour}
      handleLikeClick={handleLikeClick}
      handleTourClick={handleTourClick}
    />
  );
};

export default TourCardListItemContainer;
