import { images } from '@components/assets/images';
import React from 'react';
import * as S from './styles/PageHeaderStyles';

type Props = {
  isBackbuttonVisible?: boolean;
  onBackbuttonClick: () => void;
  title?: string;
};

const PageHeader = ({
  isBackbuttonVisible = true,
  onBackbuttonClick,
  title,
}: Props) => {
  return (
    <S.PageHeader>
      <div className='header'>
        {isBackbuttonVisible && (
          <button onClick={onBackbuttonClick}>
            <img src={images.arrowBack} />
          </button>
        )}
        <div className='h2'>{title}</div>
      </div>
    </S.PageHeader>
  );
};

export default PageHeader;
