import React, { useEffect, useRef } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { useQueryClient } from '@tanstack/react-query';

import NearByBottomSheet from '../components/NearByBottomSheet';

type Props = {
  isLoading: boolean;
  placeList?: PlaceDetailType[];
  fetchNextPage: () => void;
};
const NearByBottomSheetContainer = ({
  isLoading,
  placeList,
  fetchNextPage,
}: Props) => {
  const queryClient = useQueryClient();
  const targetElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //전체 화면 스크롤 막기
    //바텀시트를 움직일 때 화면이 같이 스크롤 되는 걸 막기 위함
    disableBodyScroll(targetElementRef.current!, {
      allowTouchMove: (el) => {
        let isTochable = false;
        while (el && el !== document.body) {
          if (el.className === 'body-scroll-lock-ignore') {
            isTochable = true;
            break;
          }

          el = el.parentElement!;
        }
        return isTochable;
      },
    });

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <NearByBottomSheet
      isLoading={isLoading}
      targetElementRef={targetElementRef}
      placeList={placeList}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default NearByBottomSheetContainer;
