import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { atom } from 'recoil';

export const mapState = atom<google.maps.Map | null>({
  key: 'MAP_KEY',
  default: null,
});

export const markerState = atom<PlaceDetailType | null>({
  key: 'MARKER_KEY',
  default: null,
});
