/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import { css } from '@emotion/react';
import { TourType } from '@typedef/components/home/home.types';
import React from 'react';

type Props = {
  tour: TourType;
  liked: boolean;
  handleLikeClick: () => void;
  handleTourClick: (tourId: number) => void;
};

const TourCardListItem = ({
  tour,
  liked,
  handleLikeClick,
  handleTourClick,
}: Props) => {
  return (
    <div
      onClick={(e) => {
        const target = e.target as HTMLElement;
        console.log(target.classList);
        if (target.classList.contains('like-btn')) {
          return;
        }
        handleTourClick(tour.id);
      }}
      css={css`
        position: relative;
        height: 183px;
        min-width: 325px;
        border-radius: 6px;
        overflow: hidden;
        background-color: ${colors.greyScale.grey1};
        background: center / cover no-repeat
          url(${tour.thumbnail || images.dummy.cafe});
      `}>
      <button
        onClick={handleLikeClick}
        className='like-btn'
        css={css`
          position: absolute;
          top: 11px;
          right: 10px;
          width: 24px;
          height: 24px;
          background: center / cover no-repeat
            url(${liked ? images.likeAtv : images.like});
        `}></button>
      <div
        css={css`
          position: absolute;
          bottom: 0;
          height: 83px;
          width: 100%;
          padding: 20px 16px;
          display: flex;
          flex-direction: column;
          gap: 6px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.7) 100%
          );
        `}>
        <span className='text-xxl white'>{tour.name}</span>
        <span className='text-m white'>{tour.regionName}</span>
      </div>
    </div>
  );
};

export default TourCardListItem;
