import React, { useCallback, useEffect, useState } from 'react';
import StarRating from '../StarRating';

type Props = {
  rate: number;
  onHandleRate?: (num: number) => void;
  test?: number;
  size?: number;
};

const StarRatingContainer = ({ rate, onHandleRate, test, size }: Props) => {
  let STAR_IDX_ARR = ['first', 'second', 'third', 'fourth', 'last']; // 다섯개의 별을 따로 컨트롤하기 위해서는 고유 id를 각각 가지고 있어야 합니다. 이 고유 아이디를 쉽게 생성해 주기 위한 리스트 입니다.
  STAR_IDX_ARR = STAR_IDX_ARR.map((item) => item + test);
  // const [rate, setRate] = useState(100);
  const [ratesResArr, setRatesResArr] = useState([0, 0, 0, 0, 0]); // 별점 리스트 상태입니다.

  const calcStarRates = useCallback(() => {
    let tempStarRatesArr = [0, 0, 0, 0, 0]; // 임시 리스트.
    let starVerScore = (rate * 70) / 100; // 별 한 개 당 width가 14이므로 총 70. 100점 만점인 현재와 비율을 맞춰줍니다.
    let idx = 0;

    while (starVerScore > 14) {
      // 14를 starVerScore에서 하나씩 빼가면서 별 하나하나에 채워질 width를 지정해줍니다. 다 채워지지 않을 인덱스의 별은 아래 tempStarRatesArr[idx] = starVerScore; 에서 채워줍니다.
      tempStarRatesArr[idx] = 14;
      idx += 1; // 인덱스 0부터 첫번째 별 입니다.
      starVerScore -= 14;
    }
    tempStarRatesArr[idx] = starVerScore;

    return tempStarRatesArr; // 평균이 80이라면 [14, 14, 14, 14, 0] 이 되겠죠?
  }, [rate]);

  // 마우스 좌표 이벤트 -> 별점을 마우스 이동에 따라 채워지게 하기 위한 함수
  const calculateScore = useCallback(
    (e: React.MouseEvent) => {
      const { width, left } = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - left;
      const scale = width / 5 / 2;
      // const scale = 184 / 5 / 2;
      // const score = Math.floor(x / scale + 1) / 2; //최소 0.5값만큼 점수 계산이 되어야 하기 때문에 계산 된 비율 값에 1을 가산
      const score = Math.ceil(Math.floor(x / scale + 1) / 2); // 1점 단위 계산 시 사용
      // setRate(score * 20);
      if (onHandleRate) {
        onHandleRate(score * 20);
      }
      console.log(`width : ${width} left : ${left}`);

      console.log('x : ' + x + ' scale : ' + scale + ' score : ' + score);

      return score;
    },
    [onHandleRate]
  );

  useEffect(() => {
    setRatesResArr([...calcStarRates()]);
    // calcStarRates(); // 별점 리스트는 첫 렌더링 때 한번만 상태를 설정해줍니다.
  }, [calcStarRates]);

  return (
    <StarRating
      starArray={STAR_IDX_ARR}
      calculateScore={calculateScore}
      ratesResArr={ratesResArr}
      size={size}
    />
  );
};

export default StarRatingContainer;
