import CBottomSheetContainer from '@components/common/CBottomSheet/containers/CBottomSheetContainer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import Loading from '@components/common/Loading/Loading';
import { SelfTourDetailState } from '@store/atom/SelfTourAtom';
import SelectRegionModal from '../components/ModalSelectRegion';
import { useCommonQuery } from '@hooks/api/useCommonQuery';
import { Modal } from '@components/common/Modal/Modal';
import CBottomSheetFooter from '@components/common/CBottomSheet/components/CBottomSheetFooter';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';

type Props = { handleModalOpen: (value: boolean) => void };

const ModalRegionContainer = ({ handleModalOpen }: Props) => {
  const { regionList } = useCommonQuery();
  const [selectRegionIdx, setSelectRegionIdx] = useState(1);

  const [selfTourDetail, setSelftourDetail] =
    useRecoilState(SelfTourDetailState);

  /**
   * 선택된 지역 이름 저장
   */
  const handleRegionBtnClick = useCallback((idx: number) => {
    setSelectRegionIdx(idx);
  }, []);

  /**
   * 바텀시트 관련 메소드
   */
  const handleCloseClick = useCallback(() => {
    handleModalOpen(false);
  }, []);

  const handelApplyClick = useCallback(() => {
    if (!regionList.data) return;

    setSelftourDetail({
      ...selfTourDetail,
      region: regionList.data[selectRegionIdx],
    });

    handleModalOpen(false);
  }, [selfTourDetail, regionList, selectRegionIdx]);

  if (regionList.isLoading) return <Loading />;

  return (
    <Modal onCloseClick={handleCloseClick}>
      <Modal.Header
        hasCloseBtn={true}
        title='지역 선택'
        onCloseClick={handleCloseClick}
      />
      <Modal.Content>
        <SelectRegionModal
          regionList={regionList.data ?? []}
          selectRegionIdx={selectRegionIdx}
          handleRegionBtnClick={handleRegionBtnClick}
        />
      </Modal.Content>
      <Modal.Footer>
        <ButtonContainer
          isFilled={false}
          btnText={'취소'}
          backgroundColor={'primary-lighten'}
          onClick={handleCloseClick}
        />
        <ButtonContainer
          isFilled={true}
          btnText={'적용'}
          backgroundColor={'primary'}
          onClick={handelApplyClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalRegionContainer;
