/** @jsxImportSource @emotion/react */

import { RefObject } from 'react';
import BottomSheet from './BottomSheet';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { css } from '@emotion/react';
import Loading from '@components/common/Loading/Loading';
import MapListOptionContainer from '../containers/MapListOptionContainer';
import PlaceListItemContainer from '@components/common/PlaceListItem/containers/PlaceListItemContainer';

type Props = {
  isLoading: boolean;
  targetElementRef: RefObject<HTMLDivElement>;
  placeList?: PlaceDetailType[];
  fetchNextPage: () => void;
};

const NearByBottomSheet = ({
  isLoading,
  targetElementRef,
  placeList,
  fetchNextPage,
}: Props) => {
  const { setTarget } = useInfiniteScroll(() => {
    fetchNextPage();
  });

  return (
    <div
      css={css`
        width: 100%;
        max-width: 500px;
      `}
      ref={targetElementRef}>
      <BottomSheet>
        <div className='body-scroll-lock-ignore'>
          <MapListOptionContainer />
          <ul>
            {placeList?.map((item, idx) => (
              <li key={item.id}>
                <PlaceListItemContainer place={item} />
              </li>
            ))}
            <li ref={setTarget} className='last' />
          </ul>
          {isLoading && <Loading />}
        </div>
      </BottomSheet>
    </div>
  );
};

export default NearByBottomSheet;
