import React from 'react';
import styles from '../styles/istouropen.module.css';
import { ScopeType } from '@typedef/components/SelfTour/selftour.types';

type Props = {
  isOpen: { label: string; value: ScopeType }[];
  selectedIdx: number;
  handleBtnClick: (idx: number) => void;
};
const ModalTourScope = ({ isOpen, selectedIdx, handleBtnClick }: Props) => {
  return (
    <div className={styles['is-tour-open-root']}>
      <section className={styles['open-section']}>
        {isOpen.map((item, idx) => (
          <label className={styles['open-list']} htmlFor={`tour-open${idx}`}>
            <p
              className={`text-m ${styles['text']} ${
                idx === selectedIdx ? styles['check'] : ''
              }`}>
              {item.label}
            </p>
            <input
              checked={idx === selectedIdx}
              type='radio'
              id={`tour-open${idx}`}
              onChange={() => handleBtnClick(idx)}
              hidden></input>
            <div
              className={`${styles['public-btn']} ${
                idx === selectedIdx ? styles['check'] : ''
              }`}>
              <div
                className={`${styles['inner-btn']} ${
                  idx === selectedIdx ? styles['check'] : ''
                }`}
              />
            </div>
          </label>
        ))}
      </section>
    </div>
  );
};

export default ModalTourScope;
