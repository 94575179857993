import { BLACK, GREY1, GREY2 } from '@constants/colors';
import styled from '@emotion/styled';

export const RelatedLocationSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 39px;
  border-bottom: 8px solid ${GREY1};

  .title {
    color: ${BLACK};
    margin: 24px 20px 12px 20px;
  }

  .location-container {
    display: flex;
    gap: 10px;
    padding: 0 20px;
    overflow-x: auto;
  }
`;
