import React from 'react';
import MyPageBottomSheetCheckBox from '../components/MyPageBottomSheetCheckBox';

type Props = {
  text: string;
};

const MyPageBottomSheetCheckBoxContainer = ({ text }: Props) => {
  return <MyPageBottomSheetCheckBox text={text} />;
};

export default MyPageBottomSheetCheckBoxContainer;
