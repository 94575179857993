import Map from '@components/NearBy/components/Map';
import MapContainer from '@components/NearBy/containers/MapContainer';
import { images } from '@components/assets/images';
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const PlaceMap = () => {
  const [searchParams] = useSearchParams();
  const lat = searchParams.get('lat');
  const lng = searchParams.get('lng');

  const [map, setMap] = useState<any>();

  const loadMap = useCallback(() => {
    if (!lat || !lng) return;

    let map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: { lat: Number(lat), lng: Number(lng) },
        zoom: 16,
        disableDefaultUI: true,
      },
    );

    setMap(map);

    new google.maps.Marker({
      position: { lat: Number(lat), lng: Number(lng) },
      map: map,
      icon: images.marker,
    });
  }, [lat, lng]);

  useEffect(() => {
    loadMap();
  }, []);

  return (
    <div>
      <PageHeaderContainer title='지도 보기' isBackbuttonVisible={true} />
      <Map />
    </div>
  );
};

export default PlaceMap;
