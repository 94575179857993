import React, { useCallback, useMemo, useState } from 'react';
import CalendarDate from './CalendarDate';
import * as S from '../styles/calendarStyles';

type Props = {
  year: number;
  month: number;
  today?: number;
};

const Month = ({ year, month, today }: Props) => {
  const offset = new Date(year, month, 1).getDay();
  const lastDate = new Date(year, month + 1, 0).getDate();

  return (
    <S.MonthContainer>
      <div className={'title text-l'}>{`${year}년 ${month + 1}월`}</div>
      <div className={'week-list-container'}>
        {new Array(6).fill(0).map((_, week) => (
          <div className={'week'}>
            {week === 0
              ? new Array(7)
                  .fill(0)
                  .map((_, idx) => idx + 1 - offset)
                  .map((date, idx) => {
                    if (date > 0)
                      return (
                        <CalendarDate
                          key={`${year}${month}${date}`}
                          year={year}
                          month={month}
                          date={date}
                          isSat={idx === 6}
                          isSun={idx === 0}
                          isToday={today === date}
                        />
                      );
                    else return <div />;
                  })
              : new Array(7)
                  .fill(0)
                  .map((_, idx) => week * 7 + idx + 1 - offset)
                  .map((date, idx) => {
                    if (date <= lastDate)
                      return (
                        <CalendarDate
                          key={`${year}${month}${date}`}
                          year={year}
                          month={month}
                          date={date}
                          isSat={idx === 6}
                          isSun={idx === 0}
                          isToday={today === date}
                        />
                      );
                    else return <div />;
                  })}
          </div>
        ))}
      </div>
    </S.MonthContainer>
  );
};

export default Month;
