import { GNBItemsTypes, GNBTabTypes } from '@typedef/components/gnb.types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GNB from '../GNB';

const GNBContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(
    location.pathname as GNBTabTypes,
  );

  const onTabClicked = useCallback(
    (tab: GNBItemsTypes) => {
      setCurrentTab(tab.path);
      navigate(tab.path);
    },
    [navigate],
  );
  useEffect(() => {
    setCurrentTab(location.pathname as GNBTabTypes);
  }, [location]);
  return <GNB currentTab={currentTab} onTabClicked={onTabClicked} />;
};

export default GNBContainer;
