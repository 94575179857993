import PlaceList from '../PlaceList';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { useNavigate } from 'react-router-dom';
import { PlaceListContext } from '../context/PlaceListProvider';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCommonQuery } from '@hooks/api/useCommonQuery';

const PlaceListContainer = () => {
  const navigate = useNavigate();
  const { regionList } = useCommonQuery();

  const {
    value: {
      page,
      placeTypeId,
      x,
      y,
      name,
      unitIds,
      activityIds,
      isSortedByRate,
      regionId,
    },
  } = useContext(PlaceListContext);
  const [isInit, setIsInit] = useState(true);

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    [
      'infiniteMorePlaceList',
      Array.from(unitIds),
      Array.from(activityIds),
      name,
      regionId,
      placeTypeId,
    ],
    ({ pageParam }) => getPlaceList(pageParam),
  );

  const getPlaceList = async (pageParam = 0) => {
    const activityIdQueryString =
      activityIds.size !== 0
        ? Array.from(activityIds.values())
            .map((id) => `&activityId=${id}`)
            .join('')
        : '';

    const unitIdQueryString =
      unitIds.size !== 0
        ? Array.from(unitIds.values())
            .map((id) => `&unitId=${id}`)
            .join('')
        : '';

    const {
      data: {
        content,
        meta: { page, maxPage, total },
      },
      config: { isSuccessful },
    } = await request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place +
        '/recommends' +
        buildQueryString({
          page: pageParam,
          size: 10,
          x,
          y,
          ...(regionId !== -1 && { regionId }),
          ...(placeTypeId !== 0 && { placeTypeId }),
          ...(name && { name }),
          ...(isSortedByRate && { sort: 'rating' }),
        }) +
        activityIdQueryString +
        unitIdQueryString,

      'get',
    );

    if (!isSuccessful) {
      alert('장소 리스트를 불러오는데에 실패했습니다');
      throw Error('장소 리스트를 불러오는데에 실패했습니다');
    }

    return {
      hasNextPage: page === maxPage ? undefined : page + 1,
      pageParam,
      content,
    };
  };

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
      return;
    }

    navigate(
      `/place/list${buildQueryString({
        ...(regionId && { regionId }),
        placeType: placeTypeId,
        page,
        name,
      })}`,
      { replace: true },
    );
  }, [placeTypeId, regionId, page, name]);

  return (
    <PlaceList
      isLoading={isLoading}
      placeList={data?.pages.map((page) => page.content).flat(1)}
      regionList={regionList.data}
      regionId={regionId}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default PlaceListContainer;
