/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import CInputBoxContainer from '@components/common/CinputBox/containers/CInputBoxContainer';
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import Button from '@components/common/button/components/Button';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { API_ROUTE, request } from '@libs/api';
import { authState } from '@store/atom/AuthAtom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ProfileType } from '@typedef/components/MyPage/profile.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userRepository } from 'repositories/userRepository';

const ProfileEditPage = () => {
  const navigate = useNavigate();
  const userRepo = new userRepository();
  const { mutate } = useMutation(['profile']);
  const [_, setAuthState] = useRecoilState(authState);
  const [name, setName] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const getProfile = async () => {
    const {
      data,
      config: { isSuccessful },
    } = await request<ProfileType>(API_ROUTE.user + '/profile', 'get');

    if (!isSuccessful) {
      setAuthState({
        isLoggedIn: false,
        token: {
          hc_eat: '',
          hc_ert: '',
        },
      });

      throw Error('프로필을 불러오는데에 실패했습니다');
      // return;
    }
    return data;
  };

  const patchProfile = async () => {
    if (name === '') {
      alert('닉네임을 입력해주세요');
      return;
    }

    const {
      config: { isSuccessful },
    } = await request(
      API_ROUTE.user,
      'patch',
      { 'Content-Type': 'multipart/form-data' },
      { displayName: name, ...(file && { thumbnail: file }) },
    );

    if (!isSuccessful) {
      throw Error('프로필 수정에 실패했습니다');
    }

    mutate();
    navigate(-1);
  };

  const onClickComplete = () => {
    patchProfile();
  };

  const { data: profileData, isSuccess } = useQuery(
    userRepo.queryKey.get,
    userRepo.get,
  );

  useEffect(() => {
    if (isSuccess) {
      setName(profileData.displayName);
      setThumbnail(profileData.thumbnail);
    }
  }, [profileData]);

  return (
    <Container>
      <PageHeaderContainer isBackbuttonVisible={true} title='프로필 수정' />
      <ContentSection>
        <ProfileSection>
          <div className='profile-img-container'>
            <img className='profile-img' src={thumbnail} />
            <div className='change-img-container'>
              <label htmlFor='change-img-btn'>
                <img src={images.camera} alt='프로필 이미지 선택 버튼 이미지' />
                <input
                  hidden
                  accept='image/*'
                  type='file'
                  name='change-img-btn'
                  id='change-img-btn'
                  onChange={(e) => {
                    const file = e.target.files?.item(0);
                    if (file) {
                      setThumbnail(URL.createObjectURL(file));
                      setFile(file);
                    }
                  }}
                />
              </label>
            </div>
          </div>
        </ProfileSection>
        <NickNameSection>
          <p
            className='text-l'
            css={css`
              color: ${colors.greyScale.grey5};
            `}>
            닉네임 입력
          </p>
          <CInputBoxContainer
            value={name}
            className={''}
            onChange={({ value }) => setName(value)}
            onReset={() => setName('')}
            variant={'flushed'}
          />
        </NickNameSection>
      </ContentSection>
      <Footer>
        <Line />
        <div
          css={css`
            margin: 0 20px;
          `}>
          <Button
            isFilled={true}
            btnText={'프로필 수정 완료'}
            backgroundColor={'primary'}
            onClick={onClickComplete}
          />
        </div>
      </Footer>
    </Container>
  );
};

export default ProfileEditPage;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileSection = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .profile-img-container {
    position: relative;
  }

  .profile-img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
  }

  .change-img-container {
    position: absolute;
    bottom: 0;
    right: 0;
    label {
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
`;

const NickNameSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 32px 20px 0px 20px;
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0px;
  background-color: ${colors.greyScale.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 7px;
  margin-top: auto;
`;

const Line = styled.div`
  height: 1px;
  margin-bottom: 6px;
  background-color: ${colors.greyScale.grey2};
`;
