import React from 'react';
import CommentListItem from '../CommentListItem';

type Props = {
  test: number;
};

const CommentListItemContainer = ({ test }: Props) => {
  return <CommentListItem test={test} />;
};

export default CommentListItemContainer;
