import { RegionType } from '@typedef/api/common.types';
import { PlaceDetailType } from '../Location/location.types';

export type SelfTourModalKeyType =
  | 'exit'
  | 'scope'
  | 'region'
  | 'calendar'
  | 'time'
  | 'day';

/**직접 투어 페이지 모달 타입*/
export type SelfTourModalType = {
  [k in SelfTourModalKeyType]: boolean;
};

/**투어 예산 타입*/
type TourPlaceBudgetType = {
  type: string;
  amount: number;
  description: string;
};

/**투어 장소 타입*/
export type TourPlaceType = {
  from: string;
  to: string;
  budgets?: TourPlaceBudgetType[];
  description: string;
};

/**직접 투어 만들 때 편집을 위해 사용하는 장소 타입*/
export type SelfTourPlaceType = TourPlaceType & {
  place: PlaceDetailType;
  isSelected: boolean;
};

/**투어 공개 범위 타입*/
export type ScopeType = 'PUBLIC' | 'PRIVATE';

export const ScopeLabel = {
  PUBLIC: '전체공개' as const,
  PRIVATE: '비공개' as const,
};

/**투어 예산 타입 */
export type BudgetType = {
  type: string;
  amount: number;
  description: string;
};

/**직접 투어 만들 때 편집을 위해 사용하는 투어 타입*/
export type SelfTourDetailType = {
  name: string;
  region: RegionType;
  scope: ScopeType | null;
  schedule: Set<string>;
  selectedPlace: PlaceDetailType | null;
  selectedDay: number;
  editableItemIdx: number;
  placeList: Map<number, SelfTourPlaceType[]>;
};
