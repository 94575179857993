/** @jsxImportSource @emotion/react */
import { images } from '@components/assets/images';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  text-align: center;
  padding: 17px 0 17px 0;
  background-color: white;
  z-index: 999;
`;

const BackButtonSection = styled.section`
  position: absolute;
  left: 24px;
`;

type Props = {
  title: string;
  onClickBackBtn: () => void;
};

const MyPageHeader = ({ title, onClickBackBtn }: Props) => {
  return (
    <HeaderStyled>
      <BackButtonSection onClick={onClickBackBtn}>
        <img
          src={images.arrowBack}
          alt=""
          css={css`
            width: 24px;
            height: 24px;
          `}
        />
      </BackButtonSection>
      <h1
        css={css`
          flex: 1;
          font-weight: 700;
          font-size: 18px;
          letter-spacing: -0.02em;
        `}
      >
        {title}
      </h1>
    </HeaderStyled>
  );
};

export default MyPageHeader;
