import React, { useCallback, useContext, useState } from 'react';
import SearchSection from '../components/SearchSection';
import { TourListContext } from '../context/TourListProvider';

const SearchSectionContainer = () => {
  const [searchInput, setSearchInput] = useState('');
  const {
    actions: { updateContext },
  } = useContext(TourListContext);

  const handleChangeInput = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const handleSubmit = useCallback(() => {
    updateContext({ name: searchInput, page: 0 });
  }, [searchInput]);

  return (
    <SearchSection
      searchInput={searchInput}
      handleChangeInput={handleChangeInput}
      handleSubmit={handleSubmit}
    />
  );
};

export default SearchSectionContainer;
