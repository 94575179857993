import { images } from '@components/assets/images';
import { getOSVersion } from '@libs/factory';
import { CBottomSheetType } from '@typedef/components/common/cbottom.sheet.types';
import React, { useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import CBottomSheetFooter from './components/CBottomSheetFooter';
import CBottomSheetHeader from './components/CBottomSheetHeader';
import * as S from './styles/BottomSheetStyles';
import './styles/dialog.styles.css';
import _ from 'lodash';

type Props = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  type?: CBottomSheetType;
  text?: string;
  onClosedBtnClick: () => void;
  onApplyBtnClick: () => void;
};

const CBottomSheet = ({
  open,
  title,
  children,
  type,
  text,
  onClosedBtnClick,
  onApplyBtnClick,
}: Props) => {
  return (
    <BottomSheet
      onDismiss={_.debounce(() => {
        onClosedBtnClick();
      }, 100)}
      open={open}
      className={`dialog-root ${getOSVersion()}`}
      header={
        <CBottomSheetHeader title={title} onClosedBtnClick={onClosedBtnClick} />
      }
      footer={
        <CBottomSheetFooter
          type={type}
          text={text}
          onCancelBtnClick={onClosedBtnClick}
          onApplyBtnClick={onApplyBtnClick}
        />
      }>
      <div className={'content-root'}>{children}</div>
    </BottomSheet>
  );
};

export default CBottomSheet;
