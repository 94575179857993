import React, { useState } from 'react';
import AddPlace from '../components/AddOtherPlace';
import AddOtherPlace from '../components/AddOtherPlace';
import { images } from '@components/assets/images';

export const PlaceType = [
  {
    id: 1,
    name: '식사',
    imgUrl: images.locationCategory.meal,
  },
  {
    id: 2,
    name: '카페',
    imgUrl: images.locationCategory.cafe,
  },
  {
    id: 3,
    name: '공원',
    imgUrl: images.locationCategory.park,
  },
  {
    id: 4,
    name: '쇼핑',
    imgUrl: images.locationCategory.shopping,
  },
  {
    id: 5,
    name: '문화시설',
    imgUrl: images.locationCategory.culturalFacilities,
  },
  {
    id: 6,
    name: '숙소',
    imgUrl: images.locationCategory.lodging,
  },
  {
    id: 7,
    name: '관광',
    imgUrl: images.locationCategory.tour,
  },
  {
    id: 8,
    name: '액티비티',
    imgUrl: images.locationCategory.activity,
  },
  {
    id: 9,
    name: '기타',
    imgUrl: images.locationCategory.etc,
  },
];

const AddOtherPlaceContainer = () => {
  return <AddOtherPlace />;
};

export default AddOtherPlaceContainer;
