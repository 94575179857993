/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import styled from '@emotion/styled';
import React, { ReactNode, useEffect } from 'react';

const StyledModalBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  opacity: 0.5;
  z-index: 1000;

  /* position: fixed;
  top: -${window.scrollY}px;
  overflow-y: scroll;
  width: 100%; */
`;

const StyledBottomSheet = styled.div`
  z-index: 1000;
  position: fixed;
  width: min(42rem, 100%);
  height: fit-content;
  max-height: 90%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  background-color: ${colors.greyScale.white};
`;

type Props = {
  closeModal: () => void;
  children: ReactNode;
};

const BottomSheet = ({ closeModal, children }: Props) => {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  return (
    <>
      <StyledModalBackground onClick={closeModal} />
      <StyledBottomSheet>
        <div>{children}</div>
      </StyledBottomSheet>
    </>
  );
};

export default BottomSheet;
