import { getDateDiff } from '@libs/factory';
import React from 'react';
import * as S from '../styles/EditModalStyles';

type Props = {
  schedule: Set<string>;
  selectedIdx: number;
  handleDayClick: (idx: number) => void;
};

const EditDayModal = ({ schedule, selectedIdx, handleDayClick }: Props) => {
  return (
    <S.ModalContentRoot>
      <div className='subtitle1 title description'>
        {'선택한 장소의 날짜를 변경해주세요'}
      </div>
      <div className='subtitle1 title'>{'날짜 선택'}</div>
      <div>
        {new Array(getDateDiff(Array.from(schedule))).fill(0).map((_, idx) => (
          <S.OptionItemLabel htmlFor={`select-day${idx}`}>
            <p className={`text-m text ${idx === selectedIdx ? 'check' : ''}`}>
              {`${idx + 1}일차`}
            </p>
            <input
              checked={idx === selectedIdx}
              type='radio'
              id={`select-day${idx}`}
              onChange={() => handleDayClick(idx)}
              hidden></input>
            <div className={`public-btn ${idx === selectedIdx ? 'check' : ''}`}>
              <div
                className={`inner-btn ${idx === selectedIdx ? 'check' : ''}`}
              />
            </div>
          </S.OptionItemLabel>
        ))}
      </div>
    </S.ModalContentRoot>
  );
};

export default EditDayModal;
