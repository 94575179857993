/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

const Tour = () => {
  const navigate = useNavigate();
  const categories = [
    {
      name: 'comingtour',
      text: '다가오는 투어',
    },
    {
      name: 'pasttour',
      text: '지난 투어',
    },
  ];
  const { state } = useLocation();
  const [title, setTitle] = useState('다가오는 투어');

  const onClickedComeTourButton = useCallback(() => {
    setTitle('다가오는 투어');
  }, []);

  const onClickedPastTourButton = useCallback(() => {
    setTitle('지난 투어');
  }, []);

  useEffect(() => {
    if (state) {
      setTitle(state);
    }
  }, [state]);

  return (
    <RootContainer>
      <PageHeaderContainer
        isBackbuttonVisible={true}
        title='나의 투어 보기'
        navigateTo='/mypage'
      />
      <MenuSection>
        {categories.map((c) => (
          <Category
            className={({ isActive }) => (isActive ? 'active' : undefined)}
            to={`${c.name}`}>
            {c.text}
          </Category>
        ))}
      </MenuSection>
      <DivideLine />
      <div className='list-container'>
        <Outlet />
      </div>
    </RootContainer>
  );
};

export default Tour;

const TourCard = styled.div`
  display: flex;
  background-color: ${colors.greyScale.grey1};
  border: 1px solid ${colors.greyScale.grey2};
  border-radius: 6px;
  height: 92px;
  min-width: 321px;
  padding: 16px;
  align-items: center;
  margin-right: 10px;
`;

const TourCard2 = styled.div`
  display: flex;
  height: 92px;
  min-width: 321px;
  padding: 16px;
  align-items: center;
  margin-right: 10px;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
  max-height: 100vh;

  .list-container {
    overflow-y: scroll;
  }
`;

const MenuSection = styled.section`
  background-color: white;
  display: flex;
  width: 100%;
  height: 56px;
  padding-left: 20px;
`;

const Category = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 56px;
  /* width: 52px; */
  padding: 0 12px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.greyScale.grey5};

  &.active {
    color: ${colors.primary.primary};
    font-weight: 700;
    border-bottom: 2px solid ${colors.primary.primary};
  }
`;

const DivideLine = styled.div`
  width: 100%;
  height: 8px;
  min-height: 8px;
  background-color: ${colors.greyScale.grey1};
`;
