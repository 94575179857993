/** @jsxImportSource @emotion/react */
import { images } from '@components/assets/images';
import { css } from '@emotion/react';
import React, { RefObject } from 'react';
import * as S from './styles/MultiFileInputStyles';

type Props = {
  inputRef: RefObject<HTMLInputElement>;
  files: File[];
  onFileSelectClicked: () => void;
  onFileSelected: (selected: File[]) => void;
  onDeleteClicked: (idx: number) => void;
};

const MultiFileInput = ({
  inputRef,
  files,
  onFileSelectClicked,
  onFileSelected,
  onDeleteClicked,
}: Props) => {
  return (
    <S.MultiFileDiv>
      <label htmlFor='cmulti-file-input'>
        <img src={images.cameraGrey3} alt='카메라 이미지' />
        <input
          id='cmulti-file-input'
          ref={inputRef}
          type='file'
          accept='image/*'
          multiple
          onChange={(e) => {
            const selectedFiles = e.target.files;

            if (selectedFiles) {
              onFileSelected(Array.from(selectedFiles));
            }
          }}
          hidden
        />
      </label>
      <div className='item-container'>
        {files.map((file, idx) => (
          <S.FileItemDiv
            key={file.name}
            className={'image-preview-container'}
            onClick={() => onDeleteClicked(idx)}>
            <img
              id={`image-preview-${file.name}`}
              onError={(e) => {
                // (e.currentTarget.src = images.common.placeholderImg)
              }}
            />
          </S.FileItemDiv>
        ))}
      </div>
    </S.MultiFileDiv>
  );
};

export default MultiFileInput;
