/** @jsxImportSource @emotion/react */
import Loading from '@components/common/Loading/Loading';
import TourCardListItemContainer from '@components/common/TourCardListItem/containers/TourCardListItemContainer';
import styled from '@emotion/styled';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TourType } from '@typedef/components/home/home.types';

const WishListTourContent = () => {
  const loadTourLikeList = async (pageParam = 0) => {
    const {
      data: {
        content,
        meta: { maxPage, total },
      },
      config: { isSuccessful },
    } = await request<ListResponseType<TourType>>(
      API_ROUTE.tourLike +
        'likes/me' +
        buildQueryString({ page: pageParam, limit: 20, order: 'DESC' }),
      'get',
    );

    if (!isSuccessful) {
      throw Error('투어 찜 리스트를 불러오는데에 실패했습니다');
    }

    return {
      content,
      pageParam,
      maxPage,
      total,
    };
  };

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    ['tourLikeListQuery'],
    ({ pageParam }) => loadTourLikeList(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.pageParam + 1 > lastPage.maxPage
          ? undefined
          : lastPage.pageParam + 1;
      },
      refetchOnMount: true,
    },
  );

  const { setTarget } = useInfiniteScroll(() => fetchNextPage());
  const wishTourList = data?.pages.map((item) => item.content).flat(1) ?? [];

  return (
    <TourContentSection>
      <ul>
        {wishTourList.map((item) => (
          <li key={item.id}>
            <TourCardListItemContainer tour={item} />
          </li>
        ))}
      </ul>
      {wishTourList.length > 0 && <div ref={setTarget} />}
      {isLoading && <Loading />}
    </TourContentSection>
  );
};

export default WishListTourContent;

const TourContentSection = styled.section`
  padding: 24px 20px;
  z-index: 0;

  ul {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
  }
`;
