import { API_ROUTE, ListResponseType, request } from '@libs/api';
import { ConstantItemType } from '@typedef/api/common.types';
import { ProfileType } from '@typedef/components/MyPage/profile.types';

type ReqBodyType = { displayName: string; thumbnail: string };

export class userRepository {
  queryKey = {
    get: ['profile'] as const,
  };

  async get() {
    return request<ProfileType>(API_ROUTE.user + '/profile', 'get').then(
      (data) => data.data,
    );
  }

  async patch(body: ReqBodyType) {
    return request<ConstantItemType>(API_ROUTE.user, 'patch', {}, body).then(
      (data) => data.data,
    );
  }
}
