/** @jsxImportSource @emotion/react */
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { Outlet, useNavigate } from 'react-router-dom';
import * as S from './styles/WishListStyles';
import { useState } from 'react';

const WishList = () => {
  const categories = [
    {
      name: 'tour',
      text: '투어',
    },
    {
      name: 'place',
      text: '장소',
    },
  ];

  return (
    <S.RootDiv>
      <PageHeaderContainer
        navigateTo={'/mypage'}
        isBackbuttonVisible={true}
        title='나의 찜 리스트'
      />
      <S.MenuSection>
        {categories.map((c) => (
          <li key={c.name}>
            <S.CategoryNavLink
              className={({ isActive }) => (isActive ? 'active' : undefined)}
              to={`${c.name}`}>
              {c.text}
            </S.CategoryNavLink>
          </li>
        ))}
      </S.MenuSection>
      <S.DivideLineDiv />
      <div className='list-container'>
        <Outlet />
      </div>
    </S.RootDiv>
  );
};

export default WishList;
