/** @jsxImportSource @emotion/react */
import ReviewBottomSheet from '@components/common/ReviewBottomSheet/ReviewBottomSheet';
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import CommentListItemContainer from '@components/common/CommentListItem/containers/CommentListItemContainer';
import Button from '@components/common/button/components/Button';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Review = () => {
  const { state } = useLocation() as unknown as {
    state: {
      title: string;
      dDay: number;
      itinerary: string;
      img: string;
    };
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <RootContainer>
      <Header>
        <CloseButtonContainer onClick={() => setIsModalOpen(true)}>
          <img
            src={images.header.close}
            alt=''
            css={css`
              width: 24px;
              height: 24px;
            `}
          />
        </CloseButtonContainer>
        <h1
          css={css`
            flex: 1;
            font-weight: 700;
            font-size: 18px;
            letter-spacing: -0.02em;
          `}>
          투어 후기 작성하기
        </h1>
      </Header>
      <ContentSection>
        <TourCard
          css={css`
            border-bottom: 8px solid ${colors.greyScale.grey1};
          `}>
          <img
            css={css`
              height: 60px;
              width: 60px;
              border-radius: 6px;
              margin-right: 10px;
            `}
            src={state.img}
            alt=''
          />
          <div>
            <div className='text-m'>{state.title}</div>
            <div
              className='text-xs'
              css={css`
                margin-top: 5px;
                color: ${colors.greyScale.grey5};
              `}>
              {state.itinerary}
            </div>
          </div>
        </TourCard>
        <CommentContainer>
          <SubTitle>장소별 한줄평을 남겨주세요</SubTitle>
          <div
            css={css`
              display: flex;
              width: 100%;
              flex-direction: column;
              margin-top: 24px;
            `}>
            {[0, 1, 2, 3, 4, 5].map((item, idx) => (
              <>
                <div
                  css={css`
                    margin: 24px 20px 0 20px;
                  `}>
                  <CommentListItemContainer test={item} key={item} />
                </div>
                <Divider />
              </>
            ))}
          </div>
        </CommentContainer>
      </ContentSection>
      <div
        css={css`
          padding: 20px;
          height: 206px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: ${colors.greyScale.grey4};
        `}>
        투어 후기는 다른 시즈니들에게 도움이 됩니다.
      </div>
      <ButtonContainer>
        <Button
          isFilled={true}
          btnText={'후기 작성 완료'}
          backgroundColor={'primary'}
          onClick={function (): void {
            throw new Error('Function not implemented.');
          }}></Button>
      </ButtonContainer>
      {isModalOpen && (
        <ReviewBottomSheet
          title={'나가기'}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </RootContainer>
  );
};

export default Review;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  text-align: center;
  padding: 17px 0 17px 0;
  background-color: white;
  z-index: 99;
`;

const CloseButtonContainer = styled.section`
  position: absolute;
  left: 24px;
`;

const ContentSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 56px;
  overflow: hidden;
`;

const ButtonContainer = styled.section`
  position: fixed;
  width: 100%;
  bottom: 0px;
  padding: 7px 20px;
  border-top: 1px solid ${colors.greyScale.grey2};
  background-color: ${colors.greyScale.white};
`;

const SubTitle = styled.div`
  margin-top: 24px;
  font-weight: 700;
  font-size: 16px;
`;

const TourCard = styled.div`
  display: flex;
  background-color: ${colors.greyScale.white};
  border-radius: 6px;
  height: 92px;
  min-width: 321px;
  padding: 0 20px;
  align-items: center;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.greyScale.grey2};
`;
