import { images } from '@components/assets/images';
import styles from '../styles/ccheckbox.list.item.module.css';
import React from 'react';

/**
 * 사용법 예시
 *
 *   const [isChecked, setIsChecked] = useState(false);
 *
 *   const onRadioClicked = useCallback(() => {
 *      setIsChecked((prev) => !prev);
 *    }, []);
 *
 *    <CRadioListItem
 *      name='one'
 *      id='1'
 *      isChecked={isChecked}
 *      handleClick={onRadioClicked}
 *      labelText='텍스트'
 *    />
 */

type Props = {
  name: string;
  id: string;
  isChecked: boolean;
  labelText: string;
  handleClick: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

const CRadioListItem = ({
  name,
  id,
  isChecked,
  labelText,
  handleClick,
  onChange,
  value,
}: Props) => {
  return (
    <>
      <input
        name={name}
        type="radio"
        id={id}
        value={value}
        onClick={handleClick}
        onChange={onChange}
        checked={isChecked}
        hidden
      />
      <label
        htmlFor={id}
        className={`${styles['list-item-root']} ${
          styles[isChecked ? 'checked' : '']
        }`}
      >
        <span className={`text-m ${styles['label']}`}>{labelText}</span>
        <img
          className={styles['checkbox']}
          src={isChecked ? images.radioAtv : images.radio}
          alt="radio-btn"
        />
      </label>
    </>
  );
};

export default CRadioListItem;
