import React, { useState } from 'react';
import SocialLoginButton from '../components/SocialLoginButton';

type Props = {
  socialLogin: string;
  socialName: string;
};

const SocialLoginButtonContainer = ({ socialName, socialLogin }: Props) => {
  return (
    <SocialLoginButton socialName={socialName} socialLogin={socialLogin} />
  );
};

export default SocialLoginButtonContainer;
