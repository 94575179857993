import { GetTokenReturnType } from '@typedef/auth.types';

export const SESSION_STORAGE_TYPE = 'SESSION_STORAGE_TYPE';
export const LOCAL_STORAGE_TYPE = 'LOCAL_STORAGE_TYPE';

export type StorageTypes =
  | typeof SESSION_STORAGE_TYPE
  | typeof LOCAL_STORAGE_TYPE;

export const AUTH_KEY = '@hc_t';
export const AUTO_LOGIN_KEY = '@autologin';

export type StorageKeyTypes = typeof AUTH_KEY | typeof AUTO_LOGIN_KEY;

export type AuthItemTypes = {
  hc_eat: string;
  hc_ert: string;
};

export type AutoLoginItemTypes = {
  autoLogin: boolean;
};

export type StorageConfigTypes = {
  key: StorageKeyTypes;
  item: string;
};

export const authConfig = (item: AuthItemTypes): StorageConfigTypes => ({
  key: AUTH_KEY,
  item: JSON.stringify(item),
});

export const autoLoginConfig = (autoLogin: boolean): StorageConfigTypes => ({
  key: AUTO_LOGIN_KEY,
  item: JSON.stringify({ autoLogin }),
});

export function setItemToStorage(
  type: StorageTypes,
  config: StorageConfigTypes,
) {
  if (!window) {
    return null;
  }

  try {
    type === SESSION_STORAGE_TYPE
      ? sessionStorage.setItem(config.key, config.item)
      : localStorage.setItem(config.key, config.item);
  } catch {
    type === SESSION_STORAGE_TYPE
      ? window.sessionStorage.setItem(config.key, config.item)
      : window.localStorage.setItem(config.key, config.item);
  }
}

export function removeItemToStorage(type: StorageTypes, key: StorageKeyTypes) {
  if (!window) {
    return null;
  }

  try {
    type === SESSION_STORAGE_TYPE
      ? sessionStorage.removeItem(key)
      : localStorage.removeItem(key);
  } catch {
    type === SESSION_STORAGE_TYPE
      ? window.sessionStorage.removeItem(key)
      : window.localStorage.removeItem(key);
  }
}

export function getItemFromStorage(
  type: StorageTypes,
  key: StorageKeyTypes,
): string | null {
  if (!window) {
    return null;
  }

  let stored: string | null = null;

  try {
    stored =
      type === SESSION_STORAGE_TYPE
        ? sessionStorage.getItem(key)
        : localStorage.getItem(key);
  } catch {
    stored =
      type === SESSION_STORAGE_TYPE
        ? window.sessionStorage.getItem(key)
        : window.localStorage.getItem(key);
  }

  return stored;
}

export function getAutoLoginFromStorage(): boolean {
  let autoloign = JSON.parse(
    getItemFromStorage(LOCAL_STORAGE_TYPE, AUTO_LOGIN_KEY) ??
      '{"autoLogin":false}',
  );
  return autoloign.autoLogin;
}

export function getTokenFromStorage(type: StorageTypes): GetTokenReturnType {
  const stored = getItemFromStorage(type, AUTH_KEY);

  if (stored) {
    return {
      isTokenStored: true,
      token: JSON.parse(stored),
    };
  }

  return {
    isTokenStored: false,
    token: {
      hc_eat: '',
      hc_ert: '',
    },
  };
}
