import {
  API_ROUTE,
  ListResponseType,
  TmpListResponseType,
  request,
} from '@libs/api';
import { ConstantItemType } from '@typedef/api/common.types';

type ReqBodyType = { name: string };

export class UnitRepository {
  queryKey = {
    get: ['getUnitList'] as const,
  };

  async get() {
    return request<TmpListResponseType<ConstantItemType>>(
      API_ROUTE.unit,
      'get',
    ).then((data) => data.data.contents);
  }

  async put(id: number, body: ReqBodyType) {
    return request<ConstantItemType>(
      API_ROUTE.unit + `/${id}`,
      'put',
      {},
      body,
    ).then((data) => data.data);
  }

  async post(body: ReqBodyType) {
    return request<ConstantItemType>(API_ROUTE.unit, 'post', {}, body).then(
      (data) => data.data,
    );
  }

  async delete(id: number) {
    return request<boolean>(API_ROUTE.unit + `/${id}`, 'delete').then(
      (data) => data.config.isSuccessful,
    );
  }
}
