import {
  GREY1,
  GREY2,
  GREY5,
  PRIMARY,
  PRIMARY_DARK,
  PRIMARY_LIGHTEN,
  WHITE,
} from '@constants/colors';
import styled from '@emotion/styled';

export const ChipsFilterRootDiv = styled.div`
  position: relative;
  height: 38px;
  flex-shrink: 0;
`;

type ChipsProps = {
  isClick: boolean;
  isMedium: boolean;
};

export const ChipsButton = styled.button<ChipsProps>`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: ${({ isClick, isMedium }) =>
    isClick ? (isMedium ? PRIMARY_LIGHTEN : PRIMARY) : GREY1};
  border: ${({ isClick, isMedium }) =>
    `1px solid ${isClick ? (isMedium ? PRIMARY_LIGHTEN : PRIMARY) : GREY2}`};
  border-radius: 20px;
  margin: 0;

  p {
    color: ${({ isMedium, isClick }) =>
      isMedium ? PRIMARY_DARK : isClick ? '#111111' : GREY5};
    font-size: 14px;
    margin-right: 9px;
  }
`;

export const ChipsDropdownDiv = styled.div`
  position: absolute;
  min-width: 150px;
  top: 44px;
  left: 50%;
  transform: translate(-50%, 8px);
  background-color: ${GREY1};
  padding: 12px 16px;
  z-index: 9;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
`;

type DropdownItemProps = {
  isSelected: boolean;
};

export const ChipsDropdownItem = styled.div<DropdownItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    color: ${({ isSelected }) => (isSelected ? '#111111' : GREY5)};
    font-size: 14px;
    margin-right: 9px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;
