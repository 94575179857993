import { API_ROUTE, ListResponseType, request } from '@libs/api';
import { ConstantItemType } from '@typedef/api/common.types';

type ReqBodyType = { name: string };

export class PlaceTypeRepository {
  queryKey = {
    get: ['getPlaceType'] as const,
  };

  async get() {
    return request<{ pages: ListResponseType<ConstantItemType> }>(
      API_ROUTE.placeType,
      'get',
    ).then((data) => data.data.pages.content);
  }

  async put(id: string, body: ReqBodyType) {
    return request<ConstantItemType>(
      API_ROUTE.placeType + `/${id}`,
      'put',
      {},
      body,
    ).then((data) => data.data);
  }

  async post(body: ReqBodyType) {
    return request<ConstantItemType>(
      API_ROUTE.placeType,
      'post',
      {},
      body,
    ).then((data) => data.data);
  }

  async delete(id: string) {
    return request<boolean>(API_ROUTE.placeType + `/${id}`, 'delete').then(
      (data) => data.config.isSuccessful,
    );
  }
}
