/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import Button from '@components/common/button/components/Button';
import { css } from '@emotion/react';
import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const dummy = [
  {
    title: '투어 장소 보기',
    desc: `엔시티가 방문한 장소가 모두 여기에 있어요.\n어디서 나온 건지 멤버가 먹은 메뉴까지 \n알 수 있는 깨알정보를 모두 알려드려요`,
  },
  {
    title: '시즈니 투어 직접 만들기',
    desc: `계획형 시즈니라면 직접 시즈니 투어를 만들어보세요.\n투어 가는 날의 날씨 확인이 가능하며 여행의 지출 내역\n까지 확인할 수 있어요`,
  },
  {
    title: '지출내역 만들기',
    desc: `같이 투어한 시즈니와 더치페이가 가능해요\n여행 카테고리별로 지출 비용을 나눌 수 있어 더욱\n편리한 투어가 될 수 있어요
`,
  },
  {
    title: '투어후기 작성하기',
    desc: `소문난 한줄평 장인 시즈니!\n여행 후에는 한줄 여행 후기를 남겨주시면\n다른 시즈니들과 후기를 공유할 수 있어요
`,
  },
];

const OnBoarding = () => {
  return (
    <div
      css={css`
        width: 100%;
        height: 100vh;
        padding: 20px;
        display: flex;
        flex-direction: column;
      `}>
      <div
        css={css`
          flex: 1;
        `}>
        <Swiper
          css={css`
            width: 100%;
            height: 100%;
            padding-bottom: 48px;
          `}
          pagination={true}
          modules={[Pagination]}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}>
          {[0, 0, 0, 0].map((_, idx) => (
            <SwiperSlide>
              <div
                css={css`
                  position: relative;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  /* background: top / cover no-repeat
                    url(${images.onBoarding[idx]}); */
                `}>
                <img
                  src={images.onBoarding[idx]}
                  css={css`
                    flex: 1;
                    width: 100%;
                    height: 100%;
                    max-height: calc(100vh - 150px);
                    object-fit: cover;
                    object-position: top;
                    padding-top: 40px;
                  `}
                />
                <div
                  css={css`
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    white-space: pre-wrap;
                    text-align: center;
                    background-color: ${colors.greyScale.white};
                    box-shadow: 0px -20px 30px rgb(255, 255, 255);
                  `}>
                  <h1 className='h2'>{dummy[idx].title}</h1>
                  <p className='body2 grey5'>{dummy[idx].desc}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        css={css`
          flex-shrink: 0;
        `}>
        <Button
          isFilled={true}
          btnText={'시즈니 투어 시작하기'}
          backgroundColor={'primary'}
          onClick={() => alert('오픈까지 조금만 기다려주세요!')}
        />
      </div>
    </div>
  );
};

export default OnBoarding;
