import React, { useCallback, useContext, useState } from 'react';
import SearchSection from '../../SelfTour/components/SearchSection';
import { useRecoilState } from 'recoil';
import { SelfTourDetailState } from '@store/atom/SelfTourAtom';
import { nearByState } from '@store/atom/NearByAtom';

const SearchSectionContainer = () => {
  const [searchInput, setSearchInput] = useState('');
  const [selfTourDetail] = useRecoilState(SelfTourDetailState);

  const [value, setValue] = useRecoilState(nearByState);

  const handleChangeInput = useCallback((value: string) => {
    setSearchInput(value);
  }, []);

  const handleSubmit = useCallback(() => {
    setValue((prev) => {
      const clone = { ...prev };
      clone.name = searchInput;
      return clone;
    });
  }, [searchInput]);

  return (
    <SearchSection
      region={selfTourDetail.region}
      searchInput={searchInput}
      handleChangeInput={handleChangeInput}
      handleSubmit={handleSubmit}
    />
  );
};

export default SearchSectionContainer;
