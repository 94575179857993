import { API_ROUTE, ListResponseType, request } from '@libs/api';
import { PlaceDetailType } from '@typedef/components/Location/location.types';

type ReqBodyType = { name: string };

export class ActivityRepository {
  queryKey = {
    get: ['getAcitivityList'] as const,
  };

  async get() {
    return request<{ content: { activities: PlaceDetailType[] } }>(
      API_ROUTE.activity,
      'get',
    ).then((data) => data.data.content.activities);
  }

  async patch(id: number, body: ReqBodyType) {
    return request<PlaceDetailType>(
      API_ROUTE.activity + `/${id}`,
      'put',
      {},
      body,
    ).then((data) => data.data);
  }

  async post(body: ReqBodyType) {
    return request<PlaceDetailType>(API_ROUTE.activity, 'post', {}, body).then(
      (data) => data.data,
    );
  }

  async delete(id: number) {
    return request<boolean>(API_ROUTE.place + `/${id}`, 'delete').then(
      (data) => data.config.isSuccessful,
    );
  }
}
