/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import { images } from '@components/assets/images';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import StarRatingContainer from '../StarRating/containers/StarRatingContainer';
import MyReviewBottomSheet from '@components/MyPage/components/MyReviewBottomSheet/MyReviewBottomSheet';

const MyReviewCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <MyReviewCardBox>
        <ThumbnailBox>
          <Thumbnail>
            <img src={images.dummy.location3} alt='thumbnail' />
          </Thumbnail>
          <TitleArea>
            <h4 className='text-m'>제노따라 자전거 여행</h4>
            <p className='text-xs grey5'>12.20/화 ~ 12.22/목</p>
          </TitleArea>
          <EditArea>
            <img
              src={images.dots}
              alt='menu'
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          </EditArea>
        </ThumbnailBox>
        <TotalRatingArea>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}>
            <StarRatingContainer rate={100} size={20} />
            <p
              className='text-input grey5'
              css={css`
                margin-top: 12px;
              `}>
              다녀온 투어 중 가장 좋았어요
            </p>
          </div>
          <PictureDiaryButton className='text-m'>그림일기</PictureDiaryButton>
        </TotalRatingArea>
        <DetailTourArea>
          {/* 배열을 받아서 반복 렌더링 */}
          <DetailTourBox>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin: 0 20px;
              `}>
              <h4 className='text-input'>송도해상케이블카</h4>
              <p
                className='text-input grey5'
                css={css`
                  margin-top: 8px;
                `}>
                인증샷 잘나와요
              </p>
            </div>
            <DetailTourPictureArea>
              <div>
                <img src={images.dummy.location1} alt='test1' />
                <img src={images.dummy.location2} alt='test2' />
                <img src={images.dummy.location3} alt='test3' />
                <img src={images.dummy.location4} alt='test4' />
                <img src={images.dummy.location1} alt='test1' />
                <img src={images.dummy.location2} alt='test2' />
              </div>
            </DetailTourPictureArea>
          </DetailTourBox>
          <DetailTourBox>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin: 0 20px;
              `}>
              <h4 className='text-input'>송도해상케이블카</h4>
              <p
                className='text-input grey5'
                css={css`
                  margin-top: 8px;
                `}>
                인증샷 잘나와요
              </p>
            </div>
            <DetailTourPictureArea>
              <div>
                <img src={images.dummy.location1} alt='test1' />
                <img src={images.dummy.location2} alt='test2' />
                <img src={images.dummy.location3} alt='test3' />
                <img src={images.dummy.location4} alt='test4' />
                <img src={images.dummy.location1} alt='test1' />
                <img src={images.dummy.location2} alt='test2' />
              </div>
            </DetailTourPictureArea>
          </DetailTourBox>
        </DetailTourArea>
      </MyReviewCardBox>
      {isModalOpen && (
        <MyReviewBottomSheet closeModal={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

export default MyReviewCard;

const MyReviewCardBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ThumbnailBox = styled.div`
  display: flex;
  flex: 1;
  height: 92px;
  align-items: center;
  margin: 0 20px;
  border-bottom: 1px solid ${colors.greyScale.grey2};
`;

const Thumbnail = styled.div`
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  height: 60px;
  width: 60px;
  margin: 16px 0;

  &.img {
    height: 60px;
    width: 60px;
    object-fit: cover;
  }
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const EditArea = styled.div`
  margin-left: auto;
`;

const TotalRatingArea = styled.div`
  display: flex;
  margin: 12px 20px;
  justify-content: space-between;
  align-items: center;
`;

const PictureDiaryButton = styled.button`
  padding: 10px 12px;
  background-color: #f1e1f4;
  color: #863895;
  border-radius: 20px;
  height: 38px;
`;

const DetailTourArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DetailTourBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailTourPictureArea = styled.div`
  overflow-x: scroll;

  div {
    width: fit-content;
    gap: 10px;
    display: flex;
    margin: 16px 20px;
    img {
      width: 210px;
      height: 150px;
      border-radius: 6px;
      object-fit: cover;
    }
  }
`;
