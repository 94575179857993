import React, { useState } from 'react';
import ChipswithImage from '../components/ChipswithImage';
import { images, RegionKeyType } from '../../../assets/images';

type Props = {
  isClick: boolean;
  btntext: string;
  imgSrc: string;
  onClickHandler?: () => void;
};
const ChipswithImageContainer = (props: Props) => {
  return <ChipswithImage {...props} />;
};

export default ChipswithImageContainer;
