import React, { useContext } from 'react';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import TourList from '../TourList';
import { TourType } from '@typedef/components/home/home.types';
import { TourListContext } from '../context/TourListProvider';
import { useInfiniteQuery } from '@tanstack/react-query';

const TourListContainer = () => {
  const {
    value: { name },
  } = useContext(TourListContext);

  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    ['infiniteMoreTourList', name],
    ({ pageParam }) => getTourList(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.pageParam + 1 > lastPage.maxPage
          ? undefined
          : lastPage.pageParam + 1;
      },
      staleTime: 1000 * 60,
      refetchOnWindowFocus: true,
      cacheTime: 1000 * 60 * 5,
    },
  );

  const getTourList = async (pageParam = 0) => {
    const {
      data: {
        content,
        meta: { maxPage, total },
      },
      config: { isSuccessful },
    } = await request<ListResponseType<TourType>>(
      API_ROUTE.tour +
        buildQueryString({
          page: pageParam,
          limit: 20,
          ...(name && { name }),
        }),

      'get',
    );

    if (!isSuccessful) {
      alert('장소 리스트를 불러오는데에 실패했습니다');
      throw Error('투어 리스트를 불러오는데에 실패했습니다');
    }

    return {
      items: content,
      pageParam,
      maxPage,
      total,
    };
  };

  return (
    <TourList
      isLoading={isLoading}
      tourList={data?.pages.map((page) => page.items).flat(1)}
      total={data?.pages[0].total}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default TourListContainer;
