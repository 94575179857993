import { images } from '@components/assets/images';
import React from 'react';
import * as S from '../styles/CommentTabStyles';
import { LocationReviewType } from '@typedef/components/Location/locationReview.types';
import StarRatingContainer from '@components/common/StarRating/containers/StarRatingContainer';

type Props = {
  commentItem: LocationReviewType;
};
const Comment = ({ commentItem }: Props) => {
  const date = new Date(commentItem.createdAt);
  const formattedDate = date
    .toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\.$/, '');

  return (
    <S.CommentDiv>
      <div className='comment'>
        <S.CommentHeader>
          <img src={commentItem.userThumbnail} alt='유저 프로필 사진' />
          <div>
            <div className='date-container'>
              <span className='text-xs'>{commentItem.userDisplayName}</span>
              <div className='bar' />
              <span className='text-xs'>{formattedDate}</span>
            </div>
            <div className='rate-container'>
              <StarRatingContainer
                rate={commentItem.rating * 20}
                test={commentItem.rating * 20}
                size={14}
              />
            </div>
          </div>
        </S.CommentHeader>
        <p className='comment-main text-m'>{commentItem.content}</p>
        {/* <button className='like-button'>
          <img src={images.like} alt='한줄평 좋아요 하트' />
          <span className='text-xs'>{'12'}</span>
        </button> */}
      </div>
      <div className='image-container'>
        {commentItem.images.map((item) => (
          <img
            style={{ width: 120, height: 120 }}
            src={item}
            alt='리뷰이미지'
          />
        ))}
      </div>
    </S.CommentDiv>
  );
};

export default Comment;
