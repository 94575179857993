import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import '@styles/core.css';

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };

    Kakao: any;
    initMap: () => void;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
