/** @jsxImportSource @emotion/react */
import { GREY2, GREY4, PRIMARY, WHITE } from '@constants/colors';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  GNBItems,
  GNBItemsTypes,
  GNBTabTypes,
} from '@typedef/components/gnb.types';
import React from 'react';

type Props = {
  currentTab: GNBTabTypes;
  onTabClicked: (item: GNBItemsTypes) => void;
};

const GNB = ({ currentTab, onTabClicked }: Props) => {
  return (
    <S.GNBRootDiv>
      {GNBItems.map((item) => (
        <S.ItemDiv key={item.label} onClick={() => onTabClicked(item)}>
          <img
            width={28}
            height={28}
            src={
              currentTab === item.path ? item.icon.active : item.icon.inactive
            }
            alt={item.label}
          />
          <span
            css={css`
              font-size: 11px;
              font-weight: 400;
              color: ${currentTab === item.path ? PRIMARY : GREY4};
            `}>
            {item.label}
          </span>
        </S.ItemDiv>
      ))}
    </S.GNBRootDiv>
  );
};

export default GNB;

const GNBRootDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  max-width: 500px;
  width: 100%;
  height: 56px;
  display: flex;
  padding: 0 20px;
  border: 1px solid ${GREY2};
  background-color: ${WHITE};
  transform: translate(-50%);
  z-index: 9999999;
`;

const ItemDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const S = { GNBRootDiv, ItemDiv };
