/** @jsxImportSource @emotion/react */
import GNBContainer from '@components/common/GNB/containers/GNBContainer';
import { css } from '@emotion/react';
import { markerState } from '@store/atom/MapAtom';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const TabNavigation = () => {
  const [marker] = useRecoilState(markerState);

  return (
    <>
      <div
        css={css`
          width: 100%;
          min-height: 100%;
          flex: 1;
          margin-bottom: 56px;
        `}>
        <Outlet />
      </div>
      {!marker && <GNBContainer />}
    </>
  );
};

export default TabNavigation;
