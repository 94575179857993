/** @jsxImportSource @emotion/react */
import MapListItemContainer from '@components/common/PlaceListItem/containers/PlaceListItemContainer';
import Loading from '@components/common/Loading/Loading';
import styled from '@emotion/styled';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import React, { useEffect, useState } from 'react';

const WishListPlaceContent = () => {
  const [coords, setCoords] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });

  const loadCurrentPos = () => {
    navigator.geolocation.getCurrentPosition((e) => {
      const { latitude, longitude } = e.coords;

      setCoords({ lat: latitude, lng: longitude });
      setTimeout(() => {
        refetch();
      }, 300);
    });
  };

  const getPlaceList = async (pageParam = 0) => {
    const {
      data: {
        content,
        meta: { maxPage },
      },
      config: { isSuccessful },
    } = await request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.placeLike +
        'likes/me' +
        buildQueryString({
          page: pageParam ?? 0,
          limit: 20,
          x: coords.lng,
          y: coords.lat,
        }),

      'get',
    );

    if (!isSuccessful) {
      alert('장소 리스트를 불러오는데에 실패했습니다');
      throw new Error('장소 리스트를 불러오는데에 실패했습니다');
    }

    return { content, pageParam, maxPage };
  };

  const { data, fetchNextPage, isLoading, refetch } = useInfiniteQuery(
    [`placeLikeListQuery`],
    ({ pageParam }) => getPlaceList(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.pageParam + 1 > lastPage.maxPage
          ? undefined
          : lastPage.pageParam + 1;
      },
    },
  );

  const { setTarget } = useInfiniteScroll(() => fetchNextPage());
  const wishPlaceList = data?.pages.map((item) => item.content).flat(1) ?? [];

  useEffect(() => {
    loadCurrentPos();

    return () => {
      refetch();
    };
  }, []);

  if (isLoading) {
    return (
      <PlaceContentSection>
        <Loading />
        <div ref={setTarget} />
      </PlaceContentSection>
    );
  }
  return (
    <PlaceContentSection>
      {wishPlaceList.map((item, idx) => (
        <MapListItemContainer key={idx} place={item} />
      ))}
      <div ref={setTarget} />
    </PlaceContentSection>
  );
};

export default WishListPlaceContent;

const PlaceContentSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
