import { images } from '@components/assets/images';
import React from 'react';
import * as S from './styles/PlaceListItemStyles';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { distanceFormat } from '@libs/factory';

type Props = {
  liked: boolean;

  place: PlaceDetailType;
  onClick: () => void;
  handleLikeClick: () => void;
};

const PlaceListItem = ({ place, liked, onClick, handleLikeClick }: Props) => {
  return (
    <S.PlaceListItemStyle className='map-item'>
      <div className='img-wrapper'>
        <img
          referrerPolicy='no-referrer'
          loading='lazy'
          src={place.images[0]}
          alt='장소 사진'
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = images.placeholder;
            target.classList.add('error');
          }}
          onClick={onClick}
        />
      </div>
      <S.ItemInfoStyle onClick={onClick}>
        <div className='item-title'>
          <span className='title text-xxl'>{place.name}</span>
          <span className='description text-m'>{place.title}</span>
        </div>
        <div className='location-info'>
          <span className='distance text-xs'>
            {place.distance ? distanceFormat(place.distance) : ''}
          </span>
          <div className='bar' />
          <span className='location text-xs'>{place.address}</span>
        </div>
      </S.ItemInfoStyle>
      <button className='like' onClick={handleLikeClick}>
        <img src={liked ? images.likeAtv : images.like} alt='' />
      </button>
    </S.PlaceListItemStyle>
  );
};

export default PlaceListItem;
