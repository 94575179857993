import React, { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SelfTourDetailState } from '@store/atom/SelfTourAtom';
import { ScopeType } from '@typedef/components/SelfTour/selftour.types';
import { Modal } from '@components/common/Modal/Modal';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import ModalTourScope from '../components/ModalTourScope';

type Props = {
  handleModalOpen: (value: boolean) => void;
};

const isOpen: { label: string; value: ScopeType }[] = [
  { label: '전체공개', value: 'PUBLIC' },
  { label: '비공개', value: 'PRIVATE' },
];

const ModalTourScopeContainer = ({ handleModalOpen }: Props) => {
  const [selectedIdx, setSelectedIdx] = useState(1);
  const [selfTourDetail, setSelftourDetail] =
    useRecoilState(SelfTourDetailState);

  const handleBtnClick = useCallback((idx: number) => {
    setSelectedIdx(idx);
  }, []);

  const handelApplyClick = useCallback(() => {
    setSelftourDetail({ ...selfTourDetail, scope: isOpen[selectedIdx].value });
    handleModalOpen(false);
  }, [selectedIdx, selfTourDetail, isOpen]);

  const handleCloseClick = useCallback(() => {
    handleModalOpen(false);
  }, []);

  return (
    <Modal>
      <Modal.Header
        title='투어 공개 여부'
        hasCloseBtn={true}
        onCloseClick={handleCloseClick}
      />
      <Modal.Content>
        <ModalTourScope
          isOpen={isOpen}
          selectedIdx={selectedIdx}
          handleBtnClick={handleBtnClick}
        />
      </Modal.Content>
      <Modal.Footer>
        <ButtonContainer
          isFilled={false}
          btnText={'취소'}
          backgroundColor={'primary'}
          onClick={handleCloseClick}
        />
        <ButtonContainer
          isFilled={true}
          btnText={'적용'}
          backgroundColor={'primary'}
          onClick={handelApplyClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTourScopeContainer;
