import { images } from '@components/assets/images';
import React from 'react';
import * as S from '../styles/ScheduleSectionStyles';
import { SelfTourPlaceType } from '@typedef/components/SelfTour/selftour.types';
import { getTime } from '@libs/factory';
import { useNavigate } from 'react-router-dom';

type Props = {
  tourPlaceInfo: SelfTourPlaceType;
  isLineVisible?: boolean;
};
const LocationItem = ({ tourPlaceInfo, isLineVisible }: Props) => {
  const navigate = useNavigate();
  return (
    <S.LocationItemDiv>
      <div className='time-container'>
        {isLineVisible && <div className='line' />}
        <div className='dot' />
        <div className='caption1 time'>{`${getTime(
          `${tourPlaceInfo.from}`,
        )}-${getTime(`${tourPlaceInfo.to}`)}`}</div>
      </div>
      <div
        className='place-container'
        onClick={() =>
          navigate(
            `/place/detail?id=${tourPlaceInfo.place.id}&name=${tourPlaceInfo.place.name}`,
          )
        }>
        <div className='img-wrapper'>
          <img
            loading='lazy'
            src={tourPlaceInfo.place.images[0]}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = images.placeholder;
              target.classList.add('error');
            }}
            alt='장소 사진'
          />
        </div>
        <div className='location-info'>
          <p className='text-xxl black'>{tourPlaceInfo.place.name}</p>
          <p className='text-m grey5 title'>{tourPlaceInfo.place.title}</p>
        </div>
      </div>
    </S.LocationItemDiv>
  );
};

export default LocationItem;
