/** @jsxImportSource @emotion/react */
import React, { RefObject, useContext, useEffect } from 'react';
import { css } from '@emotion/react';

const Map = () => {
  return (
    <div
      id='map'
      css={css`
        max-width: 500px;
        width: 100vw;
        height: 100vh;
      `}
    />
  );
};

export default Map;
