import {
  BLACK,
  ERROR,
  PRIMARY,
  PRIMARY_DARK,
  PRIMARY_LIGHTEN,
} from '@constants/colors';
import styled from '@emotion/styled';

export const RootContainer = styled.div`
  max-width: 500px;
  padding: 0 20px;
  .month-list {
    height: calc((100 - 40px) / 7 * 7);
    max-height: 90vh;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  max-width: 500px;
`;

export const DaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;

  .day {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MonthContainer = styled.div`
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
  }

  .week-list-container {
    display: grid;
    grid-template-rows: repeat(5, 1fr);

    .week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }
  }
`;

export const DateContainer = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;

  &.sat {
    color: #018ad0;
  }

  &.sun {
    color: ${ERROR};
  }

  &.today {
    color: ${PRIMARY_DARK};
    font-weight: 700;
  }

  &.start {
    background-color: ${PRIMARY};
    color: ${BLACK};
    border-radius: 6px 0 0 6px;
    ${(props) => props.theme.font.text_xxl}
  }

  &.end {
    background-color: ${PRIMARY};
    color: ${BLACK};
    border-radius: 0 6px 6px 0;
    ${(props) => props.theme.font.text_xxl}
  }

  &.range {
    background-color: ${PRIMARY_LIGHTEN};
  }
`;
