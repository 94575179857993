/** @jsxImportSource @emotion/react */
import { colors } from '@components/assets/colors';
import styled from '@emotion/styled';

const TourSkeleton = () => {
  return (
    <>
      {new Array(5).fill(0).map((_) => (
        <S.RootDiv>
          <div className='info-container'>
            <span className='title-container effect'></span>
            <span className='location-container effect'></span>
          </div>
        </S.RootDiv>
      ))}
    </>
  );
};

export default TourSkeleton;

const RootDiv = styled.div`
  position: relative;
  height: 183px;
  min-width: 325px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${colors.greyScale.grey1};
  border: 1px solid ${colors.greyScale.grey3};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    background: linear-gradient(
      to right,
      ${colors.greyScale.grey1},
      ${colors.greyScale.grey2},
      ${colors.greyScale.grey1}
    );
    animation: skeleton 1.5s infinite linear;
  }

  @keyframes skeleton {
    0% {
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(460px);
    }
  }

  .info-container {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    height: 83px;
    width: 100%;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .effect {
    position: relative;
    display: block;
    height: 20px;
    background-color: ${colors.greyScale.grey3};
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 100%;
      background: linear-gradient(
        to right,
        ${colors.greyScale.grey3},
        ${colors.greyScale.grey1},
        ${colors.greyScale.grey3}
      );
      animation: skeleton 1.5s infinite linear;
    }
  }

  .title-container {
    width: 200px;
  }

  .location-container {
    width: 100px;
  }
`;

const S = { RootDiv };
