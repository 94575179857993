import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { atom } from 'recoil';

export const ScheduleModeState = atom<'default' | 'edit'>({
  key: 'schduleModeState',
  default: 'default',
});

export const ActiveScheduleDayState = atom({
  key: 'ActiveScheduleDay',
  default: 0,
});

export const PlaceListByDayState = atom<Map<number, PlaceDetailType[]>>({
  key: 'PlaceListByDateState',
  default: new Map(),
});
