import styled from '@emotion/styled';

export const RootDiv = styled.div`
  min-height: 100%;
`;

export const SectionTitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 0 20px;
`;

export const TourSection = styled.section`
  margin-top: 38px;

  .list-container {
    height: 183px;
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    padding: 0 20px;
  }
`;

export const RegionListUl = styled.ul`
  position: relative;
  display: flex;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 12px;
  overflow-y: scroll;

  & > li {
    display: block;
    flex-shrink: 0;
  }

  & > li:first-of-type {
    margin-left: 20px;
  }
`;

export const PlaceListUl = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 32px;
  padding: 0 20px;
`;
