import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import EditMode from '../components/EditMode';
import { ScheduleModeState } from '@store/atom/ScheduleAtom';
import {
  SelfTourDetailSelector,
  SelfTourDetailState,
  SelfTourModalState,
} from '@store/atom/SelfTourAtom';
import {
  SelfTourModalType,
  SelfTourPlaceType,
} from '@typedef/components/SelfTour/selftour.types';

const EditModeContainer = () => {
  const setScheduleMode = useSetRecoilState(ScheduleModeState);
  const [selfTourDetail] = useRecoilState(SelfTourDetailState);
  const setSelfTourDetail = useSetRecoilState(SelfTourDetailSelector);
  const { placeList, selectedDay } = selfTourDetail;
  const [list, setList] = useState<SelfTourPlaceType[]>(
    placeList.get(selectedDay) ?? [],
  );
  const [isModalOpen, setIsModalOpen] = useRecoilState(SelfTourModalState);

  const handlePlaceClick = (idx: number) => {
    setList((prev) => {
      const clone = [...prev];
      clone[idx].isSelected = !clone[idx].isSelected;
      return clone;
    });
  };

  //편집 완료
  //모든 리스트의 선택 상태와 수정 가능한 아이템 인덱스를 초기화
  const handleEditDone = () => {
    const clone = new Map(placeList);
    clone.forEach((placeArr) => {
      placeArr.forEach((place) => (place.isSelected = false));
    });
    setSelfTourDetail({ placeList: clone, editableItemIdx: -1 });
    setScheduleMode('default');
  };

  // 장소 삭제
  const handleDeletePlaces = async () => {
    const clone = new Map(placeList);
    const filteredList = list.filter((item) => !item.isSelected);
    clone.set(selectedDay, filteredList);
    setList(filteredList);
    setSelfTourDetail({ placeList: clone });
  };

  const handleModalOpen = (key: keyof SelfTourModalType, value: boolean) => {
    setSelfTourDetail({
      editableItemIdx: list.findIndex((item) => item.isSelected),
    });
    setIsModalOpen({ ...isModalOpen, [key]: value });
  };

  useEffect(() => {
    setList(placeList.get(selectedDay) ?? []);
  }, [selectedDay]);

  return (
    <EditMode
      list={placeList.get(selectedDay) ?? []}
      handlePlaceClick={handlePlaceClick}
      handleEditDone={handleEditDone}
      handleDeletePlaces={handleDeletePlaces}
      handleModalOpen={handleModalOpen}
    />
  );
};

export default EditModeContainer;
