import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { getDateDiff, timeFormat } from '@libs/factory';
import React from 'react';
import * as S from '../styles/AddPlaceStyles';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';
import FloatingBottomButtonContainer from '@components/common/FloatingBottomButton/containers/FloatingBottomButtonContainer';

type Props = {
  schedule: Set<string>;
  selectedIdx: number;
  time: { from: string; to: string };
  handleDayClick: (idx: number) => void;
  handleTimeChange: (key: 'from' | 'to', value: string) => void;
  handleDoneClick: () => void;
};

const AddPlaceSetting = ({
  schedule,
  selectedIdx,
  time,
  handleDayClick,
  handleTimeChange,
  handleDoneClick,
}: Props) => {
  console.log(schedule);

  return (
    <div>
      <PageHeaderContainer isBackbuttonVisible={true} title='투어 장소 추가' />
      <S.Section>
        <div className='subtitle1 title'>{'날짜 선택'}</div>
        <div>
          {new Array(getDateDiff(Array.from(schedule.values())))
            .fill(0)
            .map((_, idx) => (
              <S.OptionItemLabel htmlFor={`select-day${idx}`}>
                <p
                  className={`text-m text ${
                    idx === selectedIdx ? 'check' : ''
                  }`}>
                  {`${idx + 1}일차`}
                </p>
                <input
                  checked={idx === selectedIdx}
                  type='radio'
                  id={`select-day${idx}`}
                  onChange={() => handleDayClick(idx)}
                  hidden></input>
                <div
                  className={`public-btn ${
                    idx === selectedIdx ? 'check' : ''
                  }`}>
                  <div
                    className={`inner-btn ${
                      idx === selectedIdx ? 'check' : ''
                    }`}
                  />
                </div>
              </S.OptionItemLabel>
            ))}
        </div>
      </S.Section>
      <S.Section>
        <div className='subtitle1 title'>{'시간 설정'}</div>
        <div>
          <S.TimeItemLable>
            <div className={'time-list'}>
              <p className={`text-m time-text`}>{'시작 시각'}</p>
              <label htmlFor='input-time-start' className={`text-m input-time`}>
                {timeFormat(time.from)}
                <input
                  type='time'
                  id='input-time-start'
                  name='input-time-start'
                  className={'time'}
                  value={time.from}
                  onChange={(e) => {
                    handleTimeChange('from', e.target.value);
                  }}
                />
              </label>
            </div>
          </S.TimeItemLable>
          <S.TimeItemLable>
            <div className={'time-list'}>
              <p className={`text-m time-text`}>{'종료 시각'}</p>
              <label htmlFor='input-time-end' className={`text-m input-time`}>
                {timeFormat(time.to)}
                <input
                  type='time'
                  id='input-time-end'
                  name='input-time-end'
                  className={'time'}
                  value={time.to}
                  onChange={(e) => {
                    handleTimeChange('to', e.target.value);
                  }}
                />
              </label>
            </div>
          </S.TimeItemLable>
        </div>
      </S.Section>
      <FloatingBottomButtonContainer>
        <ButtonContainer
          isFilled={true}
          btnText={'완료'}
          backgroundColor={'primary'}
          onClick={handleDoneClick}
        />
      </FloatingBottomButtonContainer>
    </div>
  );
};

export default AddPlaceSetting;
