import React, { useCallback, useEffect, useState } from 'react';
import PreviewSection from '../components/PreviewSection';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { useRecoilState } from 'recoil';
import { API_ROUTE, buildQueryString, request } from '@libs/api';
import { authState } from '@store/atom/AuthAtom';
import { useNavigate, useSearchParams } from 'react-router-dom';

type Props = {
  place: PlaceDetailType;
};

const PreviewSectionContainer = ({ place }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [liked, setLiked] = useState(place.liked);
  const [{ isLoggedIn }] = useRecoilState(authState);
  const [params] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleChangeIndex = useCallback((idx: number) => {
    setActiveIndex(idx);
  }, []);

  const handleLikeClick = useCallback(async () => {
    if (!isLoggedIn) {
      alert('로그인이 필요한 기능입니다');
      return;
    }

    const {
      data,
      config: { isSuccessful },
    } = await request<{ liked: boolean }>(
      API_ROUTE.placeLike + `${place.id}/${liked ? 'dislike' : 'like'}`,
      'post',
    );

    if (!isSuccessful) {
      alert('요청에 실패했습니다');
      return;
    }

    setLiked(data.liked);
  }, [isLoggedIn, place, liked]);

  const handleShareClick = () => {
    setIsOpen(true);
    navigate(
      `${window.location.pathname}${buildQueryString({
        [`isShareModalOpen`]: String(!isOpen),
      })}`,
    );
  };

  const handleModalClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    !params.get('isShareModalOpen') && setIsOpen(false);
  }, [params]);

  return (
    <PreviewSection
      place={place}
      liked={liked}
      activeIndex={activeIndex}
      isOpen={isOpen}
      handleChangeIndex={handleChangeIndex}
      handleLikeClick={handleLikeClick}
      handleShareClick={handleShareClick}
      handleModalClose={handleModalClose}
    />
  );
};

export default PreviewSectionContainer;
