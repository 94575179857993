import { WHITE } from '@constants/colors';
import styled from '@emotion/styled';

export const SearchBarDiv = styled.div`
  position: fixed;
  max-width: 500px;
  top: 11px;
  left: 50%;
  height: 70px;
  width: 100%;
  padding: 11px 20px;
  transform: translate(-50%);
  z-index: 9;
`;
