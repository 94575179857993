import React, { useState } from 'react';
import CommentBottomSheetContainer from '../containers/CommentBottomSheetContainer';
import CommentContainer from '../containers/CommentContainer';
import * as S from '../styles/CommentTabStyles';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import StarRatingContainer from '@components/common/StarRating/containers/StarRatingContainer';
import { LocationReviewType } from '@typedef/components/Location/locationReview.types';

type Props = {
  open: boolean;
  place: PlaceDetailType;
  reviewList: LocationReviewType[];
  handleCommentClick: () => void;
  handleBottomSheetClose: () => void;
};

const CommentTab = ({
  open,
  place,
  reviewList,
  handleBottomSheetClose,
  handleCommentClick,
}: Props) => {
  return (
    <div>
      <CommentBottomSheetContainer
        isOpen={open}
        onClosedBtnClick={handleBottomSheetClose}
        place={place}
      />
      <S.RateSection>
        <span className='title subtitle black'>{`평점(${place.rating})`}</span>
        <div>
          <StarRatingContainer rate={place.rating * 20} size={48} />
        </div>
      </S.RateSection>
      <S.CommentSection>
        <div className='review-controller'>
          <p className='text text-m'>
            {'이 투어에 대한 한줄평을 남기고 싶다면?'}
          </p>
          <div className='btn-container'>
            <button className='btn' onClick={handleCommentClick}>
              {'한줄평 남기기'}
            </button>
          </div>
        </div>
        {reviewList.map((item) => (
          <CommentContainer commentItem={item} key={item.id} />
        ))}
      </S.CommentSection>
    </div>
  );
};

export default CommentTab;
