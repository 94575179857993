import React from 'react';
import TextButton from '../components/TextButton';

type Props = {
  btnName: string;
  onClick: () => void;
};
const TextButtonContainer = ({ btnName, onClick }: Props) => {
  return <TextButton btnName={btnName} onClick={onClick} />;
};

export default TextButtonContainer;
