import { API_ROUTE, ListResponseType, request } from '@libs/api';
import { PlaceDetailType } from '@typedef/components/Location/location.types';

type ReqBodyType = {
  unitIds: number[];
  activityIds: number[];
  placeTypeIds: number[];
  regionId: number;
  title: string;
  name: string;
  address: string;
  description: string;
  location: {
    x: number;
    y: number;
  };
  images: string[];
  visitDate: string;
};

export class PlaceRepository {
  queryKey = {
    get: ['getPlaceList'] as const,
    getRecommends: ['getRecommendPlaceList'] as const,
  };

  async get(queryString = '') {
    return request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place + queryString,
      'get',
    ).then((data) => data.data.content);
  }

  async getRecommends(queryString = '') {
    return request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place + '/recommends' + queryString,
      'get',
    ).then((data) => data.data.content);
  }

  async put(id: number, body: ReqBodyType) {
    return request<PlaceDetailType>(
      API_ROUTE.place + `/${id}`,
      'put',
      {},
      body,
    ).then((data) => data.data);
  }

  async post(body: ReqBodyType) {
    return request<PlaceDetailType>(API_ROUTE.place, 'post', {}, body).then(
      (data) => data.data,
    );
  }

  async delete(id: number) {
    return request<boolean>(API_ROUTE.place + `/${id}`, 'delete').then(
      (data) => data.config.isSuccessful,
    );
  }
}
