import {
  API_ROUTE,
  ListResponseType,
  TmpListResponseType,
  request,
} from '@libs/api';
import { RegionType } from '@typedef/api/common.types';

type ReqBodyType = { name: string };

export class RegionRepository {
  queryKey = {
    get: ['getRegion'] as const,
    patch: ['patchRegion'] as const,
    post: ['postRegion'] as const,
  };

  async get() {
    return request<TmpListResponseType<RegionType>>(
      API_ROUTE.region,
      'get',
    ).then((data) => data.data.contents);
  }

  async patch(id: number, body: ReqBodyType) {
    return request<RegionType>(
      API_ROUTE.region + `/${id}`,
      'put',
      {},
      body,
    ).then((data) => data.data);
  }

  async post(body: ReqBodyType) {
    return request<RegionType>(API_ROUTE.region, 'post', {}, body).then(
      (data) => data.data,
    );
  }
}
