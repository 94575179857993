import React, { useCallback, useState } from 'react';
import ChipsFilter from '../components/ChipsFilter';
import { UnitType } from '@typedef/api/common.types';

type Props = {
  isClick: boolean;
  btntext: string;
  isMedium?: boolean;
  onClickHandler: (id: number) => void;
  list: UnitType[];
  selectedIds: number[];
};

const ChipsFilterContainer = (props: Props) => {
  return <ChipsFilter {...props} />;
};

export default ChipsFilterContainer;
