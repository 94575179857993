import 'dayjs/locale/zh-cn'; // import locale
import Month from './components/Month';
import {
  getNextMonth,
  getNextYear,
  getPreviousMonth,
  getPreviousYear,
} from '@libs/factory';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Days from './components/Days';
import CalendarHeader from './components/CalendarHeader';
import * as S from './styles/calendarStyles';

// 1. 날짜는 현재 날짜 기준 한달 전후만 선택 가능함
// 2. 날짜 두개 선택하면 날짜 사이 스타일 변화
// 3. 당일 치기 가능

const Calendar = () => {
  const today = new Date().getDate();
  const year = new Date().getFullYear();
  const month = new Date().getMonth();

  return (
    <S.RootContainer>
      <CalendarHeader />
      <Days />
      <Swiper initialSlide={1} className={'month-list'} direction={'vertical'}>
        <SwiperSlide>
          <Month
            year={getPreviousYear(year, month)}
            month={getPreviousMonth(month)}
          />
        </SwiperSlide>
        <SwiperSlide>
          <Month year={year} month={month} today={today} />
        </SwiperSlide>
        <SwiperSlide>
          <Month year={getNextYear(year, month)} month={getNextMonth(month)} />
        </SwiperSlide>
      </Swiper>
    </S.RootContainer>
  );
};

export default Calendar;
