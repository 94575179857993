import React, { createContext, useCallback, useMemo, useState } from 'react';
import { TourType } from '@typedef/components/home/home.types';

type TourListContextValueType = {
  isSubmitted: boolean;
  page: number;
  name: string;
  tourList: TourType[];
};

type TourListContextType = {
  value: TourListContextValueType;
  actions: {
    updateContext: (payload: Partial<TourListContextValueType>) => void;
  };
};

export const TourListContext = createContext<TourListContextType>({
  value: {
    isSubmitted: false,
    page: 0,
    name: '',
    tourList: [],
  },
  actions: {
    updateContext: () => {},
  },
});

type Props = {
  children: JSX.Element;
};

const TourListProvider = ({ children }: Props) => {
  const [value, setValue] = useState<TourListContextValueType>({
    isSubmitted: false,
    page: 0,
    name: '',
    tourList: [],
  });

  const updateContext = useCallback(
    (payload: Partial<TourListContextValueType>) => {
      setValue((prev) => {
        return { ...prev, ...payload };
      });
    },
    [],
  );

  const store = useMemo(() => {
    return {
      value,
      actions: { updateContext },
    };
  }, [value, updateContext]);

  return (
    <TourListContext.Provider value={store}>
      {children}
    </TourListContext.Provider>
  );
};

export default TourListProvider;
