import CBottomSheetContainer from '@components/common/CBottomSheet/containers/CBottomSheetContainer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Calendar from '../Calendar';
import { CalendarContext } from '../context/CalendarContext';
import { Modal } from '@components/common/Modal/Modal';
import ButtonContainer from '@components/common/button/containers/ButtonContainer';

/**
 * 사용법
 * 
 * 선택된 날짜, 날짜 선택 함수, 리셋 함수는 CalendarContext로 관리
 * 
 * CalendarContext.Provider 안에 모달을 넣어서 모달 내에서 날짜를 전역으로 관리함 
 * 
 * 
 *  
 * const [open, setOpen] = useState(false);

  const [schedule, setSchedule] = useState<Set<string>>(new Set());

  const onClosedBtnClicked = useCallback(() => {
    setOpen(false);
  }, []);

  const onChangeSchedule = useCallback((start: string, end: string) => {
    setSchedule(new Set([start, end]));
  }, []);

    <button onClick={() => setOpen(true)}>Open</button>
    <CalendarContainer
        open={open}
        initalSelectDate={schedule}
        onClosedBtnClicked={onClosedBtnClicked}
        onChangeSchedule={onChangeSchedule}
      />
 * 
 * 
 */

type Props = {
  open: boolean;
  initalSelectDate?: Set<string>;
  onClosedBtnClicked: () => void;
  onChangeSchedule: (start: string, end: string) => void;
};
const CalendarContainer = ({
  open,
  initalSelectDate = new Set<string>(),
  onClosedBtnClicked,
  onChangeSchedule,
}: Props) => {
  const [selectDate, setSelectDate] = useState<Set<string>>(initalSelectDate);
  const [start, end] = Array.from(selectDate).sort();

  const handleDateClick = useCallback(
    (date: string) => {
      setSelectDate((prev) => {
        const clone = new Set(prev);
        if (clone.has(date)) {
          clone.delete(date);
          return clone;
        }
        if (clone.size >= 2) {
          return prev;
        }
        clone.add(date);
        return clone;
      });
    },
    [selectDate],
  );

  const handleResetClick = useCallback(() => {
    setSelectDate(new Set());
  }, []);

  const handleApplyBtnClicked = useCallback(() => {
    if (selectDate.size === 0) return;

    const date = Array.from(selectDate).sort();

    onChangeSchedule(date[0], selectDate.size === 1 ? date[0] : date[1]);
    onClosedBtnClicked();
  }, [selectDate]);

  useEffect(() => {
    setSelectDate(initalSelectDate);
  }, [open]);

  return (
    <CalendarContext.Provider
      value={useMemo(() => {
        return { selectDate, handleDateClick, handleResetClick };
      }, [selectDate, handleDateClick, handleResetClick])}>
      {open && (
        <Modal onCloseClick={onClosedBtnClicked}>
          <Modal.Header
            title='날짜선택'
            hasCloseBtn={true}
            onCloseClick={onClosedBtnClicked}
          />
          <Modal.Content>
            <Calendar />
          </Modal.Content>
          <Modal.Footer>
            <ButtonContainer
              isFilled={true}
              backgroundColor={'primary'}
              btnText={
                selectDate.size === 0
                  ? '날짜를 선택해주세요'
                  : selectDate.size === 1
                  ? start
                  : `${start} - ${end}`
              }
              onClick={handleApplyBtnClicked}
            />
          </Modal.Footer>
        </Modal>
      )}
    </CalendarContext.Provider>
  );
};

export default CalendarContainer;
