import { WHITE } from '@constants/colors';
import styled from '@emotion/styled';
import React from 'react';

type Props = {
  children: JSX.Element;
};

const FloatingBottomButton = ({ children }: Props) => {
  return (
    <FloatingBottomButtonRoot>
      <div className='button-section'>{children}</div>
      <div className='dummy' />
    </FloatingBottomButtonRoot>
  );
};

export default FloatingBottomButton;

const FloatingBottomButtonRoot = styled.div`
  .button-section {
    position: fixed;
    border: 0;
    bottom: 0;
    padding: 7px 20px;
    width: 100%;
    background-color: ${WHITE};
  }

  .dummy {
    min-height: 70px;
  }
`;
