import { getCalendarDateFormat } from '@libs/factory';
import React, { useCallback, useContext } from 'react';
import { CalendarContext } from '../context/CalendarContext';
import * as S from '../styles/calendarStyles';

type Props = {
  year: number;
  month: number;
  date: number;
  isSun: boolean;
  isSat: boolean;
  isToday: boolean;
};

const CalendarDate = ({ year, month, date, isSat, isSun, isToday }: Props) => {
  const { selectDate, handleDateClick } = useContext(CalendarContext);
  const [startDate, endDate] = Array.from(selectDate).sort();

  const isInRange =
    getCalendarDateFormat(year, month + 1, date) > startDate &&
    getCalendarDateFormat(year, month + 1, date) < endDate;

  const isStartDate =
    getCalendarDateFormat(year, month + 1, date) === startDate;
  const isEndDate = getCalendarDateFormat(year, month + 1, date) === endDate;

  const onDateClick = useCallback(() => {
    handleDateClick(getCalendarDateFormat(year, month + 1, date));
  }, []);

  return (
    <S.DateContainer
      onClick={onDateClick}
      className={`${isToday && 'today'}  ${isSat && 'sat'} ${isSun && 'sun'} ${
        isStartDate && 'start'
      } ${isEndDate && 'end'} 
      ${isInRange && 'range'}`}>
      {date > 0 ? date : ''}
    </S.DateContainer>
  );
};

export default CalendarDate;
