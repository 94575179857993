import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../PageHeader';
import history from '@libs/history';

type Props = {
  isBackbuttonVisible?: boolean;
  title?: string;
  navigateTo?: string;
};

const PageHeaderContainer = (props: Props) => {
  const navigate = useNavigate();
  const handleBackbuttonClick = useCallback(() => {
    props.navigateTo ? navigate(props.navigateTo) : navigate(-1);
  }, [props.navigateTo, navigate]);

  return <PageHeader {...props} onBackbuttonClick={handleBackbuttonClick} />;
};

export default PageHeaderContainer;
