import { images } from '@components/assets/images';

export type GNBTabTypes = '/' | '/nearby' | '/mypage';

export type GNBItemsTypes = {
  label: string;
  path: GNBTabTypes;
  icon: { active: string; inactive: string };
};

export const GNBItems: GNBItemsTypes[] = [
  {
    label: '홈',
    path: '/',
    icon: {
      active: images.navigation.homeAtv,
      inactive: images.navigation.home,
    },
  },
  {
    label: '주변',
    path: '/nearby',
    icon: {
      active: images.navigation.nearbyAtv,
      inactive: images.navigation.nearby,
    },
  },
  {
    label: '마이페이지',
    path: '/mypage',
    icon: {
      active: images.navigation.mypageAtv,
      inactive: images.navigation.mypage,
    },
  },
];
