/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { images } from '../../../assets/images';
import { GREY1, GREY2, GREY5 } from '../constants/color';

type Props = {
  socialName: string;
  socialLogin: string;
};
const SocialLoginButton = ({ socialName, socialLogin }: Props) => {
  return (
    <div>
      <button
        css={css`
          display: flex;
          padding: 18px;
          text-align: center;
          align-items: center;
          background-color: ${GREY1};
          border: 1px solid ${GREY2};
          border-radius: 6px;
          width: 335px;
          height: 56px;
        `}>
        <img
          css={css``}
          src={
            socialName === 'google'
              ? images.google
              : socialName === 'naver'
              ? images.naver
              : socialName === 'twitter'
              ? images.twitter
              : socialName === 'kakao'
              ? images.kakao
              : ''
          }
          alt=''
        />
        <p
          css={css`
            flex: 1;
            font-size: 15px;
            font-weight: 700;
            color: ${GREY5};
          `}>
          {socialLogin}
        </p>
      </button>
    </div>
  );
};

export default SocialLoginButton;
