import { BLACK, GREY1, GREY2, GREY3, GREY5 } from '@constants/colors';
import styled from '@emotion/styled';

export const RateSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid ${GREY2};

  .title {
    font-weight: 700;
  }

  div {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-top: 10px;
  }
`;

export const CommentSection = styled.section`
  display: flex;
  flex-direction: column;
  //padding-top: 20px;
  //align-items: flex-end;

  .review-controller {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${GREY1};
    .text {
      color: ${GREY5};
    }

    .btn-container {
      .btn {
        padding: 10px 12px;
        border-radius: 20px;
        background-color: white;
        border: 1px solid ${GREY2};
        min-width: fit-content;
        font-size: 14px;
      }
    }
  }
`;

export const CommentDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  border-bottom: 1px solid ${GREY2};

  .comment {
    padding: 0 20px;
  }

  .comment-main {
    padding: 16px 0;
    color: ${BLACK};
  }

  .like-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin-bottom: 20px;

    img {
      width: 14px;
      height: 14px;
    }

    span {
      color: ${GREY5};
    }
  }

  .image-container {
    display: flex;
    gap: 10px;
    padding: 0 20px;
    overflow-x: auto;
  }
`;

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    padding: 4px 0;
  }

  .date-container {
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      color: ${GREY5};
    }
    .bar {
      width: 1px;
      height: 8px;
      background-color: ${GREY3};
    }
  }

  .rate-container {
    img {
      width: 14px;
      height: 14px;
    }
  }
`;
