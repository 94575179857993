/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styles from '../styles/chip.module.css';

type Props = {
  isClick: boolean;
  btntext: string;
  isMedium?: boolean;
  onClickHandler: () => void;
};
const Chips = ({ isMedium, btntext, isClick, onClickHandler }: Props) => {
  return (
    <button
      className={`${styles[isMedium ? 'medium' : '']} 
      ${styles[isClick ? 'click' : '']} ${styles['btn']}`}
      onClick={onClickHandler}>
      {btntext}
    </button>
  );
};

export default Chips;
