import { ColorTypes } from '@typedef/styles/styles.types';
import React from 'react';
import styles from '../styles/button.module.css';

type Props = {
  isDisabled?: boolean;
  isFilled: boolean;
  btnText: string;
  isMedium?: boolean;
  backgroundColor: ColorTypes;
  buttonStyle?: React.CSSProperties;
  onClick: () => void;
};
const Button = ({
  isDisabled,
  isFilled,
  btnText,
  isMedium,
  backgroundColor,
  buttonStyle,
  onClick,
}: Props) => {
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      style={buttonStyle}
      className={`${isMedium ? 'text-m' : 'text-button'} ${styles['btn']} ${
        styles[isFilled ? 'fill' : 'outline']
      } ${styles[isMedium ? 'medium' : '']} ${styles[backgroundColor]}
        ${styles[isDisabled ? 'disabled' : '']}  `}>
      {btnText}
    </button>
  );
};

export default Button;
