import { PRIMARY } from '@constants/colors';
import styled from '@emotion/styled';

export const LoadingDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;

  .spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid white;
    border-right-color: ${PRIMARY};
    animation: loading 500ms infinite;
  }

  @keyframes loading {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
