import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import PlaceDetail from '../PlaceDetail';
import { SelfTourDetailSelector } from '@store/atom/SelfTourAtom';
import { API_ROUTE, ListResponseType, request } from '@libs/api';

const PlaceDetailContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const placeName = searchParams.get('name');
  const [place, setPlace] = useState<PlaceDetailType>(location.state);
  const setSelfTourDetail = useSetRecoilState(SelfTourDetailSelector);

  const handleAddBtnClick = () => {
    setSelfTourDetail({ selectedPlace: place });
    navigate('/selftour/add-place/setting');
  };

  const getPlace = async () => {
    if (!placeName) return;

    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<PlaceDetailType>>(
      API_ROUTE.place + `?name=${placeName}&x=0&y=0`,
      'get',
    );

    if (!isSuccessful) {
      console.log('fail');
      return;
    }
    setPlace(data.content[0]);
  };

  useEffect(() => {
    getPlace();
  }, []);

  return place ? (
    <PlaceDetail
      searchParams={searchParams}
      place={place}
      handleAddBtnClick={handleAddBtnClick}
    />
  ) : (
    <></>
  );
};

export default PlaceDetailContainer;
