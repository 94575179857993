import React, { useCallback, useEffect, useRef } from 'react';
import CInputBox from '../CInputBox';

type Props = {
  value?: string;
  className?: string;
  onChange: ({ value }: { value: string }) => void;
  onReset: () => void;
  variant?: 'outline' | 'flushed';
  placeholder?: string;
};

const CInputBoxContainer = ({
  value,
  className,
  onChange,
  onReset,
  variant,
  placeholder,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClearBtnClicked = useCallback(() => {
    if (inputRef.current) {
      onReset();
      inputRef.current.focus();
    }
  }, [onReset]);

  return (
    <CInputBox
      value={value}
      className={className}
      inputRef={inputRef}
      onChange={onChange}
      onClearBtnClicked={onClearBtnClicked}
      variant={variant}
      placeholder={placeholder}
    />
  );
};

export default CInputBoxContainer;
