/** @jsxImportSource @emotion/react */

import { GREY1, GREY2, PRIMARY_DARK } from 'constants/colors';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { images } from '@components/assets/images';

type Props = {
  value?: string;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  onChange: ({ value }: { value: string }) => void;
  onClearBtnClicked: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: 'outline' | 'flushed';
  placeholder?: string;
  disabled?: boolean;
};

const CInputBox = ({
  value,
  className,
  inputRef,
  onChange,
  onFocus,
  onBlur,
  onClearBtnClicked,
  variant,
  placeholder,
  disabled,
}: Props) => {
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      onChange({ value });
    },
    [onChange],
  );

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        gap: 12px;
        ${variant ? borderTypes[variant] : borderTypes['default']};
      `}>
      <input
        css={css`
          flex: 1;
          background-color: transparent;
          height: 56px;
        `}
        value={value}
        type='text'
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
      {value && (
        <button
          css={css`
            width: 15px;
            height: 15px;
            background: url(${images.delete});
            background-size: cover;
          `}
          onClick={onClearBtnClicked}></button>
      )}
    </div>
  );
};

export default CInputBox;

const borderTypes = {
  default: `border: 1px solid ${GREY2};
            background: ${GREY1};
            border-radius: 6px;
            font-weight: 400;
            font-size: 15px;
            padding: 20px 16px;
            &:focus {
            border: 1px solid ${PRIMARY_DARK};
            }`,
  outline: `border: 1px solid ${GREY2};
            background: ${GREY1};
            border-radius: 6px;
            font-weight: 400;
            font-size: 15px;
            &:focus {
            border: 1px solid ${PRIMARY_DARK};
            }`,
  flushed: `border-bottom: 1px solid ${GREY2};
            font-weight: 400;
            font-size: 16px;
            &:focus {
            border-bottom: 1px solid ${PRIMARY_DARK};
            }`,
};
