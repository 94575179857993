/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import { images } from '../../../assets/images';
import * as S from '../styles/ChipsFilterStyles';
import useDetectClickOut from '@hooks/useDetectClickOut';
import { UnitType } from '@typedef/api/common.types';

type Props = {
  isClick: boolean;
  btntext: string;
  isMedium?: boolean;
  onClickHandler: (id: number) => void;
  list: UnitType[];
  selectedIds: number[];
};

const ChipsFilter = ({
  isMedium = false,
  btntext,
  isClick,
  list,
  selectedIds,
  onClickHandler,
}: Props) => {
  const { triggerRef, nodeRef, show } = useDetectClickOut(false);

  return (
    <S.ChipsFilterRootDiv>
      <S.ChipsButton
        ref={triggerRef as React.RefObject<HTMLButtonElement>}
        isClick={isClick}
        isMedium={isMedium}>
        <p>{btntext}</p>
        <img src={images.filter3} alt='' />
      </S.ChipsButton>
      {show && (
        <S.ChipsDropdownDiv ref={nodeRef}>
          {list?.map((item) => {
            return (
              <S.ChipsDropdownItem
                key={item.id}
                onClick={() => onClickHandler(item.id)}
                isSelected={selectedIds.includes(item.id)}>
                <div className='text'>{item.name}</div>
                <img
                  src={
                    selectedIds.includes(item.id)
                      ? images.selectboxAtv
                      : images.selectbox
                  }
                />
              </S.ChipsDropdownItem>
            );
          })}
        </S.ChipsDropdownDiv>
      )}
    </S.ChipsFilterRootDiv>
  );
};

export default ChipsFilter;
