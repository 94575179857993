import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { API_ROUTE, request } from '@libs/api';
import { useRecoilState } from 'recoil';
import { authState } from '@store/atom/AuthAtom';
import PlaceListItem from '../PlaceListItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { nearByState } from '@store/atom/NearByAtom';
import { useLoginRedirect } from '@hooks/useGotoLogin';
import { PlaceLikeRepository } from 'repositories/placeLikeRepository';

type Props = {
  place: PlaceDetailType;
};

const PlaceListItemContainer = ({ place }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [{ isLoggedIn }] = useRecoilState(authState);
  const [value] = useRecoilState(nearByState);
  const { loginRedirect } = useLoginRedirect();
  const placeLikeRepo = new PlaceLikeRepository();
  const { placeTypeId, x, y, name, unitIds, activityIds } = value;

  const [liked, setLiked] = useState(place.liked);

  const handleClick = () => {
    navigate(
      `/place/detail?add=${searchParams.get('add')}&id=${
        place.id
      }&name=${encodeURI(place.name)}`,
      {
        state: { ...place, liked },
      },
    );
  };

  const handleLikeClick = async () => {
    if (!isLoggedIn) {
      loginRedirect();
      return;
    }

    const {
      data,
      config: { isSuccessful },
    } = await placeLikeRepo.post(place.id, liked);

    if (!isSuccessful) {
      alert('요청에 실패했습니다.');
      return;
    }

    queryClient.invalidateQueries([
      `infiniteNearbyPlaceList`,
      x,
      y,
      name,
      placeTypeId,
      Array.from(unitIds.values()).join(''),
      Array.from(activityIds.values()).join(''),
    ]);
    setLiked(data.liked);
  };

  return (
    <PlaceListItem
      onClick={handleClick}
      place={place}
      liked={liked}
      handleLikeClick={handleLikeClick}
    />
  );
};

export default PlaceListItemContainer;
