/** @jsxImportSource @emotion/react */
import PageHeaderContainer from '@components/common/PageHeader/containers/PageHeaderContainer';
import { BLACK, GREY2, WHITE } from '@constants/colors';
import { css } from '@emotion/react';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import ListOptionContainer from './containers/ListOptionContainer';
import Loading from '@components/common/Loading/Loading';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { FetchNextPageOptions } from '@tanstack/react-query';
import PlaceListItemContainer from '@components/common/PlaceListItem/containers/PlaceListItemContainer';
import { RegionType } from '@typedef/api/common.types';

type Props = {
  isLoading: boolean;
  regionId: number;
  regionList?: RegionType[];
  placeList?: PlaceDetailType[];
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => void;
};

const PlaceList = ({
  isLoading,
  regionId,
  regionList,
  placeList,
  fetchNextPage,
}: Props) => {
  const { setRoot, setTarget } = useInfiniteScroll(() => {
    fetchNextPage();
  });

  return (
    <div
      ref={setRoot}
      css={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow-y: auto;
      `}>
      <PageHeaderContainer title='추천 투어 장소' />
      <div
        css={css`
          background-color: ${WHITE};
          padding: 25px 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
        `}>
        <div
          className='h1'
          css={css`
            color: ${BLACK};
          `}>
          {regionId === -1
            ? '전국'
            : regionList?.filter((item) => item.id === regionId)[0].name}
        </div>
      </div>
      <div
        css={css`
          flex: 1;
          border-top: 1px solid ${GREY2};
        `}>
        <ListOptionContainer />
        {placeList?.map((item, idx) => (
          <div ref={idx === placeList.length - 1 ? setTarget : undefined}>
            <PlaceListItemContainer key={item.id} place={item} />
          </div>
        ))}
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default PlaceList;
