/** @jsxImportSource @emotion/react */
import { images } from '@components/assets/images';
import { GREY2, WHITE } from '@constants/colors';
import { css } from '@emotion/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as S from '../styles/PreviewSectionStyles';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { memo } from 'react';
import ShareModalContainer from '@components/common/ShareModal/containers/ShareModalContainer';

type Props = {
  place: PlaceDetailType;
  activeIndex: number;
  liked: boolean;
  isOpen: boolean;
  handleChangeIndex: (idx: number) => void;
  handleLikeClick: () => Promise<void>;
  handleShareClick: () => void;
  handleModalClose: () => void;
};

const PreviewSection = memo(
  ({
    liked,
    place,
    activeIndex,
    isOpen,
    handleChangeIndex,
    handleLikeClick,
    handleModalClose,
    handleShareClick,
  }: Props) => {
    return (
      <div
        css={css`
          position: relative;
        `}>
        <Swiper
          css={css`
            z-index: 0;
          `}
          onSlideChange={(swiper) => handleChangeIndex(swiper.activeIndex)}>
          {place.images.map((item) => (
            <SwiperSlide key={item}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100vw;
                  max-width: 500px;
                  max-height: 500px;
                  background-color: ${GREY2};
                `}
                className='img-wrapper'>
                <img
                  css={css`
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 1 / 1;

                    &.error {
                      width: 40px;
                      height: 40px;
                    }
                  `}
                  src={item}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.src = images.placeholder;
                    target.classList.add('error');
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <S.ButtonListDiv>
          <S.Button onClick={handleLikeClick}>
            {liked ? (
              <img src={images.likeAtv} alt='likeAtv' />
            ) : (
              <img src={images.like} alt='like' />
            )}
          </S.Button>

          <div
            className='text-xs'
            css={css`
              background-color: rgba(0, 0, 0, 0.4);
              padding: 5px 8px;
              border-radius: 2px;
              color: ${WHITE};
            `}>
            <span
              css={css`
                color: ${WHITE};
                font-weight: 700;
              `}>
              {activeIndex + 1}
            </span>
            {` / ${place.images.length}`}
          </div>
          <S.Button onClick={handleShareClick}>
            <img src={images.share} alt='공유하기' />
          </S.Button>
        </S.ButtonListDiv>
        {isOpen && (
          <ShareModalContainer place={place} onCloseClick={handleModalClose} />
        )}
      </div>
    );
  },
);
export default PreviewSection;
