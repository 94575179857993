import React, { useCallback, useEffect, useState } from 'react';
import CommentTab from '../components/CommentTab';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import {
  API_ROUTE,
  ListResponseType,
  buildQueryString,
  request,
} from '@libs/api';
import { LocationReviewType } from '@typedef/components/Location/locationReview.types';
import { useRecoilState } from 'recoil';
import { authState } from '@store/atom/AuthAtom';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type Props = {
  place: PlaceDetailType;
};

const CommentTabContainer = ({ place }: Props) => {
  const [reviewList, setReviewList] = useState<LocationReviewType[]>([]);
  const [{ isLoggedIn }] = useRecoilState(authState);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const getReviewList = async () => {
    const {
      data,
      config: { isSuccessful },
    } = await request<ListResponseType<LocationReviewType>>(
      API_ROUTE.placeReview + `?placeId=${place.id}`,
      'get',
    );

    if (!isSuccessful) {
      console.log('fail');
      return;
    }

    setReviewList(data.content);
  };

  const handleCommentClick = () => {
    if (!isLoggedIn) {
      alert('로그인이 필요한 기능입니다');
      return;
    }

    setOpen(true);
    navigate(
      `${location.pathname}${buildQueryString({
        [`isModalOpen`]: String(!open),
      })}`,
    );
  };

  const handleBottomSheetClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    getReviewList();
  }, []);

  useEffect(() => {
    !params.get('isModalOpen') && setOpen(false);
  }, [params]);

  return (
    <CommentTab
      open={open}
      place={place}
      reviewList={reviewList}
      handleCommentClick={handleCommentClick}
      handleBottomSheetClose={handleBottomSheetClose}
    />
  );
};

export default CommentTabContainer;
