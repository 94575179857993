import React, { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { scheduleState } from '@store/atom/CalendarAtom';
import {
  ActiveScheduleDayState,
  ScheduleModeState,
} from '@store/atom/ScheduleAtom';
import ScheduleSection from '../components/ScheduleSection';
import {
  SelfTourDetailSelector,
  SelfTourDetailState,
} from '@store/atom/SelfTourAtom';

const ScheduleSectionContainer = () => {
  const [scheduleMode] = useRecoilState(ScheduleModeState);
  const [activeDay, setActiveDay] = useRecoilState(ActiveScheduleDayState);
  const [selfTourDetail] = useRecoilState(SelfTourDetailState);
  const setSelftourDetail = useSetRecoilState(SelfTourDetailSelector);

  const handleDateClick = useCallback((idx: number) => {
    setActiveDay(idx);
    setSelftourDetail({ selectedDay: idx });
  }, []);

  return (
    <ScheduleSection
      scheduleMode={scheduleMode}
      schedule={selfTourDetail.schedule}
      activeDay={activeDay}
      handleDateClick={handleDateClick}
    />
  );
};

export default ScheduleSectionContainer;
