import { images } from '@components/assets/images';
import React, { useContext } from 'react';
import SearchListContainer from '../containers/SearchListContainer';
import * as S from '../styles/SearchPopupStyles';
import SearchBoxContainer from '@components/common/SearchBox/containers/SearchBoxContainer';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { useRecoilState } from 'recoil';
import { nearByState } from '@store/atom/NearByAtom';

type Props = {
  searchInput: string;
  searchedList?: PlaceDetailType[];
  onClosePopoup: () => void;
  onSubmit?: () => void;
  handleInputChange: (value: string) => void;
};

const SearchPopup = ({
  searchInput,
  onClosePopoup,
  searchedList,
  onSubmit,
  handleInputChange,
}: Props) => {
  const [value, setValue] = useRecoilState(nearByState);

  return (
    <S.SearchPopupDiv>
      <S.PopoupHeaderDiv>
        <button
          onClick={() => {
            handleInputChange('');
            onClosePopoup();
          }}>
          <img src={images.header.close} alt='' />
        </button>
        <div className='title h2'>{'내 주변 찾기'}</div>
      </S.PopoupHeaderDiv>
      <S.PopupSearchbarDiv>
        <SearchBoxContainer
          placeholder='내 주변 장소 검색'
          value={searchInput}
          className={''}
          onChange={({ value }) => {
            handleInputChange(value);
          }}
          onReset={() => {
            setValue((prev) => {
              const clone = { ...prev };
              clone.name = '';
              return clone;
            });
          }}
          onSubmit={() => {
            setValue((prev) => {
              const clone = { ...prev };
              clone.name = searchInput;
              return clone;
            });
            onClosePopoup();
          }}
        />
      </S.PopupSearchbarDiv>
      {searchedList && (
        <div style={{ marginTop: '24px', padding: '0 20px' }}>
          {searchedList.map((item) => (
            <S.ListItemDiv
              key={item.id}
              onClick={() => {
                setValue((prev) => {
                  const clone = { ...prev };
                  clone.name = item.name;
                  return clone;
                });
                onClosePopoup();
              }}>
              {item.name}
            </S.ListItemDiv>
          ))}
        </div>
      )}
    </S.SearchPopupDiv>
  );
};

export default SearchPopup;
