/** @jsxImportSource @emotion/react */
import ChipsContainer from '@components/common/button/containers/ChipsContainer';
import * as S from '../styles/MapListOptionStyles';
import ChipsFilterContainer from '@components/common/button/containers/ChipsFilterContainer';
import { useRecoilState } from 'recoil';
import { nearByState } from '@store/atom/NearByAtom';
import { useCommonQuery } from '@hooks/api/useCommonQuery';

const MapListOption = () => {
  const { placeTypeList, activityList, unitList } = useCommonQuery();
  const [value, setValue] = useRecoilState(nearByState);
  const { placeTypeId, unitIds, activityIds } = value;

  return (
    <>
      <S.ItemContainer className='map-item'>
        <S.Item
          onClick={() => {
            setValue((prev) => {
              const clone = { ...prev };
              clone.placeTypeId = 0;
              return clone;
            });
          }}
          isSelected={placeTypeId === 0}>
          {'전체'}
        </S.Item>
        {placeTypeList.data?.map((item, idx) => {
          return (
            <S.Item
              key={idx}
              onClick={() => {
                setValue((prev) => {
                  const clone = { ...prev };
                  clone.placeTypeId = item.id;
                  return clone;
                });
              }}
              isSelected={placeTypeId === item.id}>
              {item.name}
            </S.Item>
          );
        })}
      </S.ItemContainer>
      <S.OptionItemContainer className='map-item'>
        <ChipsContainer
          isClick={false}
          btntext={'전체'}
          onClickHandler={() => {
            setValue((prev) => {
              const clone = { ...prev };
              clone.activityIds = new Set();
              clone.unitIds = new Set();
              clone.page = 0;
              return clone;
            });
          }}
        />
        <ChipsFilterContainer
          btntext={'활동별'}
          isClick={activityIds.size !== 0}
          onClickHandler={(id) => {
            const newActivityIds = new Set(activityIds);
            newActivityIds.has(id)
              ? newActivityIds.delete(id)
              : newActivityIds.add(id);

            setValue((prev) => {
              const clone = { ...prev };
              clone.activityIds = newActivityIds;
              clone.page = 0;
              return clone;
            });
          }}
          list={activityList.data ?? []}
          selectedIds={Array.from(activityIds.values())}
        />
        <ChipsFilterContainer
          btntext={'유닛별'}
          isClick={unitIds.size !== 0}
          onClickHandler={(id) => {
            const newUnitIds = new Set(unitIds);
            newUnitIds.has(id) ? newUnitIds.delete(id) : newUnitIds.add(id);

            setValue((prev) => {
              const clone = { ...prev };
              clone.unitIds = newUnitIds;
              clone.page = 0;
              return clone;
            });
          }}
          list={unitList.data ?? []}
          selectedIds={Array.from(unitIds.values())}
        />
      </S.OptionItemContainer>
    </>
  );
};

export default MapListOption;
