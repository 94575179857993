/** @jsxImportSource @emotion/react */
import { images } from '@components/assets/images';
import { css } from '@emotion/react';
import { PlaceDetailType } from '@typedef/components/Location/location.types';
import { GREY2 } from '../button/constants/color';

type Props = {
  liked: boolean;
  maxWidth?: number;
  place: PlaceDetailType;
  onClick: () => void;
  handleLikeClick: () => void;
};
const PlaceGridItem = ({
  liked,
  maxWidth = 225,
  place,
  onClick,
  handleLikeClick,
}: Props) => {
  return (
    <li
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains('like-btn')) return;
        onClick();
      }}
      css={css`
        max-width: ${maxWidth}px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        overflow-x: hidden;
      `}>
      <div
        css={css`
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${GREY2};
          border-radius: 6px;
        `}>
        <img
          loading='lazy'
          css={css`
            aspect-ratio: 1 / 1;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: cover;

            &.error {
              width: 40px;
              height: 40px;
            }
          `}
          src={place.images[0]}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = images.placeholder;
            target.classList.add('error');
          }}
          alt='image'
        />
        <img
          className='like-btn'
          onClick={handleLikeClick}
          css={css`
            position: absolute;
            top: 8px;
            right: 8px;
            width: 24px;
            height: 24px;
          `}
          src={liked ? images.likeAtv : images.like}
          alt=''
        />
      </div>
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 6px;
        `}>
        <span
          css={css`
            display: inline-block;
          `}
          className='text-m'>
          {place.name}
        </span>
        <span
          css={css`
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
          `}
          className='text-xs grey5'>
          {place.title}
        </span>
      </div>
    </li>
  );
};

export default PlaceGridItem;
