import { images } from '@components/assets/images';
import { Modal } from '@components/common/Modal/Modal';
import React, { useState } from 'react';
import * as S from '../styles/ShareModalStyles';
import { PlaceDetailType } from '@typedef/components/Location/location.types';

type Props = {
  place: PlaceDetailType;

  onCloseClick: () => void;
};

const ShareModalContainer = ({ place, onCloseClick }: Props) => {
  const handleShare = (type: 'kakao' | 'twitter' | 'other') => {
    const url = `${window.location.origin}${window.location.pathname}?name=${place.name}`;
    switch (type) {
      case 'kakao':
        window.Kakao.Share.sendCustom({
          templateId: 98981,
          templateArgs: {
            title: place.name,
            description: place.description,
            img: place.images[0],
            domain: url,
          },
        });
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?text=NCT가 방문한 그 곳 ${place.name}&url=${url}`,
        );
        break;
      case 'other':
        if (navigator.share) {
          navigator.share({
            title: place.name,
            text: place.description,
            url,
          });
        }
    }
  };

  return (
    <Modal onCloseClick={onCloseClick}>
      <Modal.Header
        title='공유하기'
        hasCloseBtn={true}
        onCloseClick={onCloseClick}
      />
      <Modal.Content>
        <S.RootDiv>
          <S.ListContainerUl>
            <li>
              <a onClick={() => handleShare('twitter')}>
                <img src={images.sns.twitter} alt='트위터 공유하기 이미지' />
                <span className='text-xs'>{'트위터'}</span>
              </a>
            </li>
            <li>
              <a onClick={() => handleShare('kakao')}>
                <img src={images.sns.kakao} alt='카카오톡 공유하기 이미지' />
                <span className='text-xs'>{'카카오톡'}</span>
              </a>
            </li>
            {/* <li>
              <a href=''>
                <img
                  src={images.sns.instagram}
                  alt='인스타그램 공유하기 이미지'
                />
                <span className='text-xs'>{'인스타그램'}</span>
              </a>
            </li>
            <li>
              <a onClick={() => handleShare('facebook')}>
                <img src={images.sns.facebook} alt='페이스북 공유하기 이미지' />
                <span className='text-xs'>{'페이스북'}</span>
              </a>
            </li> */}
          </S.ListContainerUl>
          <S.LinkContainerDiv
            onClick={() => {
              window.navigator.clipboard
                .writeText(window.location.href)
                .then(() => {
                  alert('복사되었습니다');
                });
            }}>
            <span className='text-input'>{window.location.href}</span>
            <button className='text-xs'>{'링크복사'}</button>
          </S.LinkContainerDiv>
          <S.DescriptionContainerP className='text-m'>
            {`NCT가 방문한 장소를 공유해보세요!`}
          </S.DescriptionContainerP>
        </S.RootDiv>
      </Modal.Content>
    </Modal>
  );
};

export default ShareModalContainer;
