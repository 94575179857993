import { colors } from '@components/assets/colors';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;

  .list-container {
    overflow-y: auto;
  }
`;

export const MenuSection = styled.section`
  background-color: white;
  display: flex;
  width: 100%;
  height: 56px;
  padding-left: 20px;
`;

export const CategoryNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 56px;
  width: 52px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: ${colors.greyScale.grey5};

  &.active {
    color: ${colors.primary.primary};
    font-weight: 700;
    border-bottom: 2px solid ${colors.primary.primary};
  }
`;

export const DivideLineDiv = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.greyScale.grey1};
`;
