import React, { useCallback, useState } from 'react';
import DefaultMode from '../components/DefaultMode';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ScheduleModeState } from '@store/atom/ScheduleAtom';
import {
  SelfTourDetailSelector,
  SelfTourDetailState,
} from '@store/atom/SelfTourAtom';
import { buildQueryString } from '@libs/api';

const DefaultModeContainer = () => {
  const navigate = useNavigate();

  const [scheduleMode, setScheduleMode] = useRecoilState(ScheduleModeState);
  const [selfTourDetail] = useRecoilState(SelfTourDetailState);
  const setSelfTourDetail = useSetRecoilState(SelfTourDetailSelector);
  const { placeList, selectedDay, region } = selfTourDetail;

  const handleAddLoadcationClick = useCallback(() => {
    if (!region.name) return;

    navigate(`/selftour/add-place?add=true&regionId=${region.id}`);
  }, [navigate, region]);

  const handleAddOtherPlaceClick = useCallback(() => {
    navigate(`/selftour/add-other-place`);
  }, [navigate]);

  const handleEditClick = useCallback(() => {
    setScheduleMode('edit');
  }, []);

  const handleMapClick = useCallback(
    (lat: number, lng: number) => {
      navigate(`/place/map${buildQueryString({ lat, lng })}`);
    },
    [navigate],
  );

  return (
    <DefaultMode
      list={placeList.get(selectedDay)}
      handleAddLoadcationClick={handleAddLoadcationClick}
      handleAddOtherPlaceClick={handleAddOtherPlaceClick}
      handleEditClick={handleEditClick}
      handleMapClick={handleMapClick}
    />
  );
};

export default DefaultModeContainer;
