import CBottomSheetContainer from '@components/common/CBottomSheet/containers/CBottomSheetContainer';
import {
  SelfTourDetailState,
  SelfTourModalState,
} from '@store/atom/SelfTourAtom';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import EditDayModal from '../components/EditDayModal';
import { getTourPlaceTimeFormat } from '@libs/factory';

const ModalEditDayContainer = () => {
  const [isModalOpen, setIsModalOpen] = useRecoilState(SelfTourModalState);
  const [selfTourDetail, setSelfTourDetail] =
    useRecoilState(SelfTourDetailState);
  const { schedule, placeList, editableItemIdx, selectedDay } = selfTourDetail;
  const [selectedIdx, setSelectedIdx] = useState(0);

  const handleDayClick = (idx: number) => {
    setSelectedIdx(idx);
  };

  const handleApplyClick = () => {
    const clone = new Map(placeList);

    const originalList = placeList.get(selectedDay) ?? [];
    const replaceList = placeList.get(selectedIdx) ?? [];
    const editItem = originalList.splice(editableItemIdx, 1)[0];
    editItem.isSelected = false;

    const fromTime = editItem.from.split('T')[1];
    const toTime = editItem.to.split('T')[1];

    editItem.from = getTourPlaceTimeFormat(
      Array.from(schedule).sort()[0],
      selectedIdx,
      fromTime,
    );

    editItem.to = getTourPlaceTimeFormat(
      Array.from(schedule).sort()[0],
      selectedIdx,
      toTime,
    );

    replaceList.push(editItem);

    clone.set(selectedDay, originalList);
    clone.set(selectedIdx, replaceList);

    setSelfTourDetail({
      ...selfTourDetail,
      placeList: clone,
      selectedPlace: null,
    });
    setIsModalOpen({ ...isModalOpen, day: false });
  };

  return isModalOpen.day ? (
    <CBottomSheetContainer
      open={isModalOpen.day}
      title={'날짜 변경'}
      onClosedBtnClick={() => {
        setIsModalOpen({ ...isModalOpen, day: false });
      }}
      onApplyBtnClick={handleApplyClick}>
      <EditDayModal
        schedule={schedule}
        selectedIdx={selectedIdx}
        handleDayClick={handleDayClick}
      />
    </CBottomSheetContainer>
  ) : (
    <div></div>
  );
};

export default ModalEditDayContainer;
