import { API_ROUTE, request } from '@libs/api';
import { getOSVersion } from '@libs/factory';
import {
  AUTH_KEY,
  SESSION_STORAGE_TYPE,
  getTokenFromStorage,
  removeItemToStorage,
} from '@libs/storage';
import RootNavigation from '@routes/RootNavigation';
import { authState } from '@store/atom/AuthAtom';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

const RootNavigationContainer = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [auth, setAuth] = useRecoilState(authState);

  const checkTokenValidate = useCallback(async () => {
    const { token, isTokenStored } = getTokenFromStorage(SESSION_STORAGE_TYPE);

    if (!isTokenStored) {
      setIsChecked(true);
      return;
    }

    const {
      config: { isSuccessful },
    } = await request(API_ROUTE.user + '/profile', 'get');

    if (!isSuccessful) {
      removeItemToStorage(SESSION_STORAGE_TYPE, AUTH_KEY);
      setIsChecked(true);
      return;
    }

    setIsChecked(true);
    setAuth({ isLoggedIn: true, token });
  }, [auth]);

  useEffect(() => {
    checkTokenValidate();
  }, []);

  return isChecked ? <RootNavigation /> : <div />;
};

export default RootNavigationContainer;
