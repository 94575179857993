/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from '@components/assets/colors';

import { useNavigate } from 'react-router-dom';
import MyPageBottomSheet from './components/MyPageBottomSheet';
import MyPageCheckBox from '../CheckBox/MyPageCheckBox';
import MyPageCheckBoxContainer from '../CheckBox/containers/MyPageCheckBoxContainer';
import MyPageBottomSheetCheckBoxContainer from './containers/MyPageBottomSheetCheckBoxContainer';
import Button from '@components/common/button/components/Button';

type Props = {
  title: string;
  closeModal: () => void;
};

const MyPageBottomSheetContent = ({ title, closeModal }: Props) => {
  const navigate = useNavigate();
  const [isCheckingBox, setIsCheckingBox] = useState(false);
  const [checkArr, setCheckArr] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const checkBoxList = [
    '이용이 번거롭고 불편해서',
    '자주 이용하지 않게 되어서',
    '앱 내에 오류가 잦아서',
    '직접 입력 할게요',
  ];

  const checkingCheckedBox = useCallback(() => {
    setIsCheckingBox(true);
    setCheckArr([]);
  }, []);

  return (
    <MyPageBottomSheet title={title} closeModal={closeModal}>
      <BottomSheetContent>
        {checkBoxList.map((item, idx) => (
          <MyPageBottomSheetCheckBoxContainer text={item} />
        ))}
      </BottomSheetContent>
      <ControllerArea>
        <Button
          isFilled={false}
          btnText="취소"
          backgroundColor="primary-lighten"
          onClick={() => {}}
        ></Button>
        <Button
          isFilled={true}
          btnText="적용"
          backgroundColor="primary"
          onClick={() => {
            navigate('/mypage/tour', { state: '지난 투어', replace: true });
          }}
        ></Button>
      </ControllerArea>
    </MyPageBottomSheet>
  );
};

export default MyPageBottomSheetContent;

const BottomSheetContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 41px;
`;

const ControllerArea = styled.div`
  display: flex;
  margin-top: 47px;
  margin-bottom: 41px;
  gap: 8px;
  padding: 0 20px;
`;
