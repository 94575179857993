export const GREY1 = '#fafafa';
export const GREY2 = '#eeeeee';
export const GREY3 = '#d9d9d9';
export const GREY5 = '#767676';
export const PRIMARY = '#BBE309';
export const PRIMARYDARK = '#94b20a';
export const PRIMARYLIGTHEN = '#f9fbf2';
export const SECONDARY = '#b867c6';
export const SECONDARYDARK = '#863895';
export const SECONDARYLIGTHEN = '#F1E1F4';
