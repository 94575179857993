import { BLACK, GREY2, GREY3, GREY5, SECONDARY_DARK } from '@constants/colors';
import styled from '@emotion/styled';

export const PlaceListItemStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 20px 16px;
  border-bottom: 1px solid ${GREY2};

  .img-wrapper {
    width: 96px;
    height: 96px;
    border-radius: 6px;
    background-color: ${GREY2};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;

      &.error {
        width: 40px;
        height: 40px;
      }
    }
  }

  .like {
    margin-top: 12px;
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ItemInfoStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 12px 0;
  overflow: hidden;

  .item-title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .title {
      color: ${BLACK};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .description {
      color: ${GREY5};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .location-info {
    display: flex;
    align-items: center;
    gap: 6px;

    .distance {
      color: ${SECONDARY_DARK};
      white-space: nowrap;
    }

    .location {
      color: ${GREY5};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .bar {
      width: 1px;
      height: 8px;
      background-color: ${GREY3};
    }
  }
`;
